
export const physicsTest1Red=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'TEST1_REQUEST':
            return {...state,loading:true}
        case 'TEST1_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TEST1_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const ResponseTest1Red=(state={data:[]},action)=>{
    switch(action.type){
        case 'TEST1_RESPONSE_REQUEST':
            return {...state,loading:true}
        case 'TEST1_RESPONSE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TEST1_RESPONSE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//ALLDATA for mains

export const ResponseAllDataTest1Red=(state={data:[] },action)=>{
    switch(action.type){
        case 'TEST1_RESPONSE_ALLDATA_REQUEST':
            return {...state,loading:true}
        case 'TEST1_RESPONSE_ALLDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TEST1_RESPONSE_ALLDATA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

// sidebar


export const physicsTest1SidebarRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'TEST1_SIDEBAR_REQUEST':
            return {...state,loading:true}
        case 'TEST1_SIDEBAR_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TEST1_SIDEBAR_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}