import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useMediaQuery from "../../../components/hook/UseMediaQuery";
import { motion } from "framer-motion";


const MySkills = () => {
    const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id='skills' >
     {/* HEADER AND IMAGE SECTION */}
     
     <div className="md:flex md:justify-between md:gap-16 md:mt-40  ">
        <motion.div
          className="md:w-1/3 "
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl  text-blue text-center">
            WHAT TO <span className=" orange_gradient">LEARN</span>
          </p>
        
          <p className="mt-3 mb-7 text-center font-serif">
            Here, you will learn how to tackle good problems and the real <span className='text-blue text-[17px] font-bold' >Concepts</span>  and you will fall into love with physics.

          </p>
        </motion.div>
        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <div
              className="relative -z-10 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
            <LazyLoadImage
              src="assets/boy-min.jpg"
              alt='image loading'
              //effect='blur'
              placeholderSrc="assets/boy-min.jpg"
              threshold='100'
              className=" hover:filter hover:saturate-200 transition duration-500 w-full max-w-[400px] md:max-w-[550px]"
               
             />
              {/* <img
                alt="skills"
                className=" hover:filter hover:saturate-200 transition duration-500 w-full max-w-[400px] md:max-w-[550px]"
                src="assets/boy.jpg"
              /> */}
            </div>
          ) : (
            <div
              className="relative w-full mx-auto -z-10 ml-20 before:absolute before:-top-4 xs:before:-top-10  before:-left-4 xs:before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
             <LazyLoadImage
              src="assets/boy-min.jpg"
              alt='image loading'
              //effect='blur'
              placeholderSrc="assets/boy-min.jpg"
              threshold='100'
              className=" hover:filter hover:saturate-200 transition duration-500 w-full"
               
             />
              
            </div>
          )}
        </div>
    </div>

     {/* SKILLS */}
     <div className="md:flex md:justify-between mt-16 gap-32">
        {/* EXPERIENCE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10 mt-2">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Concepts
              </p>
            </div>
           
          </div>
          <p className="mt-4 font-serif">
          <span className='text-blue text-[17px] font-bold' >(1).</span> This is my one of the targets to make your <span className='text-blue font-bold'>Concepts</span>  very clear and very strong
             about any topics.
          
          </p>
          
           <p className="mt-1 font-serif">
           <span className='text-blue text-[17px] font-bold' >(2).</span>  For <span className='text-blue font-bold'>Concepts</span> firstly read <span className='text-blue font-bold'>Concepts</span> on this app and If you are still sticking then Go To my YOUTUBE channel(SUBSCRIBE) <a className='text-blue' href="https://www.youtube.com/watch?v=TYaOizX6t_M">whiteBoardBlackPen</a>.
            
          
          </p>
        
        </motion.div>

        {/* INNOVATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Practice
              </p>
            </div>
           
          </div>
          <p className="mt-4  font-serif">
           <span className='text-blue text-[17px] font-bold border' >(1).</span> I have taken lots of problems for practices to prepare Board exam ,JEE and NEET interence exam  from different different books , internet , sheets of institutes and  previous years .
           
            
          </p>
          <p className='mt-1 font-serif' ><span className='text-blue text-[17px] font-bold ' >(2).</span> If you need logic behind any problems and how to solve problems Please go to my YOUTUBE channel(SUBSCRIBE) <a className='text-blue' href="https://www.youtube.com/watch?v=Fw1lLrwV-LM">whiteBoardBlackPen</a>   </p>
        </motion.div>
        {/* IMAGINATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Books
              </p>
            </div>
            {/* <div className="w-1/2 md:w-3/4 h-32 absolute right-0 top-0 cursor-pointer" ><Cube3 /></div> */}
          </div>
          <p className="mt-4 font-serif">
          <span className='text-blue text-[17px] font-bold ' >(1).</span> The problems(for practices) and Concepts those are given in this app those are from many basic to advance level books like 
            M.Karim , NCERT , HC Verma , Irodov , DC Pandey  , Cengage (BM Sharma) , University physics , resnick halliday , Previous years  , Sheets of institute like Vibrant , Allen.
          </p>
        </motion.div>
      </div>
    </section>
  
  )
}

export default MySkills
