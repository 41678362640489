export const conceptsOfMaximaRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MAXIMA_CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'MAXIMA_CONCEPT_SUCCESS':
            return {...state,data:action.payload,loading:false}
        case 'MAXIMA_CONCEPT_FAIL':
             return {...state,loading:false}
        default:
            return state
    }
}

export const examplesOfMaximaRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MAXIMA_EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'MAXIMA_EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MAXIMA_EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const reviseConceptsOfMaximaRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MAXIMA_REVISE_REQUEST':
            return {...state,loading:true}
        case 'MAXIMA_REVISE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MAXIMA_REVISE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//more

export const moreQueMaximaRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_MAXIMA_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_MAXIMA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_MAXIMA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsMaximaRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_ANS_MAXIMA_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_MAXIMA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_MAXIMA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}