import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AskToSahilCreateAction } from '../../redux/actions/Ask-to-sahil/AskToSahilAction';
import { useState } from 'react';
import AllDataOfAsk from './AllDataOfAsk';
import { Button } from '@mui/material';
import CommentSection from './CommentSection';

const AskToSahil = () => {
  const createdata = useSelector(state=>state.createAskToSahilRed);
  const getData = useSelector(state=>state.getAllAskToSahilRed);
  const [showComment,setShowComment] = useState(false);
  const [id,setId] = useState(null);
  const [localData,setLocalData] = useState([]);
  const [ask,setAsk] = useState({
    name:'',
    question:''
  });
  
  useEffect(()=>{
    if (!getData?.data?.questions) return
    // setLocalData(getData?.data?.questions?.reverse()?.slice())
    const reversedQuestions = [...getData.data.questions].reverse(); // Create a reversed copy of the array
    setLocalData(reversedQuestions);
  },[getData?.data,setLocalData])
 
  const dispatch = useDispatch();
  
  const handleSubmit = (e)=>{
    e.preventDefault();
    dispatch(AskToSahilCreateAction(ask));
    setAsk({ name:'', question:''});
    setLocalData((prev)=>{
     return [{...ask,_id:Date.now()},...prev]
    });
  }

  if(showComment){
   return (
    <div className="relative h-screen">
      <div className=''><CommentSection id={id} createdata={createdata}  /></div>
    </div>
   )
  }
  return (
    <div>
     <p className='text-center text-6xl font-medium mt-4 border-b-[2px] max-w-2xl m-auto border-cyan-600'>
       Ask To Sahil
     </p>
     <p className='text-center max-w-2xl m-auto'>
      If you stuck in any problem given in WHITEBOARDBLACKPEN then please ask to me without any hesitation.
      you can write here question no with chapter name , example and exercises or directly question from this app.
     </p>
     <form onSubmit={handleSubmit} className='flex justify-center items-center'>
     
      <div className='mt-4'>
      
       <label htmlFor="name">Name:</label>
       <div> <input type="text" className='border p-2 w-full rounded-md' placeholder='Name' value={ask.name} onChange={(e)=>setAsk({...ask,name:e.target.value})} /></div>
    
      <div className='mt-2'>
      <label htmlFor="question">Question no or Question :</label>
       <div><textarea type="text" className='border rounded-md p-1' rows="4" cols="30" placeholder='Qestion no or Question' value={ask.question} onChange={(e)=>setAsk({...ask,question:e.target.value})} /></div>
      </div>
      <Button variant="contained" disabled={(ask.question && ask.name ) ? false : true} color='primary' type='submit'>submit
      <span>{createdata?.loading&&<div className='button--loading py-[12px]'></div> }</span> 
      </Button>
      </div>
</form>
   <AllDataOfAsk localData={localData} setId={setId} setShowComment={setShowComment} createdata={createdata} />
    </div>
  )
}

export default AskToSahil
