import React from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { testSeriesChapterName } from '../../redux/actions/Test/Physics/30Tests/chapterNameAction';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

const ChaptersList = () => {
    const [navbarMenu,setNavbarMenu] = useState(false);
 
  const {data,loading}  = useSelector(state=>state.physicsChapterName);
    const history = useNavigate();
    const dispatch = useDispatch();
    const log = useRef(true);
    const [path,setPath] = useState('');
    const location = useLocation();
  
  useEffect(() => {
    const pat = location.pathname.split('/');
      setPath(pat[1]);
     
      //setSubject(pat[3].split('-').join(' ').charAt(0).toUpperCase()+ pat[3].split('-').join(' ').slice(1));
   }, [location,path]);
  
    useEffect(() => {
      if(log.current){
         dispatch(testSeriesChapterName());
         log.current=false;
      }
     
    }, [])
    //chapterName?split(' ').join('-').toLowerCase();
    const singleItem=async(id)=>{
       const item =await data?.find(x=>x._id===id)?.chapterName?.split(' ').join('-').toLowerCase();
       history(`/${path}/${item}`,{replace:true})
       // onClick={()=>singleItem(chapter._id)}     this is in Link
     }
     if(loading){
      return (
        <div>
          <Loading />
        </div>
       )
  }
  return (
    <div>
      {/* <Navbar height={height} subject={subject} /> */}
      <div className='fixed w-full top-0' ><Navbar  navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} /></div>
      <Sidebar navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} />
      <div className={`md:ml-[300px] mt-[86px] mr-2  ease-in-out duration-300 `}>
     {data?.map((chapter,i)=>(
      <Link onClick={()=>singleItem(chapter._id)} key={i} >
       {
          path==="test-series"?(
            <div>
          {
            i<=8 &&<div className='flex gap-2 z-0 border-b rounded hover:bg-deep-blue hover:text-white transition duration-300 ease-in-out  p-10'  >
                <div className='text-[17px] sm:text-[20px] ml-7 sm:ml-16 cursor-pointer  font-roboto font-semi ' >{chapter.chapterName}</div>
               </div>
          }</div>)
          :
          <div className='flex gap-2 z-0 border-b rounded hover:bg-deep-blue hover:text-white transition duration-300 ease-in-out  p-10'  >
             <div className='text-[17px] sm:text-[20px] ml-7 sm:ml-16 cursor-pointer  font-roboto font-semi ' >{chapter.chapterName}</div>
          </div>
       
       }
       </Link>
            
       
       
     ))}
     </div>
    </div>
  )
}

export default ChaptersList
