export const conceptsOfLimitsRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'LIMITS_CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'LIMITS_CONCEPT_SUCCESS':
            return {...state,data:action.payload,loading:false}
        case 'LIMITS_CONCEPT_FAIL':
             return {...state,loading:false}
        default:
            return state
    }
}

export const examplesOfLimitsRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'LIMITS_EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'LIMITS_EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'LIMITS_EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const reviseConceptsOfLimitsRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'LIMITS_REVISE_REQUEST':
            return {...state,loading:true}
        case 'LIMITS_REVISE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'LIMITS_REVISE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//more

export const moreQueLimitsRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_LIMITS_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_LIMITS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_LIMITS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsLimitsRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_ANS_LIMITS_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_LIMITS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_LIMITS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}