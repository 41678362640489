
export const getOtpRed=(state={},action)=>{
    switch(action.type){
        case 'GETOTP_REQUEST':
            return { loading:true }
        case 'GETOTP_SUCCESS':
           return {data:action.payload,loading:false}
        case 'GETOTP_FAIL':
           return {loading:false,error:action.payload}
        
        default:
            return state
    }
}


export const checkOtpRed=(state={},action)=>{
    switch(action.type){
        case 'CHECKOTP_REQUEST':
            return { loading:true }
        case 'CHECKOTP_SUCCESS':
           return { data:action.payload,loading:false }
        case 'CHECKOTP_FAIL':
           return { loading:false,error:action.payload }
        case 'USER_SIGNOUT':
            return {}
        default:
            return state
    }
}

export const loginRed=(state={},action)=>{
    switch(action.type){
        case 'LOGIN_REQUEST':
            return { loading:true }
        case 'LOGIN_SUCCESS':
           return { data:action.payload,loading:false }
        case 'LOGIN_FAIL':
           return { loading:false,error:action.payload }
        case 'USER_SIGNOUT':
            return {}
        default:
            return state
    }
}

export const confirmLoginFinalLoginRed=(state={},action)=>{
    switch(action.type){
        case 'CONFIRM_OTP_LOGIN_REQUEST':
            return { loading:true }
        case 'CONFIRM_OTP_LOGIN_SUCCESS':
           return { data:action.payload,loading:false }
        case 'CONFIRM_OTP_LOGIN_FAIL':
           return { loading:false,error:action.payload }
        default:
            return state
    }
}


  export const loggedInUsrRemoveRed=(state={},action)=>{
    switch(action.type){
        case 'REMOVE_LOGGED_IN_USR_REQUEST':
            return { loading:true }
        case 'REMOVE_LOGGED_IN_USR_SUCCESS':
           return { data:action.payload,loading:false }
        case 'REMOVE_LOGGED_IN_USR_FAIL':
           return { loading:false,error:action.payload }
        default:
            return state
    }
}
