import axios from 'axios';

//const url =   'http://localhost:8000/ask-sahil';
const url = 'https://whiteboardblackpen-tge6.onrender.com/ask-sahil'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/ask-sahil`

const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });

  export const AskToSahilGetAction = ()=>async(dispatch)=>{
    dispatch({type:"GET_ASK_TO_SAHIL_REQUEST"});
    try {
     const {data} = await API.get('/get');
     dispatch({type:"GET_ASK_TO_SAHIL_SUCCESS",payload:data})
    } catch (error) {
      
      dispatch({type:"GET_ASK_TO_SAHIL_FAIL",payload:error});
    }
  }

  export const AskToSahilCreateAction = (ask) => async(dispatch) => {
    dispatch({type:"CREATE_ASK_TO_SAHIL_REQUEST"});
    try {
     const {data} = await API.post('/create',ask);
     dispatch({type:"CREATE_ASK_TO_SAHIL_SUCCESS",payload:data})
    } catch (error) {
      
      dispatch({type:"CREATE_ASK_TO_SAHIL_FAIL",payload:error});
    }
  }


  export const CreateCommentsToSahilAskAction = (comment) => async(dispatch) => {
    dispatch({type:"CREATE_COMMENTS_REQUEST"});
    try {
     const {data} = await API.put('/comment-post',comment);
     dispatch({type:"CREATE_COMMENTS_SUCCESS",payload:data});
    } catch (error) {
      dispatch({type:"CREATE_COMMENTS_FAIL",payload:error});
    }
  }




  export const AddLikeToSahilAskAction = (id) => async(dispatch) => {
    dispatch({type:"ADD_LIKE_REQUEST"});
    try {
     const {data} = await API.put('/add-like',{ id });
     dispatch({type:"ADD_LIKE_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"ADD_LIKE_FAIL",payload:error});
    }
  }



  export const RemoveLikeToSahilAskAction = (id) => async(dispatch) => {
    dispatch({type:"REMOVE_LIKE_REQUEST"});
    try {
     const {data} = await API.put('/remove-like',{ id });
     dispatch({type:"REMOVE_LIKE_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"REMOVE_LIKE_FAIL",payload:error});
    }
  }



  //  NESTED COMMENENT

  export const AddCommentsOnCommentsToSahilAskAction = (comment) => async(dispatch) => {
   
    dispatch({type:"CREATE_COMMENTS_COMMENTS_REQUEST"});
    try {
     const {data} = await API.put('/comment-on-comment',comment);
     dispatch({type:"CREATE_COMMENTS_COMMENTS_SUCCESS",payload:data});
    
    } catch (error) {
      dispatch({type:"CREATE_COMMENTS_COMMENTS_FAIL",payload:error});
    }
  }



  export const AddLikeOnCommentsToSahilAskAction = (id ,commentId) => async(dispatch) => {
    dispatch({type:"ADD_LIKE_ON_COMMENT_REQUEST"});
    try {
     const {data} = await API.put('/add-like-on-comment',{ id ,commentId });
     dispatch({type:"ADD_LIKE_ON_COMMENT_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"ADD_LIKE_ON_COMMENT_FAIL",payload:error});
    }
  }



  export const RemoveLikeOnCommentsToSahilAskAction = (id ,commentId) => async(dispatch) => {
    dispatch({type:"REMOVE_LIKE_ON_COMMENT_REQUEST"});
    try {
     const {data} = await API.put('/remove-like-on-comment',{ id ,commentId });
     dispatch({type:"REMOVE_LIKE_ON_COMMENT_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"REMOVE_LIKE_ON_COMMENT_FAIL",payload:error});
    }
  }
