import { Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { InlineMath } from 'react-katex';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import PaginateText from '../PaginateTest';
import { multiTestAction, multiTestresponseAnswerAction, multiTestresponseAnswerActionAllData } from '../../redux/actions/Test/Physics/multiTestAction';

const MultipleTest = ({page,setPage}) => {
  
  const allResponseData = useSelector(state=>state.multiTestResponseAllDataRed);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const [responseOption,setResponseOption] = useState({response:[],id:null})
  const {data,loading } = useSelector(state=>state.multiTestPhysicsRed);
  const [value,setValue] = useState(null)
  const responseTest = useSelector(state=>state.multiTestResponseRed);
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(multiTestAction(page));
    setResponseOption({response:[]});
  },[page,dispatch])
 
  const optionSelect=(i,id)=>{
     
    if(squaredNum?.length===0){
     if(!responseOption.response.includes(i)){
       setResponseOption({response:[...responseOption.response,i],id:id});
     }else {
      setResponseOption({response:responseOption.response.filter((x=>x!==i)),id:id});
     }
     }
    
   }
  
  function handleSubmit(e){
    e.preventDefault();
       dispatch(multiTestresponseAnswerAction(responseOption.response,responseOption.id))
    }

 useEffect(()=>{
  
    dispatch(multiTestresponseAnswerActionAllData());
  },[ responseTest,responseOption.response,dispatch,data]);


const squaredNum = useMemo(()=> {
    if(data?.results){
        return allResponseData?.data?.gettingResponses?.filter(x=>x.questionId===(data?.results[0]._id));
  
    }
 
}, [allResponseData,data,userData]);

 useEffect(()=>{
  const value =  squaredNum&&squaredNum[0]?.response?.filter((x)=>!data?.results[0].correctanswer.includes(x) );
   setValue(value);
  
 },[data,allResponseData]);

   //

return (
  <div>
   
    <form onSubmit={handleSubmit}>
      <div className=' border h-screen overflow-auto' >
      <div className=' absolute bottom-0 right-2 mb-3 mt-3'>
        {
        <div className='relative z-40'> <PaginateText setPage={setPage} page={page}  data={data}  /></div>
        }

      </div>
      <main className='md:ml-[400px]  mt-[85px] flex justify-center items-center text-gray-900'>
      <div className='ml-1 w-[900px]'>
      {loading?<Loading />:
      data&&data?.results?.map(x=>(
      <div key={x._id}>
      <div className='definition' >

        <div className='' >
            <h2 style={{color:"#005DFF"}}> {x.heading}</h2>
              <div className='flex ' >
              <div className='mr-3'>{x.Qno}.</div>
              <div style={{color:"#005DFF"}} className='text-[9px] vvs:text-[11px] xs:text-[15px] sm:text-[17px] ' >  {x.Question}</div>
              {x.Question2&&<div className='text-[9px]  vvs:text-[11px] xs:text-[13px] sm:text-[17px] -z-10 ' style={{color:"#005DFF"}} > <InlineMath displayMode={true} options={{strict: false}} math={x.Question2} errorColor={'#cc0000'} /></div>}
              
              </div>
              <div><img className='w-[200px]' src={x.image} alt="" /></div>
            </div>    
        </div> 
        {x.options &&
          x.options.map((opt,i)=>(
          <div key={i} className='relative' >
             <div className={`flex relative z-10 definition items-center cursor-pointer rounded ${responseOption.response.includes(i) && 'selecting' } ${squaredNum && squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id&&value&&value.includes(i) && 'error'} ${squaredNum&&squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id&& x.correctanswer.includes(i) && 'success' } `} onClick={()=>optionSelect(i,x._id)} >
             <div className={`absolute right-4 -top-3  ${squaredNum&&squaredNum.length!==0 && x.correctanswer.includes(i) && "text-green-600"} ${ value&&value.includes(i) &&" text-error"}`}>{(squaredNum&&squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id&& x.correctanswer.includes(i) && 'Correct-Answer')||(squaredNum&&squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id&&value&&value.includes(i) && "Wrong-Answer")}</div>
             <div className={`mr-8 text-[17px] absolute right-1 top-0 option px-[8px] py-[0px] rounded-full ${squaredNum&&squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id && value&&value.includes(i) && 'bg-error text-white'} ${squaredNum&&squaredNum.length!==0 && squaredNum[0]?.creator===userData.id && x.correctanswer.includes(i) && 'bg-success text-white'} `} >{String.fromCharCode(i+97)}</div>
            
                <div className={`w-[120px] h-4  absolute right-3 -top-2 -z-40 ${squaredNum&&squaredNum.length!==0 && x.correctanswer.includes(i) && "bg-white"} ${ value&&value.includes(i) &&" bg-white"}`} ></div>
               
                <div>{opt}</div>
            
            </div>
            </div>
            )) 
      }
      {x.optionLatex &&
          x.optionLatex.map((opt,i)=>(
          <div key={i} className='relative ' >
          <div className={`d-flex relative z-10 definition items-center cursor-pointer rounded ${responseOption.response.includes(i) && 'selecting' } ${squaredNum && squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id&&value&&value.includes(i) && 'error'} ${squaredNum&&squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id&& x.correctanswer.includes(i) && 'success' } `} onClick={()=>optionSelect(i,x._id)} >
          <div className={`absolute right-4 -top-3 ${squaredNum&&squaredNum.length!==0 && x.correctanswer.includes(i) && "text-green-600"} ${ value&&value.includes(i) &&" text-error"}`}>{(squaredNum&&squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id&& x.correctanswer.includes(i) && 'Correct-Answer')||(squaredNum&&squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id&&value&&value.includes(i) && "Wrong-Answer")}</div>
          <div className={`mr-5 text-[19px] option w-6 h-6 flex justify-center items-center rounded-full ${squaredNum&&squaredNum.length!==0 &&squaredNum[0]?.creator===userData.id && value&&value.includes(i) && 'bg-error text-white'} ${squaredNum&&squaredNum.length!==0 && squaredNum[0]?.creator===userData.id && x.correctanswer.includes(i) && 'bg-success text-white'} `} >{String.fromCharCode(i+97)}</div>
          <div className={`w-[120px] h-4  absolute right-3 -top-2 -z-40 ${squaredNum&&squaredNum.length!==0 && x.correctanswer.includes(i) && "bg-white"} ${ value&&value.includes(i) &&" bg-white"}`} ></div>
          <div className='p-2 text-[13px] ss:text-[16px]'> <InlineMath displayMode={true} options={{strict: false}} math={opt} errorColor={'#cc0000'} /></div>
         

            </div>
            </div>
            )) 
      }

      {/* {squaredNum&&(squaredNum[0]?.creator===userData.id) &&
      <div className='third relative p-3 rounded  text-[13px] ss:text-[16px] mb-12' >
      { squaredNum&& squaredNum[0].solution2&&<div className='text-[9px] vvs:text-[11px] xs:text-[13px]' ><InlineMath displayMode={true} options={{strict: false}} math={squaredNum&& squaredNum[0].solution2} errorColor={'#cc0000'} /></div>} 
      {squaredNum&& squaredNum[0].solution && <div className='defini' >{squaredNum[0].solution}</div> }

      {squaredNum&& squaredNum[0].link && <a href={squaredNum[0].link} className='cursor-pointer' >Click me for your Solution</a>  }

      </div>

      } */}
      </div>

      ))}

      </div>
      </main>
      <div className='absolute left-3 md:ml-[400px] bottom-3' >
      {
      (data)&& <Button disabled={squaredNum&&(data?.results[0]?._id=== squaredNum[0]?.questionId ) &&(squaredNum&& squaredNum[0]?.creator===userData.id)?true:false} variant="contained" type='submit' color='success' >Submit
      <span>{responseTest?.loading&&<div className='button--loading py-[12px]'></div> }</span> 
      </Button>

      }
      </div>
      </div>
      </form> 

        </div>
)
}

export default MultipleTest
