import  axios  from "axios";
let url = 'https://whiteboardblackpen-tge6.onrender.com/limits';
//let url = `${process.env.REACT_APP_BACKEND_DOMAIN}/limits`

const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });

export const conceptOfLimits=(page)=>async(dispatch)=>{
    dispatch({type:"LIMITS_CONCEPT_REQUEST"});
    try {
     const {data} = await API.get(`/concept?page=${page}&limit=${2}`);
    
     dispatch({type:"LIMITS_CONCEPT_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"LIMITS_CONCEPT_FAIL"});
    }
  }
  
  // examples
  
  export const exampleOfLimits=(page2)=>async(dispatch)=>{
    dispatch({type:"LIMITS_EXAMPLE_REQUEST"});
    try {
     const {data} = await API.get(`/example?page=${page2}&limit=${2}`);
    
     dispatch({type:"LIMITS_EXAMPLE_SUCCESS",payload:data})
    } catch (error) {
   
      dispatch({type:"LIMITS_EXAMPLE_FAIL"});
    }
  }


  export const reviseConceptOfLimits=(id)=>async(dispatch)=>{
    dispatch({type:"LIMITS_REVISE_REQUEST"});
   
    try {
     const {data} = await API.post(`/revise`,{ id });
     dispatch({type:"LIMITS_REVISE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"LIMITS_REVISE_FAIL"});
    }
  }

//more
  export const moreQuestionOfLimitsAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_QUE_LIMITS_REQUEST"});
   
    try {
      
     const {data} = await API.get(`/more/question?page=${page}&limit=${12}`);
    
     dispatch({type:"MORE_QUE_LIMITS_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_QUE_LIMITS_FAIL"});
    }
  }
  
  export const moreAnswerOfLimitsAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_ANS_LIMITS_REQUEST"});
   
    try {
     const {data} = await API.get(`/more/answer?page=${page}&limit=${12}`);
     dispatch({type:"MORE_ANS_LIMITS_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_ANS_LIMITS_FAIL"});
    }
  }