export const conceptsOfProjectile=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'PROJECTILE_CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'PROJECTILE_CONCEPT_SUCCESS':
            return {...state,data:action.payload,loading:false}
        case 'PROJECTILE_CONCEPT_FAIL':
             return {...state,loading:false}
        default:
            return state
    }
}

export const examplesOfProjectile=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'PROJECTILE_EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'PROJECTILE_EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'PROJECTILE_EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const reviseConceptsOfProjectileRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'PROJECTILE_REVISE_REQUEST':
            return {...state,loading:true}
        case 'PROJECTILE_REVISE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'PROJECTILE_REVISE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//more

export const moreQueProjectileRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_PROJECTILE_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_PROJECTILE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_PROJECTILE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsProjectileRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_ANS_PROJECTILE_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_PROJECTILE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_PROJECTILE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}