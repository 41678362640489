export const mathchapterName=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MATHCHAPTERNAME_REQUEST':
           return {...state,loading:true}
        case 'MATHCHAPTERNAME_SUCCESS':
            return {...state,data:action.payload,loading:false}
         case 'MATHCHAPTERNAME_FAIL':
            return {...state,loading:false}
        default:
            return state
    }
}
//...state,data:action.payload,loading:true

export const concepts=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'CONCEPT_SUCCESS':
            return {...state,data:action.payload,loading:false}
        case 'CONCEPT_FAIL':
             return {...state,loading:false}
        default:
            return state
    }
}

export const examples=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//JEEMAINS

export const jeemainRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'JEEMAINS_REQUEST':
            return {...state,loading:true}
        case 'JEEMAINS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'JEEMAINS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const updateJeemainRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'UPDATEONE_REQUEST':
            return {...state,loading:true}
        case 'UPDATEONE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'UPDATEONE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const updateJeemainState=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'STATE_REQUEST':
            return {...state,loading:true}
        case 'STATE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'STATE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}


//JEEADVANCE

export const advanceIndiQuesionsRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'ADVANCE_INDIF_QUESTIONS_REQUEST':
            return {...state,loading:true}
        case 'ADVANCE_INDIF_QUESTIONS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ADVANCE_INDIF_QUESTIONS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const advanceIndClicked=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'ADVANCE_INDIF_CLICKED_REQUEST':
            return {...state,loading:true}
        case 'ADVANCE_INDIF_CLICKED_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ADVANCE_INDIF_CLICKED_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const advanceIndOptions=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'ADVANCE_INDIF_OPTION_REQUEST':
            return {...state,loading:true}
        case 'ADVANCE_INDIF_OPTION_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ADVANCE_INDIF_OPTION_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}
export const sidebarAdvanceQuestionOfIndifinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'SIDEBAR_ADV_IND_QUESTIONS_REQUEST':
            return {...state,loading:true}
        case 'SIDEBAR_ADV_IND_QUESTIONS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'SIDEBAR_ADV_IND_QUESTIONS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}
//SIDEBAR_MAIN

export const reviseConcepts=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'REVISE_REQUEST':
            return {...state,loading:true}
        case 'REVISE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'REVISE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const sidebarMains=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'SIDEBAR_MAIN_REQUEST':
            return {...state,loading:true}
        case 'SIDEBAR_MAIN_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'SIDEBAR_MAIN_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}
//MORE_QUE_INDIFINITE

export const moreQueIndifinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_INDIFINITE_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_INDIFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_INDIFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsIndifinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_ANS_INDIFINITE_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_INDIFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_INDIFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

// response for mains

export const ResponseJeemainsIndifinite=(state={data:[]},action)=>{
    switch(action.type){
        case 'RESPONSE_JEEMAINS_ANS_INDIFINITE_REQUEST':
            return {...state,loading:true}
        case 'RESPONSE_JEEMAINS_ANS_INDIFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'RESPONSE_JEEMAINS_ANS_INDIFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//ALLDATA for mains

export const ResponseJeemainsAllDataIndifinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'RESPONSE_JEEMAINS_ANS_INDIFINITE_ALLDATA_REQUEST':
            return {...state,loading:true}
        case 'RESPONSE_JEEMAINS_ANS_INDIFINITE_ALLDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'RESPONSE_JEEMAINS_ANS_INDIFINITE_ALLDATA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

// response for mains

export const ResponseAdvanceIndifinite=(state={data:[] },action)=>{
    switch(action.type){
        case 'RESPONSE_ADVANCE_ANS_INDIFINITE_REQUEST':
            return {...state,loading:true}
        case 'RESPONSE_ADVANCE_ANS_INDIFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'RESPONSE_ADVANCE_ANS_INDIFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//ALLDATA for mains

export const ResponseAdvanceAllDataIndifinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'RESPONSE_ADVANCE_ANS_INDIFINITE_ALLDATA_REQUEST':
            return {...state,loading:true}
        case 'RESPONSE_ADVANCE_ANS_INDIFINITE_ALLDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'RESPONSE_ADVANCE_ANS_INDIFINITE_ALLDATA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}