import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Physics from './physicsContent/Physics';
import Home from './home/Home';
import Maths from './mathsContent/Maths';
import Integration from './mathsContent/integrals/Integration';
import IndefiniteIntegrals from './mathsContent/integrals/integralsTypes/IndefiniteIntegrals';
import DefiniteIntegrals from './mathsContent/integrals/integralsTypes/DefiniteIntegrals';
import { useEffect, useState } from 'react';
import PreviousPaper from './mathsContent/tabs/tab3/PreviousPaper';
import MoreProblems from './mathsContent/tabs/tab3/moreProblems/MoreProblems';
import Derivative from './mathsContent/Derivatives/Derivative';
import Limits from './mathsContent/Limits/Limit';
import DiffrentialEquation from './mathsContent/Differential Equation/DiffrentialEquation';
import ApplicationDerivative from './mathsContent/applicationOfDerivatis/ApplicationDerivative';
import ApplicationDerivativeTab from './mathsContent/applicationOfDerivatis/ApplicationDerivativeTab';
import Front from './home/uiDesign/Front';
import About from './home/uiDesign/scenes/about/About';
import Function from './mathsContent/Function/Function';
import YourProfile from './InterestingScreen/Profile/YourProfile';
import Test from './Test/PhysicsTest/Test';
import AboutCreator from './InterestingScreen/about/AboutCreator';
import DownLoads from './InterestingScreen/downLoads/DownLoads';
import Empty from './physicsContent/Empty';
import NewtonsLaws from './physicsContent/NLM/NewtonsLaws';
import WorkEnergy from './physicsContent/WorkEnergy/WorkEnergy';
import Projectile from './physicsContent/Projectile/Projectile';
import ChaptersList from './Test/TestSeries/ChaptersList';
import ProjectileTestSeries from './Test/TestSeries/ProjectileMotion/ProjectileTestSeries';
import CircularMotion from './physicsContent/CircularMotion/CircularMotion';
import DailyPracticeTest from './Test/PhysicsTest/DailyPracticeTest';
import MultipleTestHome from './Test/PhysicsTest/MultipleTestHome';
import Contact from './home/uiDesign/scenes/Contact';
import AskToSahil from './InterestingScreen/Ask-to-sahil/AskToSahil';
import ChallengingProblems from './Challenging-Problems/ChallengingProblems';
import Irodov from './Irodov/Irodov';
import MockTest from './Mock-Test/MockTest';
import { testSeriesChapterName } from './redux/actions/Test/Physics/30Tests/chapterNameAction';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminCatagory from './Admin/AdminCatagory';


function App() {
  const { data }  = useSelector(state=>state.physicsChapterName);
  const [accountToggle, setAccountToggle] = useState({
    sign:false,
    signup:false
  });
  //const [path,setPath] = useState('');
  const [loginAfterVerify,setLoginAfterVerify] = useState(false)
  
  const [isTopOfPage, setIsTopOfPage] = useState(true);
  
  useEffect(() => {
   
    const handleScroll=()=>{
      if(window.scrollY===0) setIsTopOfPage(false);
      else setIsTopOfPage(false)
    }
    window.addEventListener('scroll',()=>{
     
      if(window.scrollY<=550) setIsTopOfPage(true);
      else setIsTopOfPage(false)
    })
    return window.removeEventListener('scroll',handleScroll)
  }, []);
  const log = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if( log.current ){
       dispatch(testSeriesChapterName());
       log.current = false;
    }

  }, []);
 
  return (
  <div className='relative'>
   
    <BrowserRouter>
    <Routes>
        <Route path="/" exact  element={ <Front loginAfterVerify={loginAfterVerify} setLoginAfterVerify={setLoginAfterVerify} accountToggle={accountToggle} setAccountToggle={ setAccountToggle } />}/>
        <Route path="/learn"  element={ <Home />}/>
       
        <Route path="/about-us"  element={ <About setLoginAfterVerify={setLoginAfterVerify} accountToggle={accountToggle} setAccountToggle={ setAccountToggle } />}/>
        <Route path="/contact"  element={ <Contact />}/>

        
        
         <Route path="/subject/maths" element={ <Maths />}/>
         {/* <Route path="/subject/physics/laws-of-motion" element={ <NewtonsLaws/>}/> */}
         <Route path="/subject/maths/integrals" element={<Integration/>}/>
         <Route path="/subject/maths/integrals/indefinite-integrals" element={ < IndefiniteIntegrals  />}/>
         <Route path="/subject/maths/integrals/definite-integrals" element={ < DefiniteIntegrals  />}/>
         <Route path="/subject/maths/integrals/indefinite-integrals/exercises/previousyears" element={ <PreviousPaper  />}/>
         <Route path="/subject/maths/integrals/indefinite-integrals/exercises/moreproblems" element={ <MoreProblems />}/>
         {/* definite integral */}
         <Route path="/subject/maths/integrals/definite-integrals/exercises/previousyears" element={ <PreviousPaper  />}/>
         <Route path="/subject/maths/integrals/definite-integrals/exercises/moreproblems" element={ <MoreProblems />}/>

         {/* Derivatives */}
        
         <Route path="/subject/maths/derivative" element={ <Derivative />}/>
         {/* limits */}
         <Route path="/subject/maths/limits" element={ <Limits />}/>
         {/* differential equation */}
         <Route path="/subject/maths/differential-equation" element={ <DiffrentialEquation />}/>
         {/* application derivative */}
         <Route path="/subject/maths/application-of-derivatives" element={ <ApplicationDerivative />}/>
         <Route path="/subject/maths/application-of-derivatives/tangent-normal" element={ <ApplicationDerivativeTab />}/>
         <Route path="/subject/maths/application-of-derivatives/maxima-minima" element={ <ApplicationDerivativeTab />}/>
          {/* FUNCTION */}
          <Route path="/subject/maths/functions" element={ <Function />}/>
          
          {/* YOUR PROFILE */}
          <Route path="/profile" element={ <YourProfile />}/>
          {/* TEST */}
          <Route path="/test" element={ <DailyPracticeTest />}/>
          <Route path="/test/single-correct" element={ <Test />}/>
          <Route path="/test/multiple-correct" element={ <MultipleTestHome />}/>
          
          {/* about creator */}
          <Route path="/aboutcreator" element={ <AboutCreator />}/>
          
          {/* DOWNLOADS */}
          <Route path="/downloads" element={ <DownLoads />}/>
          {/* ADMIN PANNEL */}
          <Route path="/admin-catagory" element={ <AdminCatagory />}/>
         {/* //AdminDashboard */}
         {/* empty */}
          <Route path="/empty" element={ <Empty />}/>
                {/* PHYSICS */}
          <Route path="subject/physics"  element={ <Physics />}>
          {/* <Route path="laws-of-motion" element={ <NewtonsLaws />}/> */}
          </Route>
          <Route path="/subject/physics/laws-of-motion" element={ <NewtonsLaws />}/>
          <Route path="/subject/physics/work,-energy-and-power" element={ <WorkEnergy />}/>
          <Route path="/subject/physics/kinematics-i-&-ii" element={ <Projectile />}/>
          <Route path="/subject/physics/circular-motion" element={ <CircularMotion />}/>
          <Route path="/subject/physics/com,-linear-momentum-and-collison" element={ <CircularMotion />}/>
          <Route path="/subject/physics/rotational-mechanics" element={ <CircularMotion />}/>
          <Route path="/subject/physics/gravitation" element={ <CircularMotion />}/>
          <Route path="/subject/physics/vectors" element={ <CircularMotion />}/>
          <Route path="/subject/physics/electrostatics" element={ <CircularMotion />}/>
          
          {/* test series chapters name */}
          {
            data?.message !== "jwt expired" &&(
              <React.Fragment>
             <Route path="/test-series" element={ <ChaptersList />}/>
             {

           data?.length!==0 && data?.map(x=>(
           <Route path={`/test-series/${x.chapterName?.split(' ').join('-').toLowerCase()}`} element={ <ProjectileTestSeries />} key={x._id} />
           ))
           }
             </React.Fragment>
            )
          }
         
        
          
          
          {/* Ask to sahil */}
          <Route path="/ask-to-sahil" element={ <AskToSahil />}/>
           {/* CHALLENGING PROBLEMS */}
           <Route path="/challenging-problems" element={ <ChallengingProblems />}/>

           {/* IRODOVVVV */}
           <Route path="/irodov-problems-video-solutions" element={ <Irodov />}/>;
           
           {/* MOCK TESTTT */}

           <Route path="/mock-test" element={ <ChaptersList />}/>;
            {data?.message !== "jwt expired" && 
         data?.length!==0 && data?.map(x=>(
           <Route path={`/mock-test/${x.chapterName?.split(' ').join('-').toLowerCase()}`} element={ <MockTest />} key={x._id} />
   ))
   } 
           
           {/* <Route path="/mock-test/results" element={ <Results />}/>; */}
           <Route path="/test-series/*" element={<NotFound />} />
           <Route path="/mock-test/*" element={<NotFound2 />} />
           <Route path="*" element={<NotFound3 />} />
           
           
    </Routes>
  </BrowserRouter>
  </div>
  );
}

export default App;

const NotFound = () => {
  // Render a "404 Not Found" page or redirect to a default route
  // For example, you can render a custom 404 page
  return <h1> </h1>;
};
const NotFound2 = () => {
  // Render a "404 Not Found" page or redirect to a default route
  // For example, you can render a custom 404 page
  return <div className='button--loading2 absolute left-4 top-6'></div>;
};
const NotFound3 = () => {
  // Render a "404 Not Found" page or redirect to a default route
  // For example, you can render a custom 404 page
  return <div>404 Page Not Found</div>;
};