import { Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { InlineMath } from 'react-katex';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import { responseAnswerOfMockTestAction, responseAnswerOfMockTestAllDataAction, responseAnswerOfMockTestUpdateAction } from '../redux/actions/Mock-Test/MockTestAction';
import SendIcon from '@mui/icons-material/Send';
import SelectingQuestion from './SelectingQuestion';
import useMediaQuery from '../components/hook/UseMediaQuery';



const MockTestQuestion = ({ page,setPage,path }) => {
  
  const allResponseData = useSelector(state=>state.responseAnswerOfMockTestAllDataRed);
  const updateResponse = useSelector(state=>state.responseAnswerOfMockTestUpdateRed);
  const mobile = useMediaQuery('(min-width: 600px)');
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const [responseOption,setResponseOption] = useState({response:null,id:null})
  const { data,loading } = useSelector(state=>state.mockTestAllQuestionsRed);
  const responseTest = useSelector(state=>state.responseAnswerOfMockTestRed);
  const dispatch = useDispatch();
  const optionSelect=(i,id)=>{
    if(squaredNum && (data?.results[0]?._id !== squaredNum[0]?.questionId ) &&(squaredNum && squaredNum[0]?.creator!==userData.id)){
      setResponseOption({response:i,id:id});
    }
    setResponseOption({response:i,id:id})
  }
  
  function handleSubmit(e){
    e.preventDefault();
    if(squaredNum&&squaredNum.length!==0){
      dispatch(responseAnswerOfMockTestUpdateAction(responseOption.response,responseOption.id));
      if(data.next){
        setPage(page+1);
      }
    
      
    }else{
      dispatch(responseAnswerOfMockTestAction(responseOption.response,responseOption.id));
      if(data.next){
        setPage(page+1);
      }
    }
    setResponseOption({...responseOption,id:null})
    }
   
    
 useEffect(()=>{
    dispatch(responseAnswerOfMockTestAllDataAction(path));
  },[ dispatch,responseTest,data,responseOption.response,path,updateResponse]);

const squaredNum = useMemo(()=> {
    if(data?.results){
        return allResponseData?.data?.gettingResponses?.filter(x=>x.questionId === (data?.results[0]._id));
  }
 }, [allResponseData,data]);

 
  return (
    <div>
     
      <form onSubmit={handleSubmit}>
<div className='mt-4' >
{
  mobile ? (
    <div className=' flex '>
   
    
     <div className='justify-center absolute -bottom-2 flex mb-3 mt-3'><SelectingQuestion page={page} setPage = {setPage} data = {data} squaredNum = { squaredNum } userData = { userData } allResponseData = { allResponseData } path = { path } /></div>

 </div>
  ):(
    <div className='items-center justify-center flex '>
   
     <div className='flex mb-3 mt-3'><SelectingQuestion page={page} setPage = {setPage} data = {data} squaredNum = { squaredNum } userData = { userData } allResponseData = { allResponseData } path = { path } /></div>

 </div>
  )
 }
  
<main className='flex justify-center items-center text-gray-900'>

<div className='ml-1 w-[900px]'>
 
 { loading?<Loading />:
 
data&&data?.results?.map(x=>(
<div key={x._id}>
 <div className='definition' >
    <div className='' >
          <div className='flex' >
           <div className='mr-2 py-2'>Q({x.questionNo}).</div>
           <div>
               <div className='py-2 defini' style={{color:"#005DFF" ,userSelect: "none" }}>  {x.question}</div>
              { x.questionLatex && <div className='text-[9px] relative -top-1 vvs:text-[11px] xs:text-[13px] sm:text-[16px] -z-10 ' style={{color:"#005DFF",userSelect: "none"}} > <InlineMath displayMode={true} options={{strict: false}} math={x.questionLatex} errorColor={'#cc0000'} /></div> }
           </div>
           </div>
           <div><img className='w-[200px]' src={x.image} alt="" /></div>
        </div>    
        
     </div> 
    {
     
      x.options.length!== 0 ?(   x.options.map((opt,i)=>(
               <div key={i} className='relative' >
               <div className={`flex relative definition items-center cursor-pointer rounded ${squaredNum&&squaredNum[0]?.creator===userData.id &&squaredNum&&squaredNum[0]?.response===i&&squaredNum&&squaredNum[0]?.response===x.correctanswer&&"selecting"} ${(squaredNum&&squaredNum[0]?.response!==x.correctanswer)&&squaredNum&&squaredNum[0]?.response===i&& squaredNum[0]?.creator===userData.id && "selecting"} ${(responseOption.response===i && responseOption.id===x._id  ) &&'selecting'}`} onClick={()=>optionSelect(i,x._id)} >
               <div className='mr-5 text-[19px] option ' >{String.fromCharCode(i+97)}</div>
               <div className='p-2 text-[13px] ss:text-[16px]' style={{userSelect: "none" }}> {opt}</div>
             
      
       </div>
         </div>
               
                   
  ))):(
    x.optionsLatex.map((opt,i)=>(
               <div key={i} className='relative' >
               <div className={`d-flex relative definition items-center cursor-pointer rounded ${squaredNum&&squaredNum[0]?.creator===userData.id &&squaredNum&&squaredNum[0]?.response===i&&squaredNum&&squaredNum[0]?.response===x.correctanswer&&"selecting"} ${(squaredNum&&squaredNum[0]?.response!==x.correctanswer)&&squaredNum&&squaredNum[0]?.response===i&& squaredNum[0]?.creator===userData.id && "selecting"} ${(responseOption.response===i && responseOption.id===x._id  ) &&'selecting'}`} onClick={()=>optionSelect(i,x._id)} >
               <div className='mr-5 text-[19px] option ' >{String.fromCharCode(i+97)}</div>
               <div className='p-2 text-[13px] ss:text-[16px]'> <InlineMath displayMode={true} options={{strict: false}} math={opt} errorColor={'#cc0000'} /></div>
             
      
       </div>
         </div>
    ))
  )
  }
  </div>

))}

</div>
<div>

</div>
</main>
<div className='absolute right-3  bottom-3' >
{
(data)&& <Button  variant="contained" disabled={data?.results&&data?.results[0]._id!==responseOption.id?true:false} type='submit' color='success' endIcon={<SendIcon />} >Save & Next
<span>{(responseTest?.loading||updateResponse?.loading)&&<div className='button--loading py-[12px]'></div> }</span> 
</Button>

}
</div>
</div>
 </form> 
  
    </div>
  )
}

export default MockTestQuestion


