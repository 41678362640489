import { mathchapterName,concepts,reviseConcepts,
        examples,jeemainRed,
        sidebarMains,
        moreQueIndifinite,moreAnsIndifinite,
        advanceIndiQuesionsRed,
        sidebarAdvanceQuestionOfIndifinite,
        ResponseJeemainsIndifinite,
        ResponseAdvanceIndifinite,
        ResponseAdvanceAllDataIndifinite,
        
        ResponseJeemainsAllDataIndifinite } from './mathsReducers/basicReducer'
import {conceptsOfDefinite,examplesOfDefinite,reviseConceptsOfDefinite,jeemainsOfDifinite,sidebarMainsOfDefinite ,
        advanceOfDifinite,sidebarAdvanceOfDefinite,
        moreQueDedifiniteRed,
        moreAnsDedifiniteRed,
        ResponseJeemainsDifiniteRed,
        ResponseAdvanceDifiniteRed,
        ResponseJeemainsAllDataDifiniteRed,
        ResponseAdvanceAllDataDifiniteRed,

} 
from '../reducers/mathsReducers/definiteIntegral/dfiniteConceptReducer'
import {physicsChapterName} from '../reducers/physicsReducers/chaptersreducers/chapterRedu'
import { combineReducers } from 'redux'
import {examplesOfDerevative,
        conceptsOfDerevative,
        reviseConceptsOfDerivativeRed, 
        moreQueDerivativeRed,
        moreAnsDerivativeRed} from './mathsReducers/derevative/derevativeReducer';
         
import { conceptsOfLimitsRed,examplesOfLimitsRed ,reviseConceptsOfLimitsRed,moreQueLimitsRed,moreAnsLimitsRed } from '../reducers/mathsReducers/limits/LimitsReducers';
import { conceptsOfDifferentialEquation,examplesOfDifferentialEquation,reviseConceptsOfDifferentialEquation,moreQueDifferentialEquation,moreAnsDifferentialEquation } from '../reducers/mathsReducers/differentialEquation/differentialEquationReducer';
import { conceptsOfTangentRed,examplesOfTangentRed,reviseConceptsOfTangentRed,moreQueTangentRed,moreAnsTangentRed } from '../reducers/mathsReducers/applicationOfDerivative/tangentReducer';
import { conceptsOfMaximaRed ,examplesOfMaximaRed,reviseConceptsOfMaximaRed,moreQueMaximaRed,moreAnsMaximaRed } from '../reducers/mathsReducers/applicationOfDerivative/maximaReducer';
import { contactUsRed ,getAllMessagesContactUsRed } from '../reducers/contactUsReducer/conatactUsReducers';
import { getOtpRed,checkOtpRed,loginRed,confirmLoginFinalLoginRed } from '../reducers/Auth/AuthReducers';
//function
import { conceptsOfFunctionRed, 
        examplesOfFunctionRed,
        reviseConceptsOfFunctionRed,
        moreQueFunctionRed,
        moreAnsFunctionRed } from '../reducers/mathsReducers/Function/functionReducers'
import { physicsTest1Red ,ResponseTest1Red,ResponseAllDataTest1Red ,physicsTest1SidebarRed} from './Test/physics/Test1Reducer';
import { isAdminRed,getAllLoggedInUsersRed,getAllCosingGuruUsersRed } from './Auth/IaAdminReducer.js';
//import { getAllFilesRed,uploadFileRed } from '../reducers/downloads/downLoadsReducers'
// physics
import { conceptsOfNewton,examplesOfNewton,moreQueNewtonRed,moreAnsNewtonRed } from '../reducers/physicsReducers/NLMReducers/NewtonReduce';
import { conceptsOfWorkEnergy,examplesOfWorkEnergy,reviseConceptsOfWorkEnergyRed,moreQueWorkEnergyRed,moreAnsWorkEnergyRed } from '../reducers/physicsReducers/WorkEnergy/workEnergyReducers';
import {conceptsOfProjectile,examplesOfProjectile,reviseConceptsOfProjectileRed,moreQueProjectileRed,moreAnsProjectileRed } from '../reducers/physicsReducers/Projectile/projectileReducers';
import { uploadProfileRed,userProfileRed,deleteProfileRed,getAllFilesRed,uploadFileRed } from './downloads/downLoadsReducers.js';
import {physicsTestSeriesRed,
        ResponseTestSeriesRed,
        ResponseAllDataTestSeriesRed,
        physicsTestSeriesSidebarRed } from './Test/30Test/ProjectileTestSeriesReducer';
import {allChaptersConceptsRed,
        allChaptersExamplesRed,
        reviseConceptsOfAllChaptersRed,
        moreQueAllChaptersRed,
        moreAnsAllChaptersRed,} from './physicsReducers/allChapters/allChaptersReducers';
import {multiTestPhysicsRed,
        multiTestResponseRed,
        multiTestResponseAllDataRed,
        multiTestphysicsSidebarRed } from './Test/physics/multiTestReducer.js';

import {getAllAskToSahilRed,createAskToSahilRed,
        CreateCommentsToSahilAskRed,
        AddLikeToSahilAskRed,
        RemoveLikeToSahilAskRed,
        AddCommentsOnCommentsToSahilAskRed,
        AddLikeOnCommentsToSahilAskRed,
        RemoveLikeOnCommentsToSahilAskRed } from './Ask-to-sahil/AskToSahilReducers.js';
import {challengingProblemsRed,
        challengingSolutionsRed,
        challengingSolutionsLikesRed,
        AllchallengingProblemsSolutionsRed,
        getByIdchallengingLikesRed } from './Challenging-Problems/ChallengingProblemsReducers.js';
import {
        mockTestAllQuestionsRed,
        responseAnswerOfMockTestRed,
        responseAnswerOfMockTestAllDataRed,
        responseAnswerOfMockTestUpdateRed,
        timerMockTestRed,
        resultsMockTestRed,
        mockTesResultTimeRed,
        getresultsMockTestRed,
        getAnswerSheetsMockTestAllDataRed
        
} from './Mock-Test/mockTestReducers.js'

export default combineReducers({
    concepts,
    examples,
    mathchapterName,
    jeemainRed,
    reviseConcepts,
    sidebarMains,
    moreQueIndifinite,
    moreAnsIndifinite,
    ResponseJeemainsIndifinite,
    ResponseJeemainsAllDataIndifinite,
    //advance
    advanceIndiQuesionsRed,
    sidebarAdvanceQuestionOfIndifinite,
    ResponseAdvanceIndifinite,
    ResponseAdvanceAllDataIndifinite,
    //definite
    conceptsOfDefinite,
    examplesOfDefinite,
    reviseConceptsOfDefinite,
    jeemainsOfDifinite,
    sidebarMainsOfDefinite,
    ResponseJeemainsDifiniteRed,
    ResponseJeemainsAllDataDifiniteRed,
    ResponseAdvanceDifiniteRed,
    ResponseAdvanceAllDataDifiniteRed,
    
    //definite advance
    advanceOfDifinite,
    sidebarAdvanceOfDefinite,
    moreQueDedifiniteRed,
    moreAnsDedifiniteRed,
    //derivative
    conceptsOfDerevative,
    examplesOfDerevative,
    reviseConceptsOfDerivativeRed,
    moreQueDerivativeRed,
    moreAnsDerivativeRed,
    // limits
    conceptsOfLimitsRed,
    examplesOfLimitsRed,
    reviseConceptsOfLimitsRed,
    moreQueLimitsRed,
    moreAnsLimitsRed,
    //differential equation
    conceptsOfDifferentialEquation,
    examplesOfDifferentialEquation,
    reviseConceptsOfDifferentialEquation,
    moreQueDifferentialEquation,
    moreAnsDifferentialEquation,
    //tangnt and normal 
    conceptsOfTangentRed,
    examplesOfTangentRed,
    reviseConceptsOfTangentRed,
    moreQueTangentRed,
    moreAnsTangentRed,
    //Maxima and Minima
    conceptsOfMaximaRed,
    examplesOfMaximaRed,
    reviseConceptsOfMaximaRed,
    moreQueMaximaRed,
    moreAnsMaximaRed,
    // FUNCTION
    conceptsOfFunctionRed,
    examplesOfFunctionRed,
    reviseConceptsOfFunctionRed,
    moreQueFunctionRed,
    moreAnsFunctionRed,
    //physics
    physicsChapterName,
    conceptsOfNewton,
    moreAnsNewtonRed,
    examplesOfNewton,
    moreQueNewtonRed,
    //work energy
    conceptsOfWorkEnergy,
    // all physics         
    allChaptersConceptsRed,
    allChaptersExamplesRed,
    reviseConceptsOfAllChaptersRed,
    moreQueAllChaptersRed,
    moreAnsAllChaptersRed,
    // contact us
    contactUsRed,
    getAllMessagesContactUsRed,

    examplesOfWorkEnergy,
    reviseConceptsOfWorkEnergyRed,
    moreQueWorkEnergyRed,
    moreAnsWorkEnergyRed,
   // projectile
   conceptsOfProjectile,
   examplesOfProjectile,
   reviseConceptsOfProjectileRed,
   moreQueProjectileRed,
   moreAnsProjectileRed,
    // Auth
    getOtpRed,
    checkOtpRed,
    loginRed,
    confirmLoginFinalLoginRed,
    // admin
    isAdminRed,
    getAllLoggedInUsersRed,
    getAllCosingGuruUsersRed,
    
    //TEST
    physicsTest1Red,
     ResponseTest1Red,
    ResponseAllDataTest1Red,
    physicsTest1SidebarRed,
    // files uploads
    getAllFilesRed,
    uploadFileRed,
   // upload profile
   uploadProfileRed,
   userProfileRed,
   deleteProfileRed,
   // test series
   physicsTestSeriesRed,
   ResponseTestSeriesRed,
   ResponseAllDataTestSeriesRed,
   physicsTestSeriesSidebarRed,
   // more than one correct answer

   multiTestPhysicsRed,
   multiTestResponseRed,
   multiTestResponseAllDataRed,
   multiTestphysicsSidebarRed,

   // ask to sahil

   getAllAskToSahilRed,
   createAskToSahilRed,
   CreateCommentsToSahilAskRed,
   AddLikeToSahilAskRed,
   RemoveLikeToSahilAskRed,
   AddCommentsOnCommentsToSahilAskRed,
   AddLikeOnCommentsToSahilAskRed,
   RemoveLikeOnCommentsToSahilAskRed,
   // REDUCERS

   challengingProblemsRed,
   challengingSolutionsRed,
   challengingSolutionsLikesRed,
   AllchallengingProblemsSolutionsRed,
   getByIdchallengingLikesRed,
   //  MOCK TESTTTT

   mockTestAllQuestionsRed,
   responseAnswerOfMockTestRed,
   responseAnswerOfMockTestAllDataRed,
   responseAnswerOfMockTestUpdateRed,
   timerMockTestRed,

   // resultsss
   resultsMockTestRed,
  // mockTesCompletetRed,
   //mockTesStartTimeRed,
   mockTesResultTimeRed,
   getresultsMockTestRed,
   getAnswerSheetsMockTestAllDataRed
   
})
