export const challengingProblemsRed = (state={data:[]},action)=>{
    switch(action.type){
        case 'CHALLENGING_PROBLEMS_REQUEST':
            return {...state,loading:true}
        case 'CHALLENGING_PROBLEMS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        // return { ...state, data: state.data.map((post) => (post._id === action.payload._id ? action.payload : post)) };

        case 'CHALLENGING_PROBLEMS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const AllchallengingProblemsSolutionsRed = (state={data:[]},action)=>{
    switch(action.type){
        case 'ALL_CHALLENGING_SOLUTIONS_REQUEST':
            return {...state,loading:true}
        case 'ALL_CHALLENGING_SOLUTIONS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ALL_CHALLENGING_SOLUTIONS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const challengingSolutionsRed = (state={data:[]},action) => {
    switch(action.type){
        case 'CHALLENGING_SOLUTION_REQUEST':
            return {...state,loading:true}
        case 'CHALLENGING_SOLUTION_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'CHALLENGING_SOLUTION_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}


export const challengingSolutionsLikesRed = (state={data:[]},action) => {
    switch(action.type){
        case 'CHALLENGING_SOLUTION_LIKES_REQUEST':
            return {...state,loading:true};
        case 'CHALLENGING_SOLUTION_LIKES_SUCCESS':
           return {...state,data:action.payload,loading:false}
         // return { ...state, data: state.data.map((post) => (post._id === action.payload._id ? action.payload : post)) };
        case 'CHALLENGING_SOLUTION_LIKES_FAIL':
           return {...state,loading:false};
        default:
            return state
    }
}



export const getByIdchallengingLikesRed = (state={data:[]},action) => {
    switch(action.type){
        case 'ALL_DATA_CHALLENGING_LIKES_REQUEST':
            return {...state,loading:true};
        case 'ALL_DATA_CHALLENGING_LIKES_SUCCESS':
           return {...state,data:action.payload,loading:false}
          
        case 'ALL_DATA_CHALLENGING_LIKES_FAIL':
           return {...state,loading:false};
        default:
            return state
    }
}