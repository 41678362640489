import React, {useEffect, useState } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../components/Loading'
import Pagination from '../components/Pagination'
import { challengingProblemsQuestionsAction , challengingProblemsSolution } from '../redux/actions/Challenging-Problems/ChallengingProblemsActions'
import ChallengingSolution from './ChallengingSolution'
//import ReviseTab from './ReviseTab';
import { CloseIcons } from '../icons/Icon';
import Navbar from '../components/Navbar'


const ChallengingProblems = () => {
  const questionData = useSelector(state=>state.challengingProblemsRed);
  const [likes,setLikes] = useState([]);
  const [reviseTab,setReviseTab] = useState();
  const [page2,setPage2] = useState(1);
  const [id,setId] = useState(null) ;
  
  const [revise,setRevise] = useState(false);
  const dispatch = useDispatch();
  const revisedConcept=(solutionId,id)=>{
    
    if(!revise){
       dispatch(challengingProblemsSolution(solutionId));
       setRevise(true);
    }
     setId(id);
  }

  useEffect(()=>{
    
      dispatch(challengingProblemsQuestionsAction(page2));
    
    
  },[page2,dispatch]);
  
 
  return (
    <div className='relative' >
    <div className='fixed w-full top-0 z-50' ><Navbar  /></div>
     
     <main className=' mt-[120px]  flex justify-center items-center text-gray-900'>
     
     <div className='ml-1 w-[1050px]'>
   {
   
    questionData?.loading?<Loading/>:
  questionData?.data?.results?.map((x,i)=>(
    <div className='definition relative' key={x._id} >
    <div className='flex'>
                  <div>{x.Qno}.</div>
          <div>
             <div className='defini ml-8 pb-4' style={{color:"#005DFF",fontSize:'1.2rem'}}> {x.question}</div>
           {x.questionLatex && <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px] ' style={{color:"#005DFF"}}><InlineMath displayMode={true} options={{strict: false}} math={x.questionLatex} errorColor={'#cc0000'} /></div> }  
           </div>
              </div>
              <div><img src={x.image} width='300px' alt="" /></div>
              {(x?._id===questionData?.data?.results?.find(p=>p?._id === id)?._id) && revise  && <div><ChallengingSolution setReviseTab={setReviseTab} reviseTab={reviseTab} setRevise = { setRevise } question = {x} page2={page2}  id={id} setLikes={setLikes} likes={likes} /></div> }
          
                 <div className='absolute right-1 bottom-1 cursor-pointer  py-2 px-3 ss:px-4 rounded flex'>
                 {reviseTab && (x?._id===questionData?.data?.results?.find(p => p?._id === id)?._id)?<div className='button--loading2 py-[12px]' ></div>:
                  (<div>{(x?._id===questionData?.data?.results?.find(p=>p?._id === id)?._id)&&revise ?
                    <div className='cursor-pointer hover:bg-deep-blue p-1 hover:text-white rounded-full duration-200' onClick={()=>setRevise(false)} ><CloseIcons/></div>:
                    (
                      <div>
                        {!revise && <div className='text-green-600' onClick={()=>revisedConcept(x.solutionId,x._id)} >
                      Solution
                   </div>}
                      </div>
                    )
                  }
                    
                  </div>  )
                   }
                 </div>
              </div>
             
          ))}
         {!revise &&
            <div className={`mb-3 mt-[18px] ${'fixed top-[75px]  right-2 items-center left-25 flex justify-center'} z-40`}>
          <Pagination setPage2={setPage2} page2={page2} selectedPage={'examples'} exampletData={questionData?.data}  />
         </div>
         }
     </div>
   
</main>
    </div>
  )
}

export default ChallengingProblems
