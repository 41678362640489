import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Tab1 from './tabs/Tab1';
import Tab2 from './tabs/Tab2';
import TabToggle from './TabToggle';
import Tab3 from './tabs/Tab3';
import Tab4 from './tabs/Tab4';

const concepts = 'Users';
const examples = 'Contact-Us-Messages';
const exercises = 'Logged-In-Users';
const codingGuru = "Users-For-Coding-Guru";

const AdminCatagory = ({ path }) => {
    const [selectedPage,setSelectedPage] = useState('concepts')
    const navigate = useNavigate();
    const pageSelected1=()=>{
     setSelectedPage("concepts");
     navigate('?&activeTab=sign-in-users',{replace:true}); 
  }
  const pageSelected2=()=>{
    setSelectedPage("examples");
    navigate('?&activeTab=contact-us-messages',{replace:true});
  }
  const pageSelected3 = () => {
    setSelectedPage("exercises");
    navigate('?&activeTab=logged-in-users',{replace:true});
  }
  
  const pageSelected4 = () => {
    setSelectedPage("codingGuru");
    navigate('?&activeTab=logged-in-users',{replace:true});
  }
  

  return (
    <div className='bg-white'>
      <TabToggle pageSelected1 = { pageSelected1 } pageSelected2 = { pageSelected2 } pageSelected3={ pageSelected3 } pageSelected4={pageSelected4} concepts = { concepts } examples = {examples} selectedPage={selectedPage} exercises={exercises} codingGuru = {codingGuru} />
    <div>
  
       <div>
       {
         selectedPage==='concepts'? <div><Tab1 selectedPage={selectedPage} /></div>:selectedPage==='examples'?<div><Tab2 selectedPage={selectedPage} path = {path} /></div>:selectedPage==='exercises'?<div><Tab3 selectedPage={selectedPage} path = {path} /></div>:<div><Tab4 selectedPage={selectedPage} path = {path} /></div>
       }
     </div>
    </div>
   
    
    </div>
  )
}

export default AdminCatagory


