export const physicsChapterName=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'PHYSICS_CHAPTER_NAME_REQUEST':
           return {...state,loading:true}
        case 'PHYSICS_CHAPTER_NAME_SUCCESS':
            return {...state,data:action.payload,loading:false}
         case 'PHYSICS_CHAPTER_NAME_FAIL':
            return {...state,loading:false}
        // test series
        case 'TEST_SERIES_CHAPTER_NAME_REQUEST':
           return {...state,loading:true}
        case 'TEST_SERIES_CHAPTER_NAME_SUCCESS':
            return {...state,data:action.payload,loading:false}
         case 'TEST_SERIES_CHAPTER_NAME_FAIL':
            return {...state,loading:false}
        default:
            return state
    }
}