import React, { useEffect, useMemo, useState } from 'react'
import { getTimeDifference } from '../../../lib/Utils'
import { FavoriteIcons } from '../../../icons/Icon'
import { AddLikeOnCommentsToSahilAskAction, RemoveLikeOnCommentsToSahilAskAction } from '../../../redux/actions/Ask-to-sahil/AskToSahilAction';
import { useDispatch } from 'react-redux';

const NestedLikesTime = ({nestedPost,id,setOuterCommentId,setComment ,comment,setShowNestedComment,setIndex,i}) => {
   
   //const { data,loading} = useSelector(state=>state.getAllAskToSahilRed);
   const [likes,setLikes] = useState(nestedPost?.likesComment);
   const user = JSON.parse(localStorage.getItem('userInfo'));
   const hasLikedPost =  useMemo(()=>likes?.find((like) => like === user.id),[likes,user.id]);
   const dispatch = useDispatch();
   useEffect(()=>{
    setLikes(nestedPost?.likesComment)
  },[nestedPost?.likesComment])
    const handleNestedLike = (commentId)=>{
     
      if (!hasLikedPost ) {
        dispatch(AddLikeOnCommentsToSahilAskAction(id ,commentId));
        setLikes((prev)=>{
          return [...prev,user.id]
        })
      } 
      else{
        dispatch(RemoveLikeOnCommentsToSahilAskAction(id ,commentId));
        setLikes(likes?.filter((id) => id !== user.id));
      }
    }
    
    const replyHandle = ( id ) => {
      setOuterCommentId(id);
      if(!nestedPost?.postedBy?.name) {
        setComment({...comment,text:"No Name"})
      } else {
        setComment({...comment,text:nestedPost.postedBy.name===process.env.REACT_APP_ADMIN_SECUIRITY?"Sahil bhayya":nestedPost.postedBy.name});
      }
      
      setShowNestedComment(false);
      setIndex(i)
    }
    const Likes = () => {
        if (likes?.length > 0) {
          return likes?.find((like) => like === (user.id))
            ? (
              <div className='text-error'><FavoriteIcons color='primary' />&nbsp;<span className='text-gray-500'>{likes?.length}</span></div>
            ) :(
              <div className='text-error'><FavoriteIcons color='primary' />&nbsp;<span className='text-gray-500'>{likes?.length}</span></div>
            )
        }
      
      };
  return (
    <div>
       <div className="flex gap-3 font-sans font-bold"  >
                  <p className='text-gray-500 ml-1 cursor-pointer'>{getTimeDifference(nestedPost?.createdAt)}</p>
                  <p className={`${likes?.length>0 && likes?.find((like) => like === (user.id)) ? "text-blue":"text-gray-500"} cursor-pointer`} onClick={()=>handleNestedLike(nestedPost._id)} >Like</p>
                  <p className='text-gray-500 cursor-pointer' onClick={()=>replyHandle(nestedPost._id)} >Reply</p> 
                  {/* <div className="text-error"><FavoriteIcons color='primary' /></div> */}
                  <div><Likes/></div>
                </div>
    </div>
  )
}

export default NestedLikesTime
