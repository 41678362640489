export const allChaptersConceptsRed = (state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'ALL_CHAPTERS_CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'ALL_CHAPTERS_CONCEPT_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ALL_CHAPTERS_CONCEPT_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const allChaptersExamplesRed = (state={data:[]},action)=>{
    switch(action.type){
        case 'ALL_CHAPTERS_EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'ALL_CHAPTERS_EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ALL_CHAPTERS_EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}


export const reviseConceptsOfAllChaptersRed = (state={data:[],loading:true},action) => {
    switch(action.type){
        case 'ALL_CHAPTERS_REVISE_REQUEST':
            return {...state,loading:true}
        case 'ALL_CHAPTERS_REVISE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ALL_CHAPTERS_REVISE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//more

export const moreQueAllChaptersRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_ALL_CHAPTERS_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_ALL_CHAPTERS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_ALL_CHAPTERS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsAllChaptersRed = (state={data:[],loading:true},action) => {
    switch(action.type){
        case 'MORE_ANS_ALL_CHAPTERS_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_ALL_CHAPTERS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_ALL_CHAPTERS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}