import  axios  from "axios";
let url = 'https://whiteboardblackpen-tge6.onrender.com/function';
//let url = `${process.env.REACT_APP_BACKEND_DOMAIN}/function`


const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });

export const conceptOfFunction=(page)=>async(dispatch)=>{
    dispatch({type:"FUNCTION_CONCEPT_REQUEST"});
    try {
     const {data} = await API.get(`/concept?page=${page}&limit=${2}`);
    
     dispatch({type:"FUNCTION_CONCEPT_SUCCESS",payload:data})
    } catch (error) {
   
      dispatch({type:"FUNCTION_CONCEPT_FAIL"});
    }
  }
  
  // examples
  
  export const exampleOfFunction=(page2)=>async(dispatch)=>{
    dispatch({type:"FUNCTION_EXAMPLE_REQUEST"});
    try {
     const {data} = await API.get(`/example?page=${page2}&limit=${2}`);
     dispatch({type:"FUNCTION_EXAMPLE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"FUNCTION_EXAMPLE_FAIL"});
    }
  }


  export const reviseConceptOfFunction=(id)=>async(dispatch)=>{
    dispatch({type:"FUNCTION_REVISE_REQUEST"});
   
    try {
     const {data} = await API.post(`/revise`,{ id });
     dispatch({type:"FUNCTION_REVISE_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"FUNCTION_REVISE_FAIL"});
    }
  }

//more
  export const moreQuestionOfFunctionAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_QUE_FUNCTION_REQUEST"});
   
    try {
     const {data} = await API.get(`/more/question?page=${page}&limit=${12}`);
     dispatch({type:"MORE_QUE_FUNCTION_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_QUE_FUNCTION_FAIL"});
    }
  }
  
  export const moreAnswerOfFunctionAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_ANS_FUNCTION_REQUEST"});
   
    try {
     const {data} = await API.get(`/more/answer?page=${page}&limit=${12}`);
    
     dispatch({type:"MORE_ANS_FUNCTION_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_ANS_FUNCTION_FAIL"});
    }
  }