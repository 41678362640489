import React from 'react';
import SocialMediaIcons from '../tools/SocialMediaIcon';

export const FooterForLanding = () => {
  return (
    <div className='relative'>
       <div className='bg-img w-full relative py-2 h-72 '>
        <p className='text-1xl ss:text-2xl mt-3 text-center font-serif tracking-wider' >©whiteBoardBlackPen</p>
        <div className='text-center tracking-wider text-1xl ss:text-1xl text-footer-light absolute bottom-2 w-full text-[11px] xs:text-[16px]' >Copyright © 2023 whiteBoardBlackPen designed by Sahil</div>
     <div className='absolute bottom-12 w-full  flex justify-center mb-4 xs:mb-0 right-0  mr-2' > <SocialMediaIcons /></div>
       <div className='absolute gap-2 bottom-14 xs:bottom:12 text-center w-full duration-300' >
         <a href='#learn' className='' >Learn</a> 
         <a href='/about-us' className='mx-12' >About us</a>
         <a href='#contact' >Contact</a>
       </div>
       
     </div>
    </div>
  )
}

export const Footer = ()=>{
  return (
    <div className='relative'>
       <div className='bg-blue w-full relative py-8'>
        <div className='text-center text-footer-light text-[11px] xs:text-[16px] tracking-widest' >Copyright © 2023 whiteBoardBlackPen designed by Sahil</div>
       
       
     </div>
    </div>
  )
}

export const SignSignupNavbar = ()=>{
  return (
    <div className='relative'>
       <div className='bg-blue w-full relative py-3'>
        <div className='text-center text-footer-light tracking-widest' >whiteBoardBlackPen</div>
       
       
     </div>
    </div>
  )
}
