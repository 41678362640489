import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DeleteIcons } from '../../icons/Icon';
import { isAdminAllUsrsActions, isAdminDeleteUsrsActions } from '../../redux/actions/Auth/IsAdminAction';
import Loading from '../../components/Loading';
import { getTimeDifference } from '../../lib/Utils';

const Tab1 = () => {
  const { data,loading } = useSelector(state=>state.isAdminRed);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [id,setId] = useState(null)
  const dispatch = useDispatch();
  const history = useNavigate();
  
  useEffect(() => {
    
      dispatch(isAdminAllUsrsActions())
    
  }, [dispatch]);
  useEffect(()=>{
    if(!userInfo){
       history('/',{replace:true})
    }
    
  },[history,userInfo])
  

  return (
   <div>
     <div className='text-center py-3 text-4xl font-bold ' >Admin Dashboard</div>
      {
     loading ?<Loading/>: data?.data?.map(user=>(
        <div key={user._id} >
       
           <div className='relative max-w-3xl border-1 mx-auto text-center py-6 mt-4 flex items-center justify-center border-black' >
           <button  className='absolute bottom-0 right-0 duration-300 rounded p-1 text-error hover:bg-gray2' onClick={()=>{
            dispatch(isAdminDeleteUsrsActions(user._id))
            setId(user._id)
           }} ><DeleteIcons /></button>
               <div>
               <div className='flex' >
                   <label htmlFor="name" className='px-2 pb-1'>Name:</label>
                   <div >{process.env.REACT_APP_ADMIN_SECUIRITY===user.name?"Sahil Bhayya":user.name}</div>

               </div>
             
               <div className='flex ' >
                  <label htmlFor="name" className='px-2 '>Phone number:</label>
                  <div className='text-[6px] sm:text-[16px] flex justify-center items-center' >{user.phoneNumber}</div>
                </div>
                <div className='flex ' >
                  <label htmlFor="name" className='px-2 '>Last Login:</label>
                  <div className='flex justify-center items-center' >{user?.lastLogin&&getTimeDifference(user?.lastLogin)}</div>
                </div>
                <div className='flex ' >
                  <label htmlFor="name" className='px-2 '>Last Logout:</label>
                  <div className='flex justify-center items-center' >{user?.lastLogOut&&getTimeDifference(user?.lastLogOut)}</div>
                </div>
                <div className='flex ' >
                  <label htmlFor="name" className='px-2 '>Account Created At:</label>
                  <div className='flex justify-center items-center' >{getTimeDifference(user.created_at)}</div>
                </div>
               </div>
      </div>
        </div>
       ))
      }
     
    </div>
  )
}

export default Tab1
