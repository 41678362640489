import React from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'
import { signout } from '../../../redux/actions/Auth/AuthAction';
import mylogo from '../../../youtube.png'


const Tab1 = ({setPage,page, selectedPage}) => {
  const {data,loading} = useSelector(state=>state.conceptsOfNewton);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const Logout=()=>{
    dispatch(signout(userData?.phoneNumber,navigate));
 }
  if(data.message==='jwt expired'){
       
    return (
      <div className='flex items-center justify-center min-h-screen'>
      <div className='relative  mx-auto my-auto bg-black text-white p-2 max-w-[500px]'>Your Session has expired <span className=' cursor-pointer text-blue' onClick={Logout}  >Click Me</span> to Log In again</div>
      </div>
    )
}
  return (
    <div>
        <main className='mt-[150px] flex justify-center items-center text-gray-900'>
  
  <div className='ml-1 w-[1050px]'>
  {
   loading?<Loading/>:
   data?.results?.map(x=>(
                
            <div key={x._id} className='definition rounded-md' >
            <div className='flex items-center'>
                <div className='text-[19px] mr-2 '>{x.conceptNo}.</div>
                <h1 className='text-2xl flex items-center' style={{color:"#005DFF"}}> {x.heading}</h1>
            </div>
             <div>{x.definition}</div>
             <div>{x.image&&<img src={x.image} alt="nothing" width='200px' />}</div>
             <div className='defini'>{x.concept1}</div>
             <div><InlineMath displayMode={true} options={{strict: false}} math={x.concept} errorColor={'#cc0000'} /></div>
            {x.image2 &&<div><img src={x.image2} alt="" width='200px' /></div> } 
             <div>{x.concept2}</div>
             <div><InlineMath displayMode={true} options={{strict: false}} math={x.example} errorColor={'#cc0000'} /></div>
             <div>{x.concept3}</div>
             <div><InlineMath displayMode={true} options={{strict: false}} math={x.example2} errorColor={'#cc0000'} /></div>
             <div>{x.divexample}</div>
             {x.videoLink && 
                     <div className='mt-10 bg-lime-300 p-2 w-full flex rounded-md' >
                     <a href={`${x.videoLink}`} className='flex w-full' > <img src={`${mylogo}`}  alt="" width='30px' /><p className='w-full text-center'>Click me for Video Lecture </p></a>
                   </div>
                  }
          </div>
              )) }
        </div>
   </main>
       <div className='my-3'>
         <Pagination selectedPage={selectedPage} setPage={setPage} page={page} conceptData={data} />
         </div>
    </div>
  )
}

export default Tab1



// {/* <div className='definition rounded-md' key={x._id} >
//                     <div className='flex items-center'>
//                     <div className='text-[19px] mr-2 '>{x.conceptNo}.</div>
//                      <h1 className='text-2xl flex items-center' style={{color:"#005DFF"}}> {x.heading}</h1>
//                      </div>
                   
//                     <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]' style={{margin:"10px 0"}} > {x.definition1}</div>
//                     <div className='flex'>
                     
//                        <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >  <InlineMath math={x.concept} /></div>
//                        <div>{x.image&&<img src={x.image} alt="nothing" width='200px' />}</div>
//                      </div>
//                      <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px] mb-2' > {x.definition2}</div>
//                       <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >  <InlineMath math={x.examples} /></div>
                      
//                 </div> */}