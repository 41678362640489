import React from 'react'
import { motion } from "framer-motion";

  
const Projects = () => {
  return (
    <section id="projects" className="pt-20 mb-4">
    {/* HEADINGS */}
    <motion.div
      className="md:w-2/5 mx-auto text-center"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.5 }}
      variants={{
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0 },
      }}
    >
      <div>
        <p className="font-playfair font-semibold text-4xl mb-3">
          <span className="z-50 orange_gradient">OUR</span> <span className='text-blue' >MISSION</span> 
        </p>
       
      </div>
      <p className=" mb-10 text-center font-serif ">
       My goal is just to make you love with <span className='text-blue font-bold'>Physics</span>  problems.
       and try to decrease your stress exponentially.
      </p>
    </motion.div>

    {/* PROJECTS */}
    <div className="flex justify-center">
    
      <motion.div
        className="sm:grid sm:grid-cols-3"
        //variants={container}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
      >
        {/* ROW 1 */}
        <div
          className="flex justify-center text-center items-center p-10 bg-img
            w-full max-h-[400px] text-2xl font-playfair font-semibold text-footer-light"
        >
          BEAUTIFUL THOUGHTS
        </div>
       
       
        <div
          className="flex justify-center text-center items-center p-10 bg-img
            w-full max-h-[400px] text-2xl font-playfair font-semibold text-footer-light"
        >
          TEACHING THE GOOD CONCEPTS IN EASE
        </div>
        <div
          className="flex justify-center text-center items-center p-10 bg-img
            w-full max-h-[400px] text-2xl font-playfair font-semibold text-footer-light"
        >
          TO MAKE YOU LOVE WITH PHYSICS
        </div>
        <div
          className="flex justify-center text-center items-center p-10 bg-img
            w-full max-h-[400px] text-2xl font-playfair font-semibold text-footer-light"
        >
          THE TECHNINIQUES OF SOLVING PROBLEMS
        </div>
        <div
          className="flex justify-center text-center items-center p-10 bg-img
            w-full max-h-[400px] text-2xl font-playfair font-semibold text-footer-light"
        >
          PREPARE GOOD SHEETS OF CONCEPTS AND PROBLEMS
        </div>
        <div
          className="flex justify-center text-center items-center p-10 bg-img
            w-full max-h-[400px] text-2xl font-playfair font-semibold text-footer-light"
        >
         THIS IS MY RESPONSIBILITY TO MAKE YOU UNDERSTAND EACH CONCEPT AND PROBLEM
        </div>
        <div
          className="flex justify-center text-center items-center p-10 bg-img
            w-full max-h-[400px] text-2xl font-playfair font-semibold text-footer-light"
        >
          TEACHING THAT HOW TO START TO SOLVE A GOOD PROBLEM
        </div>
        <div
          className="flex justify-center text-center items-center p-10 bg-img
            w-full max-h-[400px] text-2xl font-playfair font-semibold text-footer-light"
        >
          I KNOW YOU
        </div>
        <div
          className="flex justify-center text-center items-center p-10 bg-img
            w-full max-h-[400px] text-2xl font-playfair font-semibold text-footer-light"
        >
          ENJOY THE LIFE
        </div>
        </motion.div>  
    </div>
  </section>
  )
}

export default Projects