import { FaInstagram,FaFacebookF,FaLinkedinIn ,FaTwitter,FaYoutube } from "react-icons/fa";
const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-10 gap-7">
     <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.youtube.com/watch?v=G5_4pbLDY50"
        target="_blank"
        rel="noreferrer"
      >
        {/* <img alt="linkedin-link" src="../assets/linkedin.png" /> */}
        <FaYoutube/>
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/in/md-sahil-raza-abb699202"
        target="_blank"
        rel="noreferrer"
      >
       
        <FaLinkedinIn/>
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://twitter.com/Sahil01608772"
        target="_blank"
        rel="noreferrer"
      >
        {/* <img alt="twitter-link" src="../assets/twitter.png" /> */}
        <FaTwitter/>
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.facebook.com"
        target="_blank"
        rel="noreferrer"
      >
       
        <FaFacebookF />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.instagram.com/raz4762"
        target="_blank"
        rel="noreferrer"
      >
        {/* <img alt="instagram-link" src="../assets/instagram.png" /> */}
        <FaInstagram />
      </a>
    </div>
  );
};

export default SocialMediaIcons;