import React from 'react'
import { InlineMath } from 'react-katex'
import { useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'

const Tab1 = ({setPage,page,selectedPage,path}) => {
  const {data,loading} = useSelector(state=>path==='tangent-normal'?state.conceptsOfTangentRed:state.conceptsOfMaximaRed);
 
 
  return (
    <div>
        <main className='mt-[150px] flex justify-center items-center text-gray-900'>

  <div className='ml-1 w-[1050px]'>
  {
   loading?<Loading/>:
   (
    path==='tangent-normal'?(
      data?.results?.map(x=>(
                <div className='definition rounded-md' key={x._id} >
                  <div className='flex items-center'>
                    <div className='text-[19px] mr-2 '>{x.conceptNo}.</div>
                     <h1 className='text-2xl flex items-center' style={{color:"#005DFF"}}> {x.heading}</h1>
                     </div>
                   
                    <div style={{fontSize:'1.05rem',margin:"10px 0"}} > {x.definition1}</div>
                    <div className='flex'>
                  
                       <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>  <InlineMath math={x.concept} /></div>
                       <div>{x.image&&<img  src={x.image} alt="nothing" />}</div>
                     </div>
                     <div className='text-[18px] mb-2' > {x.definition2}</div>
                      <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>  <InlineMath math={x.examples} /></div>
                      
                </div>
    ))
  
              ):(
                data?.results?.map(x=>(
                <div className='definition rounded-md' key={x._id} >
                  <div className='flex items-center'>
                    <div className='text-[19px] mr-2 '>{x.conceptNo}.</div>
                     <h1 className='text-2xl flex items-center' style={{color:"#005DFF"}}> {x.heading}</h1>
                     </div>
                   {
                    x.definition1 &&<div style={{fontSize:'1.05rem',margin:"10px 0"}} > {x.definition1}</div>
                   }
                    
                    <div className=''>
                     
                       <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>  <InlineMath math={x.concept} /></div>
                       <div>{x.image&&<img className='w-[350px] sm:w-[390px]' src={x.image} alt="nothing"  />}</div>
                     </div>

                {x.concept1 &&<div>
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'><InlineMath options={{strict:false}} math={x.concept1}/></div>
                  <div><img src={x.image1} alt="" width='300px' /></div>
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' ><InlineMath options={{strict:false}} math={x.concept2}/></div>
                   <div><img src={x.image2} alt="" className='width-[190px] sm:width-[200px]' /></div>
              
                </div> }
                <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >  <InlineMath math={x.examples} /></div>
                 
                    <div style={{fontSize:'1.2rem',display:"flex",alignItems:"flex-start",marginTop:"10px"}} > {x.definition2}</div>
                   <div><img src={x.image3} alt="" width='300px' /></div>
                      
                </div>
    ))
              )) }
        </div>
   </main>
       <div className='my-3'>
         <Pagination selectedPage={selectedPage} setPage={setPage} page={page} conceptData={data} />
         </div>
    </div>
  )
}

export default Tab1
