export const getAllFilesRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'DOWNLOAD_ALLDATA_REQUEST':
            return {...state,loading:true}
        case 'DOWNLOAD_ALLDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'DOWNLOAD_ALLDATA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const uploadFileRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'POST_REQUEST':
            return {...state,loading:true}
        case 'POST_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'POST_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

// upload profile

export const uploadProfileRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'PROFILE_UPLOAD_REQUEST':
            return {...state,loading:true}
        case 'PROFILE_UPLOAD_SUCCESS':
           return {...state,data:action.payload,loading:false,error:null}
        case 'PROFILE_UPLOAD_FAIL':
           return {...state,loading:false,error:action.payload}
        default:
            return state
    }
}

export const userProfileRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'GET_PROFILE_REQUEST':
            return {...state,loading:true}
        case 'GET_PROFILE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'GET_PROFILE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const deleteProfileRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'DELETE_PROFILE_REQUEST':
            return {...state,loading:true}
        case 'DELETE_PROFILE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'DELETE_PROFILE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

