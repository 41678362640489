import React from 'react'
import { SignSignupNavbar } from '../../home/uiDesign/scenes/Footer'

const AboutCreator = () => {
  return (
    <div>
     <SignSignupNavbar />
      <h2 className='text-7xl  text-center font-bold mt-4' >KICK</h2>
      <div >
         <p className='max-w-[500px] mx-auto text-[18px] font-serif mt-4 ml-3 mr-3' >Hlo there, I'm Sahil, I have more than 5 years of experience to teach physics
         and maths to make you the complete competitor among excellence minds. <br/>
         I have created this beautiful web application for content of physics(especially)
         and maths, where you see how is made very easy of very advance concepts. there is a 
         my youtube channel <span className='text-blue' >whiteBoardBlackPen</span> , In this youtube channel mainly for physics
         , i'll cover all the concepts from basic to advance. </p>
      </div>
    </div>
  )
}

export default AboutCreator
