import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllCodingGuruUsersActions } from '../../redux/actions/Auth/IsAdminAction';
import Loading from '../../components/Loading';
import { DateConverting } from '../../lib/Utils';

const Tab4 = () => {
    const { data,loading } = useSelector(state=>state.getAllCosingGuruUsersRed);
    const dispatch = useDispatch();
   
    useEffect(()=>{
      dispatch(getAllCodingGuruUsersActions())
    },[])
   
  return (
    <div>
        { loading ? <Loading/>:
         data?.allMessages?.map(x=>(
            <div key={x._id} className='border-1 m-3 text-start  py-6 mt-4 items-center justify-center  border-black' >
                <p><span className='text-2xl'>First name : </span>{x.firstName}</p>
                <p><span className='text-2xl'>Last name : </span>{x.lastName}</p>
                <p><span className='text-2xl'>Age : </span>{x.age}</p>
                <p><span className='text-2xl'>Gender : </span>{x.gender}</p>
                <p><span className='text-2xl'>Education : </span>{x.classes}</p>
                <p><span className='text-2xl'>Email : </span>{x.email}</p>
                <p><span className='text-2xl'>Phone number : </span>{x.phoneNumber}</p>
                <p><span className='text-2xl'>Address : </span>{x.address}</p>
                <p><span className='text-2xl'>CreatedAt : </span>{DateConverting(x.createdAt)}</p>
               
            </div>
        ))
      }
    </div>
  )
}

export default Tab4

