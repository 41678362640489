
export const multiTestPhysicsRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MULTI_TEST1_REQUEST':
            return {...state,loading:true}
        case 'MULTI_TEST1_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MULTI_TEST1_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const multiTestResponseRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'MULTI_TEST1_RESPONSE_REQUEST':
            return {...state,loading:true}
        case 'MULTI_TEST1_RESPONSE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MULTI_TEST1_RESPONSE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//ALLDATA for mains

export const multiTestResponseAllDataRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'MULTI_TEST1_RESPONSE_ALLDATA_REQUEST':
            return {...state,loading:true}
        case 'MULTI_TEST1_RESPONSE_ALLDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MULTI_TEST1_RESPONSE_ALLDATA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

// sidebar


export const multiTestphysicsSidebarRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'MULTI_TEST1_SIDEBAR_REQUEST':
            return {...state,loading:true}
        case 'MULTI_TEST1_SIDEBAR_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MULTI_TEST1_SIDEBAR_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}