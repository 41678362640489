import React from 'react'
import { InlineMath } from 'react-katex'
import { useSelector } from 'react-redux'
import Loading from '../../../../components/Loading'
import SawalSidebar from '../../../../components/SawalSidebar'

const Screnns = ({pathName}) => {
    const {data,loading} = useSelector(state=>state.moreQueIndifinite);
    const moreDataOfDefinite = useSelector(state=>state.moreQueDedifiniteRed);
   
    if(pathName==='indefinite-integrals'){
       if(loading){
      return <Loading/>
    }
    }else{
      if(moreDataOfDefinite.loading){
        return <Loading/>
    }}
   
  return (
    <div>
      <section className='md:ml-[400px] mb-[40px] mt-[100px] '>
        <div >
          {
           
            (pathName==='indefinite-integrals'?data?.results:moreDataOfDefinite?.data?.results)?.map(x=>(
             
              <div key={x.uuid} className='overflow-hidden'>
              
              <div  className='definition flex gap-3 rounded-md'>
                 <div>{x.Qno}</div>
                 <div> <InlineMath math={x.question} /></div>
                 </div>
              </div>
            ))
          }
        </div>
     </section>
    </div>
  )
}

export default Screnns
