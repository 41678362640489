import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../components/Pagination';
import Loading from '../../../components/Loading';
import { moreQuestionOfNewton } from '../../../redux/actions/physicsActions/NLM/NewtonAction';
import PrashnaSideBar from './PrashnaSideBar';

const Tab3 = ({selectedPage,sidebarShow,setSidebarShow}) => {
  const { data,loading } = useSelector(state=>state.moreQueNewtonRed);
  const [page4,setPage4] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
   // console.log(page4);
    dispatch(moreQuestionOfNewton(page4));
   
  }, [page4,dispatch])
  return (
    <div className=''>
  
     
      {/* this sidebar for bigger screen */}
     <div className='md:block hidden z-50 h-full' ><PrashnaSideBar setPage={setPage4} selectedPage={selectedPage} page={page4}  /></div>
     
     {!sidebarShow&& <div className='z-50 h-full' ><PrashnaSideBar setSidebarShow={setSidebarShow} setPage={setPage4} selectedPage={selectedPage} page={page4}  /></div>}
    
     <section className='md:ml-[400px] mb-[40px] mt-[100px] '>
        <div className=' mt-3' >
          {
            loading?<Loading/>:
            data?.results?.map(x=>(
             
              <div key={x._id} className='overflow-hidden'>
              
              <div  className='definition  gap-3 rounded-md'>
                <div className='flex'>
                 <div>{x.Qno}</div>
                 <div className='defini ml-2' style={{color:"#005DFF",fontSize:'1.2rem'}}> {x.Question}</div>
                 </div>
                 <div><img src={x.image} width='200px' alt="" /></div>
                 </div>
                 
              </div>
            ))
          }
        </div>
        <div className=' fixed bottom-2 z-40 right-4 mt-3'>
         <Pagination setPage4={setPage4} page4={page4}  selectedPage={selectedPage} conceptData={data} />
    </div>
     </section>
    </div>
  )
}

export default Tab3