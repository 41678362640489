import React from 'react';
import { motion } from "framer-motion";
import useMediaQuery from '../../../../components/hook/UseMediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const Previous = () => {
    const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <div>
       <div className={`md:flex ${!isAboveLarge&& 'flex flex-col-reverse'} max-w-[1100px] items-center mx-auto md:justify-between md:gap-4 md:mt-20`}>
       
        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
           
              
               <LazyLoadImage
              src="assets/pre.png"
              alt='image loading'
             
              placeholderSrc="assets/pre.png"
              threshold='100'
              className="  hover:filter hover:saturate-200 transition duration-500 w-full max-w-[400px] md:max-w-[400px] mr-3"
               
             />
           
          ) : (
            <div className=' flex justify-center'>
            <LazyLoadImage
              src="assets/pre.png"
              alt='image loading'
             
              placeholderSrc="assets/pre.png"
              threshold='100'
              className=" hover:filter hover:saturate-200 transition duration-500 w-full max-w-[400px] md:max-w-[550px]"
               
             />
          
            </div>
          )}
        </div>
        <motion.div
          className="md:w-1/3 "
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-serif font-bold text-5xl text-blue text-center mt-20 md:mt-2  ">
            PREVIOUS <p className="text-black">YEARS</p>
          </p>
        
          <p className="mt-3 mb-7 max-w-[250px] xs:max-w-[400px] mx-auto text-center font-serif">
           In this content and also on my YouTube channel <a className='text-blue' href="https://www.youtube.com/watch?v=G5_4pbLDY50">Click me </a>
           previous years quations are disscussed during and after expressing the detailted concepts.
          </p>
        </motion.div>
    </div>
    </div>
  )
}

export default Previous

