import React, { useState } from 'react'
import { SendIcons } from '../../icons/Icon'
import { useDispatch } from 'react-redux'
import { AddCommentsOnCommentsToSahilAskAction, CreateCommentsToSahilAskAction } from '../../redux/actions/Ask-to-sahil/AskToSahilAction';
import CommentsFlats from './CommentsFlats'

const CommentSection = ({ id }) => {
    const [localComment,setLocalComment] = useState([]);
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const [showNestedComment,setShowNestedComment] = useState(true);
    const [outerCommentId,setOuterCommentId] = useState(null);
    const [index,setIndex] = useState(null);
   const [comment,setComment] = useState({
    text:"",
    id:null
   });

   const dispatch = useDispatch()
    const handleSubmit = (e) => {
        e.preventDefault();
        if(showNestedComment){
          dispatch(CreateCommentsToSahilAskAction(comment));
          setLocalComment((prev)=>{
            return [{...comment,_id:Date.now(),postedBy:{name:user.name}},...prev]
        });
        }
        else{
          dispatch(AddCommentsOnCommentsToSahilAskAction({...comment,outerCommentId:outerCommentId}));
          setShowNestedComment(true);

          setLocalComment(prev => {
            
            
            const updatedComments = [...prev];
            
            if (index >= 0 && index < updatedComments.length) {
              updatedComments[index] = {
                  ...updatedComments[index], // Preserve existing comment properties
                  commentsOnComments: [
                      ...updatedComments[index].commentsOnComments,
                      {
                          _id: Date.now(), // Generate a unique ID for the new comment
                          text: comment.text, // Copy the text from the original comment
                          createdAt: new Date().toISOString(), // Set the current timestamp for the new comment
                          postedOnComment: {_id: Date.now(), name: user.name }, // Set the name of the user who posted the new comment
                          likes: [], // Initialize likes array for the new comment
                      }
                  ]
              };
          }
            return updatedComments;
        });
        }
       
       setComment({
        text:"",
        id:null
       });
    }
    const calculateRows = (content) => {
      // Count the number of newline characters in the content
      const newlineCount = (content.match(/\n/g) || []).length;
      // Set a minimum number of rows
      const minRows = 1;
      const maxRows = 4
      // Calculate the number of rows based on the number of newline characters
      return Math.min(newlineCount + minRows, maxRows);
  };
 
  return (
    <div >
       <div className='mb-20'><CommentsFlats id={ id } 
                                             localComment = { localComment } 
                                             setLocalComment = { setLocalComment } 
                                             setOuterCommentId={setOuterCommentId} 
                                             setComment={setComment} comment={comment} 
                                             setShowNestedComment={setShowNestedComment} 
                                             setIndex={setIndex}
                                             /></div>
       <form onSubmit={handleSubmit} className='flex justify-center items-center'>
     
     <div className='relative w-full flex '>
     <div className='bottom-2 fixed flex justify-center items-center w-full bg-white'> 
    <textarea
         type="text"
          className={`${comment.text.replace(/\n/g, '').trim()?"border-2 border-grey":"border-1 border-grey"} w-full px-1 py-2  mx-2 rounded-lg outline-none`}
          placeholder="Write a comment"
          onChange={(e)=>setComment({id:id,text:e.target.value})}
          value={comment.text}
          style={{ resize: "none" }}
          rows={calculateRows(comment.text)}
        />
   
   <button className={`${(comment.text.replace(/\n/g, '').trim() )?'bg-black bottom-[6px]':'bg-gray-300 bottom-[5px]'} text-white flex absolute right-3 px-2 py-1 rounded`} type='submit' disabled={comment.text.replace(/\n/g, '').trim()?false:true} >
     {/* <span>{loading&&<div className='button--loading py-[12px]'></div> }</span>  */}
     <SendIcons/>
    
     </button>
     </div>
     </div>
</form>
    </div>
  )
}

export default CommentSection
