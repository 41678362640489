import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import NavbarTest from '../NavbarTest';
import Physics1Test from './Physics1Test'
import TestSidebar1 from './TestSidebar1';

const Test = () => {
  const [navbarMenu,setNavbarMenu] = useState(true);
  const [page,setPage] = useState(1);
  return (
    <div>
   
     {/* <TestSidebar1 setPage={setPage} navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} />
      */}
      <div className='md:block hidden  z-50 h-full' ><TestSidebar1 page={page} setPage={setPage} /></div>
      {!navbarMenu&& <div className='z-50  h-full' ><TestSidebar1 page={page} setPage={setPage} navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} /></div>}
     
     <div className='absolute w-full z-50  top-0' >
      <NavbarTest navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} />
      </div>
      <div className='z-40' > <Physics1Test page={page} setPage={setPage} /></div>
     
    </div>
  )
}

export default Test
