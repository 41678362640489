export const conceptsOfNewton=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'NEWTON_CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'NEWTON_CONCEPT_SUCCESS':
            return {...state,data:action.payload,loading:false}
        case 'NEWTON_CONCEPT_FAIL':
             return {...state,loading:false}
        default:
            return state
    }
}

export const examplesOfNewton=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'NEWTON_EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'NEWTON_EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'NEWTON_EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}



//more

export const moreQueNewtonRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_NEWTON_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_NEWTON_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_NEWTON_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsNewtonRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_ANS_NEWTON_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_NEWTON_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_NEWTON_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}