import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({setPage,setPage2,conceptData,selectedPage,exampletData,page,page2,setPage3,page3,setPage4,page4,setPage5,page5}) => {
  
    const handlePageClick=(paginate)=>{
        if(selectedPage==="examples"){
           setPage2(paginate.selected+1);
          
        }else if(selectedPage==="concepts"){
          setPage(paginate.selected+1);
        }else if(selectedPage==="jee mains"){
           setPage3(paginate.selected+1);
          
        }
        else if(selectedPage==="jee advance"){
          setPage5(paginate.selected+1);
         }
        else if(selectedPage==="more problems" || selectedPage==="exercises"){
          setPage4(paginate.selected+1)
         }
       
       
      }
  
  return (
    <div>
    {isNaN(exampletData?.totalPages) && isNaN(conceptData?.totalPages) ?<div className='button--loading2 relative -top-5 right-5'></div>:
      <ReactPaginate
         previousLabel={'pre'}
         breakLabel={"..."}
         nextLabel={'next'}
         pageCount={selectedPage==="examples"? Math.ceil(Number(exampletData?.totalPages)): Math.ceil(Number(conceptData?.totalPages))}
         pageRangeDisplayed={1}
         onPageChange={handlePageClick}
         marginPagesDisplayed={0}
         //initialPage={selectedPage==="concepts"?page-1:selectedPage==="examples"?page2-1:selectedPage==="jee mains"?page3-1:selectedPage==="jee advance"?page5-1:page4-1}
         forcePage={selectedPage==="concepts"?page-1:selectedPage==="examples"?page2-1:selectedPage==="jee mains"?page3-1:selectedPage==="jee advance"?page5-1:page4-1}
         containerClassName={'pagination justify-content-center'}
         pageClassName={'page-item'}
         pageLinkClassName={'page-link'}
         breakLinkClassName={'page-link'}
         previousClassName={'page-item'}
         previousLinkClassName={'page-link'}
         nextClassName={'page-item'}
         nextLinkClassName={'page-link'}
         activeClassName={'active'}

      />
    }
    </div>
  )
}

export default Pagination
