import React, { useState, useEffect } from 'react';
import MockTestQuestions from './MockTestQuestions';
import { resultsMockTestAction
 // , timerMockTestAction 
} from '../redux/actions/Mock-Test/MockTestAction';
import { useDispatch, useSelector } from 'react-redux';
import Results from './Results/Results';
import { useRef } from 'react';
import { Buttons, VisibilityIcons } from '../icons/Icon';
import { formatTime } from '../lib/Utils';


const Timer = ({ finishedMockTestAfterCompleteTimer,setFinishedMockTestAfterCompleteTimer,page,setPage,path,user,selectedPage,setSelectedPage }) => {
 // const [remainingTime, setRemainingTime] = useState(0);
// const timerId = JSON.parse(localStorage.getItem(`timer_${user.id+path}`));
  const results = useSelector(state=>state.resultsMockTestRed);
 // const timer = useSelector(state=>state.timerMockTestRed);
  const {data,loading}  = useSelector(state=>state.getresultsMockTestRed);
  
  const dispatch=useDispatch();
  
  // useEffect(() => {
  //   const timers = setInterval(() => {
  //     dispatch(timerMockTestAction(setRemainingTime,path));
  
  // }, 1000);
  
  //   return () => clearInterval(timers);
  
  // }, [path,dispatch,timer]);
  const [seconds, setSeconds] = useState(3600);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds === 0) {
          clearInterval(interval);
         // stopTimerForUser(user.id+path)
          // Handle timer expiration here
          return 0;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  function stopTimerForUser(userId) {
    localStorage.removeItem(`timer_${userId}`);
  }
 // console.log(seconds,'seconds');
const resultOfMockTest=()=>{
   // dispatch(resultsMockTestAction(path,timer?.data?.elapsedTime>=3600?3600:timer?.data?.elapsedTime));
   dispatch(resultsMockTestAction(path,3600 - seconds));
    setFinishedMockTestAfterCompleteTimer(true);
    stopTimerForUser(user.id+path);
    //setSelectedPage('concepts')
}



//useEffect(()=>{
  
//   if(timer && (timer.data.time === 2 || timer.data.time === 1 )){
//    //  resultOfMockTest()
//     // console.log(requiredTime,'timerrrrrnnnnn');
  
//   }     
// },[dispatch,timer]);



// useEffect(()=>{
//    dispatch(getResultsMockTestAction(path));
   
// },[finishedMockTestAfterCompleteTimer,dispatch,data,path]);


const log = useRef(true);
useEffect(()=>{
  if(log.current){
    data.results = []
    log.current=false;
 }
 
},[]);
useEffect(()=>{
   setSeconds(getRemainingTimeForUser(user.id+path));
   
},[user.id,path])
function getRemainingTimeForUser(userId) {
  const timerData = JSON.parse(localStorage.getItem(`timer_${userId}`));
  
  if (timerData) {
    const { startTime, duration } = timerData;
    
    const elapsedTime = (new Date().getTime() - startTime)/1000;
    
    const remainingTime = (duration - elapsedTime);
   // console.log(Math.round(remainingTime),'llll',duration,elapsedTime);
    return Math.max(0, Math.round(remainingTime));
  }
  return 0;
}
// timer.data.elapsedTime>=3600

if(seconds <= 1){
  return (
    <div>
    
      {/* (data.results?.length!==0)?  <div><Results selectedPage={selectedPage} setSelectedPage={setSelectedPage} loading = {loading} finishedMockTestAfterCompleteTimer = { finishedMockTestAfterCompleteTimer } path = { path } user={user} /></div>:( */}
    <div className='min-h-screen flex bg-slate-100 justify-center items-center'>
      <div >
      <div className='bg-white shadow-md p-3 rounded m-3'>
        <p className='pb-3'>Sorry! your time is finished. Now it's time to show your performance.</p>
           
      <div className='flex relative items-center justify-center'>
      {/* <Button variant="contained" onClick={resultOfMockTest} color='success' endIcon=<VisibilityIcons/> >
     {results.loading? <div className='button--loading py-[12px]'> </div>:<div>View results</div> }
      </Button> */}
      <Buttons variant={"contained"} type = {"button"} onClick={resultOfMockTest} color='success' endIcon = {<VisibilityIcons />} data = {results} text = {'submit your test'} />

      </div>
    
    </div>
        </div>
        </div>
      {/* ) */}
    
    
    
    </div>
      
  )
}
  return (
    <div className='min-h-screen'>
    {
      (data.results?.length!==0)?  <div><Results selectedPage={selectedPage} setSelectedPage={setSelectedPage} loading = {loading} finishedMockTestAfterCompleteTimer = { finishedMockTestAfterCompleteTimer } path = { path } /></div>:(
        <div>
           <div className='flex justify-between mx-2 relative top-4 '>
      {/* <p className='text-1xl vvs:text-2xl'>Time Left: {formatTime(remainingTime-2)} hrs</p> */}
      <p className='text-1xl vvs:text-2xl'>Time Left: {formatTime(seconds)} hrs</p>
      
      {/* <Button variant="contained" onClick={resultOfMockTest} color="primary" >
     {results.loading? <div className='button--loading py-[12px]'> </div>:<div>Submit</div> }
      </Button> */}
      <Buttons variant={"contained"} type = {"button"} onClick={resultOfMockTest} color='primary' endIcon = {null} data = {results} text = {'submit'} />

    </div>
      <MockTestQuestions page = { page } setPage = {setPage} path = { path } />
   
        </div>
      )
    }
    
    
    
    </div>
  );
};

export default Timer