import React, { useEffect } from 'react';
import { InlineMath } from 'react-katex';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
import { getAnswerSheetsMockTestAllDataAction } from '../../../redux/actions/Mock-Test/MockTestAction';

const Tab2 = ({ path }) => {
  const {data,loading } = useSelector(state=>state.getAnswerSheetsMockTestAllDataRed);
  const dispatch  =  useDispatch();
  useEffect(()=>{
    dispatch(getAnswerSheetsMockTestAllDataAction(path))
  },[path,dispatch]);
  
  return (
    <div className='definition w-full ss:w-9/12 m-auto '>
      { loading?<Loading />:
        data?.gettingResponses?.map((x)=>(
          <div key={x._id} >
          <div className='flex mb-3' >
           <div className='relative top-3 ml-2' style={{userSelect:"none"}} >{`Q(${x.questionNo})`}.</div>
           </div>
           <div className='border rounded m-1 ss:m-5'>
            <div className='text-[16px] xs:text-[18px] m-2' style={{userSelect:"none"}} >{x.question}</div>
            {x.questionLatex && <div style={{userSelect:"none" ,scrollbarWidth: 'none', }} className='text-[11px] ml-1 vvs:text-[12px] xs:text-[14px] sm:text-[18px] overflow-x-auto overflow-y-hidden'><InlineMath displayMode={true} options={{strict: false}} math={x.questionLatex} errorColor={'#cc0000'} /></div> }
            
            {
            x.options.length!== 0 ?( x.options.map((opt,i)=>(
              <div key={i} className='relative ml-1' style={{userSelect:"none"}} >
              <div className='flex relative items-center cursor-pointer ' >
              <div className='ml-2 text-[19px] option ' >{`(${String.fromCharCode(i+97)})`}</div>
              <div className='p-2 text-[13px] ss:text-[16px]'> {opt}</div>
            
     
      </div>
        </div>
              
                  
 ))):(
   x.optionsLatex.map((opt,i)=>(
              <div key={i} className='relative ml-1' style={{userSelect:"none"}} >
              <div className='flex relative items-center cursor-pointer rounded' >
              <div className='ml-2 text-[19px] option ' >{`(${String.fromCharCode(i+97)})`}</div>
              <div className='p-2 text-[13px] ss:text-[16px] overflow-x-auto overflow-y-hidden'> <InlineMath displayMode={true} options={{strict: false}} math={opt} errorColor={'#cc0000'} /></div>
            
             </div>
      </div>
   )))
 
 }
    </div>
      <div>
      {
        x.response!==undefined? (
        <div>
           <p  style={{userSelect:"none",}} className={`${x.response===parseInt(x.correctAnswer)?'text-success':'text-error'} font-semibold ml-3`}>Your Answer:{String.fromCharCode(x.response+97)}</p>
        <p className='font-semibold text-grey ml-3' style={{userSelect:"none"}}>Correct Answer:{String.fromCharCode(parseInt(x.correctAnswer)+97)}</p>
        </div>
        ): (
          <div>
          <div className='font-semibold text-grey ml-3' style={{userSelect:"none"}}>Not Attempted</div>
          <p className='font-semibold text-grey ml-3'>Correct Answer : {String.fromCharCode(parseInt(x.correctanswer)+97)}</p>
          </div>
        )
      }
       
      </div>   
        </div>
         ))
       }
      
    </div>
  )
}

export default Tab2