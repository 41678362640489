import axios from "axios";

const url = 'https://whiteboardblackpen-tge6.onrender.com/definite';
//let url = 'http://3.110.197.80/api/definite'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/definite`

const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo'))?.token}`;
    }
  
    return req;
  });

export const conceptOfDefinite=(page)=>async(dispatch)=>{
    dispatch({type:"DEFINITE_CONCEPT_REQUEST"});
    try {
     const {data} = await API.get(`/concept?page=${page}&limit=${2}`);
     dispatch({type:"DEFINITE_CONCEPT_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"DEFINITE_CONCEPT_FAIL"});
    }
  }
  
  // exaples
  
  export const exampleOfDefinite=(page2)=>async(dispatch)=>{
    dispatch({type:"DEFINITE_EXAMPLE_REQUEST"});
    try {
     const {data} = await API.get(`/example?page=${page2}&limit=${2}`);
    
     dispatch({type:"DEFINITE_EXAMPLE_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"DEFINITE_EXAMPLE_FAIL"});
    }
  }

  export const reviseOfDefinite=(id)=>async(dispatch)=>{
    dispatch({type:"DEFINITE_REVISE_REQUEST"});
   
    try {
     const {data} = await API.post(`/revise`,{id});
    
     dispatch({type:"DEFINITE_REVISE_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"DEFINITE_REVISE_FAIL"});
    }
  }


  //mains questions
export const jeemainsOfDefinite=(page)=>async(dispatch)=>{
  dispatch({type:"JEEMAINS_DEFINITE_REQUEST"});
  try {
   const {data} = await API.get(`/mains?page=${page}&limit=${1}`);
 
   dispatch({type:"JEEMAINS_DEFINITE_SUCCESS",payload:data})
  } catch (error) {
   
    dispatch({type:"JEEMAINS_DEFINITE_FAIL"});
  }
}
  //sidebar mains
  export const sidebarDefiniteQuestion=(page)=>async(dispatch)=>{
    dispatch({type:"SIDEBAR_DEFINITE_MAIN_REQUEST"});
    try {
    
     const {data} = await API.get(`/mains/sidebar?page=${page}&limit=${5}`);
     dispatch({type:"SIDEBAR_DEFINITE_MAIN_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"SIDEBAR_DEFINITE_MAIN_FAIL"});
    }
  }

  

  //advance questions
export const advanceOfDefinite=(page)=>async(dispatch)=>{
  dispatch({type:"ADVANCE_DEFINITE_REQUEST"});
  try {
   const {data} = await API.get(`/advance?page=${page}&limit=${1}`);
 
   dispatch({type:"ADVANCE_DEFINITE_SUCCESS",payload:data})
  } catch (error) {
   
    dispatch({type:"ADVANCE_DEFINITE_FAIL"});
  }
}
  //sidebar advance definite
  export const advanceOfsidebarDefiniteQuestionAction=(page)=>async(dispatch)=>{
    dispatch({type:"SIDEBAR_DEFINITE_ADVANCE_REQUEST"});
    try {
   
     const {data} = await API.get(`/advance/sidebar?page=${page}&limit=${5}`);
      dispatch({type:"SIDEBAR_DEFINITE_ADVANCE_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"SIDEBAR_DEFINITE_ADVANCE_FAIL"});
    }
  }

  
  //more problems

 export const moreQuestionOfDefifiniteAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_QUE_DEFINITE_REQUEST"});
   
    try {
     const {data} = await API.get(`/more/question?page=${page}&limit=${12}`);
     dispatch({type:"MORE_QUE_DEFINITE_SUCCESS",payload:data})
    } catch (error) {
   
      dispatch({type:"MORE_QUE_DEFINITE_FAIL"});
    }
  }
  
  export const moreAnswerOfDedifiniteAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_ANS_DEFINITE_REQUEST"});
   
    try {
     const {data} = await API.get(`/more/answer?page=${page}&limit=${12}`);

     dispatch({type:"MORE_ANS_DEFINITE_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"MORE_ANS_DEFINITE_FAIL"});
    }
  }


//response for mains

  export const responseAnswerOfJeemainsDefinite=(response,id)=>async(dispatch)=>{
    dispatch({type:"RESPONSE_JEEMAINS_ANS_DIFINITE_REQUEST"});
   
    try {
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.post(`${url}/response/mains`,{response,id},{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
   
     dispatch({type:"RESPONSE_JEEMAINS_ANS_DIFINITE_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"RESPONSE_JEEMAINS_ANS_DIFINITE_FAIL"});
    }
  }
   // all data
  export const responseAnswerOfJeemainsDefiniteAllData=()=>async(dispatch)=>{
    dispatch({type:"RESPONSE_JEEMAINS_ANS_DIFINITE_ALLDATA_REQUEST"});
   
    try {
    
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/response/mains/alldata`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
     dispatch({type:"RESPONSE_JEEMAINS_ANS_DIFINITE_ALLDATA_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"RESPONSE_JEEMAINS_ANS_DIFINITE_ALLDATA_FAIL"});
    }
  }
  
  // ADVANCE RESPONCE
  export const responseAnswerOfAdvancedifinite=(response,id)=>async(dispatch)=>{
    dispatch({type:"RESPONSE_ADVANCE_ANS_DIFINITE_REQUEST"});
   
    try {
   
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.post(`${url}/response/advance`,{response,id},{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
    
     dispatch({type:"RESPONSE_ADVANCE_ANS_DIFINITE_SUCCESS",payload:data})
    } catch (error) {
      
      dispatch({type:"RESPONSE_ADVANCE_ANS_DIFINITE_FAIL"});
    }
  }
   // all data for advance
  export const responseAnswerOfAdvancedifiniteAllData=()=>async(dispatch)=>{
    dispatch({type:"RESPONSE_ADVANCE_ANS_DIFINITE_ALLDATA_REQUEST"});
   
    try {
    
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/response/advance/alldata`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
   
     dispatch({type:"RESPONSE_ADVANCE_ANS_DIFINITE_ALLDATA_SUCCESS",payload:data})
    } catch (error) {
      
      dispatch({type:"RESPONSE_ADVANCE_ANS_DIFINITE_ALLDATA_FAIL"});
    }
  }