import axios from 'axios';

const url = 'https://whiteboardblackpen-tge6.onrender.com/allchapters'
//const url = 'http://localhost:8000/allchapters';
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/allchapters`


const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });

export const allChaptersConceptAction=(page,level,chapter)=>async(dispatch)=>{
    dispatch({type:"ALL_CHAPTERS_CONCEPT_REQUEST"});
    try {
      if (chapter){
     const {data} = await API.get(`${url}/concept/${level}/${chapter}?page=${page}&limit=${3}` );
    
     dispatch({type:"ALL_CHAPTERS_CONCEPT_SUCCESS",payload:data});
      }
    } catch (error) {
      
      dispatch({type:"ALL_CHAPTERS_CONCEPT_FAIL",payload:error});
    }
  }


  export const allChaptersExamplesAction = (page,level,chapter)=>async(dispatch)=>{
   
    dispatch({type:"ALL_CHAPTERS_EXAMPLE_REQUEST"});
    try {
     
   
     const { data } = await API.get(`${url}/example/${level}/${chapter}?page=${page}&limit=${3}`
    );
    
      dispatch({type:"ALL_CHAPTERS_EXAMPLE_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"ALL_CHAPTERS_EXAMPLE_FAIL"});
    }
  }





  export const reviseConceptOfAllChaptersAction=(id,chapter)=>async(dispatch)=>{
  
    dispatch({type:"ALL_CHAPTERS_REVISE_REQUEST"});
   
    try {
     const {data} = await API.post(`/revise`,{id,chapter,d:'llll'} );
     dispatch({type:"ALL_CHAPTERS_REVISE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"ALL_CHAPTERS_REVISE_FAIL"});
    }
  }

  //more

  export const moreQuestionOfAllChaptersAction = (page,level,chapter) => async(dispatch)=>{
    
    dispatch({type:"MORE_QUE_ALL_CHAPTERS_REQUEST"});
   
    try {
     const {data} = await API.get(`${url}/more/question/${level}/${chapter}?page=${page}&limit=${12}`);
     
     dispatch({type:"MORE_QUE_ALL_CHAPTERS_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_QUE_ALL_CHAPTERS_FAIL"});
    }
  }
  
  export const moreAnswerOfAllChaptersAction = (page,level,chapter) => async(dispatch) => {
    dispatch({type:"MORE_ANS_ALL_CHAPTERS_REQUEST"});
   
    try {
     const {data} = await API.get(`${url}/more/answer/${level}/${chapter}?page=${page}&limit=${12}`);
     dispatch({type:"MORE_ANS_ALL_CHAPTERS_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_ANS_ALL_CHAPTERS_FAIL"});
    }
  }



