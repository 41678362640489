import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import Pagination from '../../../../components/Pagination';
import Button from '@mui/material/Button';
import { responseAnswerOfJeemains, responseAnswerOfJeemainsAllData} from '../../../../redux/actions/mathsActions/mathsactions';
import Loading from '../../../../components/Loading';
import { responseAnswerOfJeemainsDefinite, responseAnswerOfJeemainsDefiniteAllData } from '../../../../redux/actions/mathsActions/definiteIntegral/conceptDefiniteAction';
import { signout } from '../../../../redux/actions/Auth/AuthAction';
import { useNavigate } from 'react-router-dom';


const JeeMains = ({selectedPage,page3,setPage3,pathName}) => {
  const allResponseData = useSelector(state=>state.ResponseJeemainsAllDataIndifinite);
  const allResponseDataForDefinite = useSelector(state=>state.ResponseJeemainsAllDataDifiniteRed);

  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const [responseOption,setResponseOption] = useState({response:null,id:null})
  const { data:{data},loading } = useSelector(state=>state.jeemainRed);
  const ResponseJeemainsIndifinite = useSelector(state=>state.ResponseJeemainsIndifinite);
  const ResponseJeemainsDifinite = useSelector(state=>state.ResponseJeemainsDifiniteRed);
  const difiniteDataOfjeemains= useSelector(state=>state.jeemainsOfDifinite);
 
  const dispatch = useDispatch()
  const  optionSelect=(i,id)=>{
    if(squaredNum&&(data?.results[0]?._id!== squaredNum[0]?.questionId ) &&(squaredNum&& squaredNum[0]?.creator!==userData.id)){
      setResponseOption({response:i,id:id});
    }
    if(pathName==='indefinite-integrals'){
      if(squaredNum&&(data?.results[0]?._id!== squaredNum[0]?.questionId ) &&(squaredNum&& squaredNum[0]?.creator!==userData.id)){
        setResponseOption({response:i,id:id});
   }
  
    }
   
    else if(pathName==='definite-integrals'){
      if(squaredNum&&(difiniteDataOfjeemains?.data?.data?.results[0]?._id!== squaredNum[0]?.questionId ) &&(squaredNum&& squaredNum[0]?.creator!==userData.id)){
        setResponseOption({response:i,id:id});
     }
  }
   
   }
 
  function handleSubmit(e){
    e.preventDefault();
    if(pathName==='indefinite-integrals'){
       dispatch(responseAnswerOfJeemains(responseOption.response,responseOption.id))
         
        }
    else if(pathName==='definite-integrals'){
      dispatch(responseAnswerOfJeemainsDefinite(responseOption.response,responseOption.id))
     
        }}
 useEffect(()=>{
  if(pathName==='indefinite-integrals'){
    dispatch(responseAnswerOfJeemainsAllData());
  }else{
    dispatch(responseAnswerOfJeemainsDefiniteAllData())
  }
  
 
 },[ ResponseJeemainsIndifinite,responseOption.response,dispatch,pathName, ResponseJeemainsDifinite]);

const squaredNum = useMemo(()=> {
 
  if(pathName==='indefinite-integrals'){
    return allResponseData?.data?.gettingResponses?.filter(x=>x.questionId===data?.results[0]._id && x.creator===userData.id );
  }else{
    return allResponseDataForDefinite?.data?.gettingResponses?.filter(x=>x.questionId===difiniteDataOfjeemains?.data?.data?.results[0]._id && x.creator===userData.id );

  }
  
}, [allResponseData,data,allResponseDataForDefinite,difiniteDataOfjeemains,userData,pathName]);
 
 const history = useNavigate();
 
 useEffect(()=>{
  if(difiniteDataOfjeemains.data.message==='jwt expired'){
    dispatch(signout());
  }
 },[difiniteDataOfjeemains.data.message,dispatch])
 
 return (
    <form onSubmit={handleSubmit}>
     <div className=' border h-screen overflow-auto' >
      <div className=' absolute bottom-0 right-2 mb-3 mt-3'>
         {
         
         <Pagination setPage3={setPage3} page3={page3} selectedPage={selectedPage} conceptData={pathName==='indefinite-integrals'?data:difiniteDataOfjeemains?.data?.data} /> }
    
     </div>
     <main className='md:ml-[400px]  mt-[100px]  flex justify-center items-center text-gray-900'>
     <div className='ml-1 w-[900px]'>
    {(pathName==='indefinite-integrals'?loading:difiniteDataOfjeemains.loading)?<Loading/>:
     (pathName==='indefinite-integrals'?data:difiniteDataOfjeemains?.data?.data)?.results?.map(x=>(
     <div key={x.uuid}>
      <div className='definition' >
         <div className='' >
              <h2 style={{color:"#005DFF"}}> {x.heading}</h2>
               <div className='flex items-center mb-2' >
                <div className='mr-3'>{x.Qno}.</div>
                <div style={{color:"#005DFF"}} className='text-[9px] vvs:text-[11px] xs:text-[13px]' >  <InlineMath displayMode={true} options={{strict: false}} math={x.question} errorColor={'#cc0000'} /></div>
                </div>
             </div>    
          </div> 
         {
              x.options.map((opt,i)=>(
                    <div key={i} >
                    
                    <div className={`d-flex relative definition cursor-pointer items-center rounded ${Number(squaredNum&&squaredNum[0]?.correctAnswer)===i&&squaredNum[0]?.creator===userData.id && 'success'} ${squaredNum&&squaredNum[0]?.creator===userData.id &&squaredNum&&squaredNum[0]?.response===i&&squaredNum&&squaredNum[0]?.response===x.correctanswer&&"success"} ${(squaredNum&&squaredNum[0]?.response!==x.correctanswer)&&squaredNum&&squaredNum[0]?.response===i&& squaredNum[0]?.creator===userData.id && "error"} ${(responseOption.response===i && responseOption.id===x._id  ) &&'selecting'}`} onClick={()=>optionSelect(i,x._id)} >
                    <div className={`absolute right-2 ${((squaredNum&&squaredNum[0]?.response===x.correctanswer&& squaredNum[0]?.creator===userData.id) || (Number(squaredNum&&squaredNum[0]?.correctAnswer)===i&&squaredNum[0]?.creator===userData.id)) && "text-green-600"} ${ squaredNum&&squaredNum[0]?.response!==x.correctanswer&& squaredNum&&squaredNum[0]?.creator===userData.id &&" text-error"}`}>{(Number(squaredNum&&squaredNum[0]?.correctAnswer)===i&&squaredNum[0]?.creator===userData.id && 'Correct Answer')||(squaredNum&&squaredNum[0]?.creator===userData.id &&squaredNum&&squaredNum[0]?.response===i&& x.correctanswer===opt.id&&"Correct Answer")||(squaredNum&&squaredNum[0]?.creator===userData.id&&squaredNum&&squaredNum[0]?.response===i &&   x.correctanswer!==opt.id && "Wrong Answer")}</div>
                    <div className='mr-5 text-[19px] option ' >{String.fromCharCode(i+97)}</div>
                    <div className='p-2 text-[13px] ss:text-[16px]'> <InlineMath displayMode={true} options={{strict: false}} math={opt.option} errorColor={'#cc0000'} /></div>
                  
            </div>
              </div>
                    
                        
       ))}
    
     {squaredNum&&(x._id=== squaredNum[0]?.questionId&&squaredNum[0]?.creator===userData.id) &&
      <div className='third relative p-3 rounded -z-10 text-[13px] ss:text-[16px]' >
        <div className='text-[9px] vvs:text-[11px] xs:text-[13px]' ><InlineMath displayMode={true} options={{strict: false}} math={squaredNum&& squaredNum[0].solution} errorColor={'#cc0000'} /></div> 
           
      </div>
          
     }
       </div>
     
    ))}
   
  {
    difiniteDataOfjeemains.data.message==='jwt expired' && <div className='text-center mx-auto my-auto bg-black text-white p-2 max-w-[500px]'>Your Session has expired <span className=' cursor-pointer text-blue' onClick={()=> history('/',{replace:true})} >Click Me</span> to Log In again</div>
  }
 
 </div>
     </main>
   <div className='absolute left-3 md:ml-[400px] bottom-3' >
   {
  (data||difiniteDataOfjeemains?.data?.data)&& <Button disabled={squaredNum&&(data?.results[0]?._id=== squaredNum[0]?.questionId ||difiniteDataOfjeemains?.data?.data?.results[0]?._id===squaredNum[0]?.questionId) &&(squaredNum&& squaredNum[0]?.creator===userData.id)?true:false} variant="contained" type='submit' color='success' >Submit
  <span>{(ResponseJeemainsDifinite?.loading ||ResponseJeemainsIndifinite?.loading )&&<div className='button--loading py-[12px]'></div> }</span>
  </Button>
   
   }
    </div>
     </div>
      </form>
   )
 }
 
 export default JeeMains
  