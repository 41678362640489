import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Navbar2 from '../../../../components/Navbar2'
import Pagination from '../../../../components/Pagination';
import SawalSidebar from '../../../../components/SawalSidebar'
import { moreQuestionOfDefifiniteAction } from '../../../../redux/actions/mathsActions/definiteIntegral/conceptDefiniteAction';
import { moreQuestionOfIndifinite } from '../../../../redux/actions/mathsActions/mathsactions';
import Screnns from './MainScreen';

const concepts = 'Do Yourself'


const MoreProblems = () => {
  const [selectedPage,setSelectedPage] = useState('more problems');
  const [sidebarShow,setSidebarShow] = useState(true);
  const [page4,setPage4] = useState(1);
  const [pathName,setPathName] = useState('');
  const {data,loading} = useSelector(state=>state.moreQueIndifinite);
  const moreDataOfDefinite = useSelector(state=>state.moreQueDedifiniteRed);
  const location = useLocation();
  useEffect(()=>{
    const path = location.pathname.split('/');
    setPathName(path[4]);
//console.log(path[4],'lklkl');
},[location])
    const dispatch = useDispatch()

    useEffect(() => {
       if(pathName==='indefinite-integrals'){
           
             if(selectedPage === 'more problems'){
                dispatch(moreQuestionOfIndifinite(page4));
               
        //dispatch(answersJeemains(page3))
          }
       }else if(pathName==='definite-integrals'){
        if(selectedPage === 'more problems'){
          dispatch(moreQuestionOfDefifiniteAction(page4))
         }}
      
    }, [page4,selectedPage,pathName,dispatch])

  return (

    <div>

    
    <div className=' min-h-[86vh]'>
    <div className=' fixed bottom-2 z-40 right-4 mt-3'>
         <Pagination setPage4={setPage4} page4={page4}  selectedPage={selectedPage} conceptData={pathName==='indefinite-integrals'?data:moreDataOfDefinite?.data} />
    </div>
    <div>
    <div className='md:block hidden z-50 h-full' ><SawalSidebar pathName={pathName}  page4={page4} selectedPage={selectedPage} /></div>
     {!sidebarShow&& <div className='z-50 h-full' ><SawalSidebar pathName={pathName} sidebarShow={sidebarShow} setSidebarShow={setSidebarShow} page4={page4}  selectedPage={selectedPage} /></div>}
     
     
    </div>
    <nav>
        <Navbar2 concepts={concepts} sidebarShow={sidebarShow} setSidebarShow={setSidebarShow}
           selectedPage={selectedPage} 
        />
     </nav>
    <section>
      <Screnns pathName={pathName} />
    </section>
   </div>   
   </div>
  )
}

export default MoreProblems
