import React, { useEffect, useState } from 'react'
import { InlineMath } from 'react-katex'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CloseIcons } from '../../../icons/Icon';

const ReviseTab = ({setRevise,pathName,selectedPage}) => {
    const reviseData = useSelector(state=>state.reviseConceptsOfDerivativeRed);
    
  return (
    <section className='absolute top-0 rounded-md md:max-w-[800px] right-0 z-50 bg-white definition ml-10' >
    <div> 
     <div className='text-3xl py-2 flex justify-center' style={{color:"#005DFF"}}>{reviseData?.data?.data?.heading}</div>
     <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]'>{reviseData.data?.data?.definition1}</div>
    <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]'>
      <InlineMath displayMode={true} options={{strict: false}} math={reviseData?.data?.data?.concept} errorColor={'#cc0000'} />
    </div>
    <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]'>
      <InlineMath displayMode={true} options={{strict: false}} math={reviseData?.data?.data?.examples} errorColor={'#cc0000'} />
    </div>
    <div>{reviseData?.data?.data?.definition2}</div>
    
    
    
      <div className=' absolute top-2 right-2 cursor-pointer' onClick={()=>setRevise(false)} ><CloseIcons/></div>
    </div>
   </section>
  )
}

export default ReviseTab
