import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const height = '100px'
const Home = () => {
 
  const userInfoData = localStorage.getItem("userInfo");
  const [navbarMenu,setNavbarMenu] = useState(false)
  const history = useNavigate()
  useEffect(() => {
    if(!userInfoData){
      history('/')
    }
  }, [userInfoData,history]);
  
  return (
    <div>
    <div>
      <Sidebar navbarMenu={ navbarMenu } setNavbarMenu={setNavbarMenu} />
   </div>
    <div className="fixed w-full top-0 left-0">
      <Navbar height={height} navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} />
    </div>
    
  
   <section id='home' className='ml-[0] items-center justify-center mr-auto flex gap-40  mt-[90px] ' >
    <main >
    <div className='ss:flex gap-4'>
     <Link className='flex justify-center w-[200px] items-center' to='/subject/physics' >
     <div className=''>
       {/* <img className='w-[200px] ss:w-[300px]' src="/assets/phyLogo.jpg" alt=""/> */}
       <LazyLoadImage
      alt='something prlm'
      src='/assets/phyLogo.jpg' // use normal <img> attributes as props
     />
       <div className='text-center text-2xl font-playfair' >Physics</div>
      </div>
     </Link>
     <Link className='flex justify-center w-[200px] items-center' to='/subject/maths'>
     <div className='w-[200px] ss:w-[300px]'>
       {/* <div> <img src="/assets/pii.jpg" alt="" /></div> */}
       <LazyLoadImage
      alt='something prlm'
      src='/assets/pii.jpg' // use normal <img> attributes as props
     
     />
      <div className='text-center text-2xl font-playfair'>Maths</div>
     </div>
     
     </Link>
   </div>
   <div className='ss:flex gap-4 ' >
     <div>
     <Link className='flex justify-center w-[200px] rounded-md border-gray2 border-1 mb-20  items-center relative ss:top-0 top-6' to='/irodov-problems-video-solutions'>
     <div className='w-[200px] ss:w-[300px]'>
       {/* <div> <img className='rounded-md' src="/assets/irodov.jpg" alt="" /></div> */}
       <LazyLoadImage
       className='rounded-md'
       alt='something prlm'
       src='/assets/irodov.jpg' // use normal <img> attributes as props
     
     />
      <div className='text-center text-1xl font-playfair'>Irodov Problems and Solutions</div>
     </div>
     
     </Link>
     </div>
    <div>
     <Link className='flex justify-center  rounded-md border-gray2 border-1 items-center relative ss:top-0 -top-8' to='/challenging-problems'>
     <div className='w-[200px] ss:w-[200px]'>
       {/* <div> <img className='rounded-md' src="/assets/challenging-problems.jpeg" alt="" /></div> */}
       <LazyLoadImage
         className='rounded-md'
         alt='something prlm'
         src='/assets/challenging-problems.jpeg' // use normal <img> attributes as props
     
     />
      <div className='text-center text-1xl font-playfair '>Challenging Problems and Solutions</div>
     </div>
     
     </Link>
     </div>  
</div>
<div>
<Link className='flex justify-center w-[200px] rounded-md border-gray2 border-1 items-center relative top-2 ss:-top-8  ' to='/mock-test'>
     <div className='w-[200px] '>
     {/* <img className='rounded-md' src="/assets/mock-test.png" width='180px' alt="" /> */}
     <LazyLoadImage
      className='rounded-md'
      alt='something prlm'
      src='/assets/mock-test.png' // use normal <img> attributes as props
    
     />
      <div className='text-center text-1xl font-playfair'>Mock Test</div>
     </div>
     
     </Link>
</div>
</main>
     
   </section>
   
   </div>
  )
}

export default Home
