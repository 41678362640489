import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Tab1 from './tabs/Tab1';
import Tab2 from './tabs/Tab2';
import TabToggle from './TabToggle';
import { responseAnswerOfMockTestAllDataAction } from '../../redux/actions/Mock-Test/MockTestAction';


const concepts = 'Results';
const examples = 'Answers';

const Results = ({ selectedPage,setSelectedPage,finishedMockTestAfterCompleteTimer,path ,user }) => {
  
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const { data }  = useSelector(state=>state.getresultsMockTestRed);
  
    const [page,setPage] = useState(1);
    const [page2,setPage2] = useState(1);
    const navigate = useNavigate();
   const dispatch=useDispatch();
   
  useEffect(() => {
    dispatch(responseAnswerOfMockTestAllDataAction(path));
    
}, [selectedPage,dispatch,path])

  const pageSelected1=()=>{
    setSelectedPage("concepts");
    navigate('?&activeTab=your-performane',{replace:true}); 
  }
  const pageSelected2=()=>{
    setSelectedPage("examples");
    navigate('?&activeTab=your-answer',{replace:true});
  }
  
  useEffect(()=>{
    if(finishedMockTestAfterCompleteTimer){
       navigate('?&activeTab=your-performane',{replace:true}); 
    }
   
  },[finishedMockTestAfterCompleteTimer,navigate]);
  
  return (
    <div className='bg-white'>
     {data?.results&& data?.results[0]?.creator === userData.id &&
      
    <div>
    <TabToggle pageSelected1={pageSelected1} pageSelected2 = { pageSelected2 } concepts={concepts} examples = {examples} selectedPage={selectedPage} data = { data } />
    
       <div>
       {
         selectedPage==='concepts'? <div><Tab1 page={page} setPage={setPage} selectedPage={selectedPage} data = { data } path={path} user={user} /></div>:<div><Tab2 page2={page2} setPage2={setPage2}  selectedPage={selectedPage} path = {path} /></div>
       }
     </div>
    </div>
    }
    
    </div>
  )
}

export default Results

