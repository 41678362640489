import axios from 'axios';

const url = 'https://whiteboardblackpen-tge6.onrender.com/physics'
//const url = 'http://localhost:8000/physics'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/physics`
const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });

export const physicschapterNamesOfAll=()=>async(dispatch)=>{
    dispatch({type:"PHYSICS_CHAPTER_NAME_REQUEST"});
     try {
      const {data} = await API.get(`${url}/chaptername`);
      dispatch({type:"PHYSICS_CHAPTER_NAME_SUCCESS",payload:data})
     } catch (error) {
      dispatch({type:"PHYSICS_CHAPTER_NAME_FAIL"});
     
     }
  }