import axios from 'axios';
import FormData from 'form-data';
// const API = axios.create({ baseURL: 'https://whiteboardblackpen-tge6.onrender.com/pdf' });
// API.interceptors.request.use((req) => {
//     if (localStorage.getItem('userInfo')) {
//       req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
//     }
  
//     return req;
//   });
const URL = 'https://whiteboardblackpen-tge6.onrender.com/profile';
//const URL = 'http://localhost:8000/profile'

//const URL = `${process.env.REACT_APP_BACKEND_DOMAIN}/profile`;

export const filesAllData=()=>async(dispatch)=>{
    dispatch({type:"DOWNLOAD_ALLDATA_REQUEST"});
   
    try {
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${URL}/all/pdf`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'ContentType':'multipart/form-data'
    }
     });
      
     dispatch({type:"DOWNLOAD_ALLDATA_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"DOWNLOAD_ALLDATA_FAIL"});
    }
  }
  

  export const uploadPdfFile=(avatar,setUploadPercentage)=>async(dispatch)=>{
    let formData = new FormData();
    formData.append('file',(avatar)); 
    dispatch({type:"POST_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
   
     const {data} = await axios.post(`${URL}/upload/pdf`,formData,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: progressEvent => {
      setUploadPercentage(
        parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        )
      );
    }
     })
     dispatch({type:"POST_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"POST_FAIL"});
    }
  }


  // upload profilee

  export const uploadProfileAction=(filename,avatar,setUpload)=>async(dispatch)=>{

    let formData = new FormData();
    formData.append('file',(avatar)); 
    formData.append('filename',(filename)); 
    dispatch({type:"PROFILE_UPLOAD_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
   
     const {data} = await axios.post(`${URL}/upload-profile-picture`,formData,{
     
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': 'multipart/form-data'
    },
   
     });
     setUpload(data);
     dispatch({type:"PROFILE_UPLOAD_SUCCESS",payload:data})
    }
     catch(error){
     
       dispatch({type:"PROFILE_UPLOAD_FAIL",
       payload:error?.response&&error.response.data.message
            ?error.response.data.message
            :error.message
       
      });
    }
  }

  export const getProfileAction=(filename)=>async(dispatch)=>{
    dispatch({type:"GET_PROFILE_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
   
     const { data } = await axios.get(`${URL}/user`,{
     
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': 'multipart/form-data'
    },
    
     });
    
     dispatch({type:"GET_PROFILE_SUCCESS",payload:data})
    }
     catch(err){
       dispatch({type:"GET_PROFILE_FAIL"});
    }
  }

  export const deleteProfileAction=(filename, setDeleteFile,id)=>async(dispatch)=>{
    dispatch({type:"DELETE_PROFILE_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const { data } = await axios.delete(`${URL}/delete-profile-picure`,{
     headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    },
    data:{filename:filename,id},
   
     })
     dispatch({type:"DELETE_PROFILE_SUCCESS",payload:data});
     setDeleteFile(data)
    }
    
     catch(error){
       dispatch({type:"DELETE_PROFILE_FAIL", payload:
       error.response&&error.response.data.message
       ?error.response.data.message
       :error.message});
    }
  }

  // error.response&&error.response.data.message
  //      ?error.response.data.message
  //      :error.message