import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { CloseIcons, MenuIcons } from '../icons/Icon'

const Navbar2 = ({setSidebarShow,sidebarShow,subject,concepts,examples,exercises,selectedPage,pageSelected1,pageSelected2,pageSelected3}) => {
 const [path,setPath] = useState('');
 const [pathForTestSeries,setPathForTestSeries] = useState('');
 //jee advance ;
 const location = useLocation();
 useEffect(() => {
  const pat = location.pathname.split('/');
    setPath(pat[3]);
    setPathForTestSeries(pat[1])
 }, [selectedPage,location,pathForTestSeries])
  return (
  <div className='relative' >

  <div className='fixed top-2 right-4 z-[100] block md:hidden'>

    {
      (selectedPage==="jee mains"||selectedPage==="more problems"||selectedPage==="jee advance"||(path!=='integrals'&&selectedPage==="exercises")) && <div>{sidebarShow ?<div onClick={()=>setSidebarShow(false)} ><MenuIcons/></div>:<div onClick={()=>setSidebarShow(true)} ><CloseIcons/></div>}</div> 
    }
    </div>
    <nav id='navbar' className={`fixed z-[60] bg-white right-0 ${(selectedPage==="jee mains"||selectedPage==="jee advance"||selectedPage==="more problems"||(path!=='integrals'&&selectedPage==="exercises"))?"md:ml-[400px]":"md:ml-[0px]"} top-0 left-0`} >
      
      <div className={`justify-between ${(selectedPage==="jee mains"||selectedPage==="jee advance"||selectedPage==="more problems"||(path!=='integrals'&&selectedPage==="exercises"))&&"h-12"}`} >
       <div className='flex w-full justify-between'>
        <div className='absolute top-2 left-1 w-[30px] h-[40px] mr-3 justify-between items-center' >
          <img src="/integ/pi.png" alt="" />
        </div>
         <div className={`w-full relative bottom-6 ss:text-2xl text-gray2 font-sofia mt-8 mb-3 flex justify-center `} >{subject}</div>
        
       </div>
        
      </div>
      <div className='flex mb-3 ml-2 topics  text-[15px] sm:text-[19px]' >
       <div className={`${(selectedPage==='concepts'||selectedPage==='jee mains')&&'actives'} ${selectedPage==='more problems'&& 'flex w-[800px] text-center justify-center'}`} onClick={pageSelected1} >{concepts}</div>
       <div className={`duration-300 ${(selectedPage==='examples'||selectedPage==='jee advance')&&'actives'} `} onClick={pageSelected2}>{examples}</div>
       <div className={`${selectedPage==='exercises'&&'actives'}`} onClick={pageSelected3}>{exercises}</div>

     </div>
    </nav>
    </div>
  )
}

export default Navbar2








// NAVBAR FOR TEST SERIES

export const NavbarForTestSeires = ({setNavbarMenu,navbarMenu,subject,concepts,examples,exercises,selectedPage,pageSelected1,pageSelected2,pageSelected3}) => {
  
   return (
   <div className='relative' >
 
   <div className='fixed top-2 right-4 z-[100] block md:hidden'>
 
   {
       <div>{navbarMenu ?<div onClick={()=>setNavbarMenu(false)} ><MenuIcons/></div>:<div onClick={()=>setNavbarMenu(true)} ><CloseIcons/></div>}</div> 
        }
     </div>
     <nav id='navbar' className={`fixed z-[60] bg-white right-0 ${(selectedPage==="examples"||selectedPage==="concepts"||selectedPage==="exercises")?"md:ml-[400px]":"md:ml-[0px]"} top-0 left-0`} >
    
       <div>
        <div className={`w-full justify-between items-center`}>
         <div className='absolute left-2 w-[30px] h-[40px] top-2 justify-between items-center' >
           <img src="/integ/pi.png" alt="" />
         </div>
          <h4 className={`w-full text-center relative bottom-6 ss:text-2xl text-gray2 font-sofia mt-8 mb-0 flex justify-center`} >{subject}</h4>
       </div>
         
       </div>
       <div className='flex mb-3 ml-2 topics  text-[15px] sm:text-[19px]' >
        <div className={`${(selectedPage==='concepts')&&'actives'} `} onClick={pageSelected1} >{concepts}</div>
        <div className={`duration-300 ${(selectedPage==='examples')&&'actives'} `} onClick={pageSelected2}>{examples}</div>
        <div className={`${selectedPage==='exercises'&&'actives'}`} onClick={pageSelected3}>{exercises}</div>
 
      </div>
     </nav>
     </div>
   )
 }
 
 
