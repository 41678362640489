import React, { useEffect, useState } from 'react'
import { sidebarQuestionOfJeeMains,moreAnswerOfIndifinite,  sidebarQuestionOfAdvanceIndifinite } from '../redux/actions/mathsActions/mathsactions';
import { useDispatch, useSelector } from 'react-redux'
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import Loading from './Loading';
import { advanceOfsidebarDefiniteQuestionAction, moreAnswerOfDedifiniteAction, sidebarDefiniteQuestion } from '../redux/actions/mathsActions/definiteIntegral/conceptDefiniteAction';
const SawalSidebar = ({page3,setPage3,selectedPage,page4,pathName,page5,setPage5}) => {
  const { data,loading } = useSelector(state=>state.sidebarMains);
  const advSideIndQues = useSelector(state=>state.sidebarAdvanceQuestionOfIndifinite);
  const answerOfChallege = useSelector(state=>state.moreAnsIndifinite);
  const defniteSidebarMains = useSelector(state=>state.sidebarMainsOfDefinite);
  const defniteSidebarDefiniteAdvance = useSelector(state=>state.sidebarAdvanceOfDefinite);
  const moreanswerOfDefinite = useSelector(state=>state.moreAnsDedifiniteRed);
  const dispatch = useDispatch()

  useEffect(() => {
    if(selectedPage==='jee mains'&&(page3%5===1 ||page3%5===0 ) ){
      //dispatch(sidebarQuestionOfJeeMains(Math.ceil(page3/5)));
      if(pathName==='indefinite-integrals'){
        dispatch(sidebarQuestionOfJeeMains(Math.ceil(page3/5)));
        //&& page3%5===1
      }
      else{
         dispatch(sidebarDefiniteQuestion(Math.ceil(page3/5)))
       }
    
    }
    else if(selectedPage==='more problems' ){
       
       if(pathName==='indefinite-integrals'){
         dispatch(moreAnswerOfIndifinite(page4));
      
       }else if(pathName==='definite-integrals'){
          dispatch(moreAnswerOfDedifiniteAction(page4));
        
       }
      
     
    }else if(selectedPage==='jee advance' && (page5%5===1 || page5%5===0 )){
    
      if(pathName==='indefinite-integrals'){
          dispatch(sidebarQuestionOfAdvanceIndifinite(Math.ceil(page5/5)));
  
      }else{
        dispatch(advanceOfsidebarDefiniteQuestionAction(Math.ceil(page5/5)))
      }
    }
 
    }, [page3,page4,selectedPage,page5,pathName,dispatch]);
    const pageset =(i)=>{
    
      if(selectedPage==='jee mains'){
      
        if(pathName==='indefinite-integrals'){
           setPage3(Number(i));
         
        }else {
          setPage3(Number(i));
        }
       
      }
     else if(selectedPage==='jee advance'){
        setPage5(Number(i))
      }
    
    }
 
  return (
  <div>
   {
    selectedPage==="jee mains" ? <section className='bg-gray2 h-screen overflow-x-hidden overflow-y-scroll z-[1000] w-[300px] ss:w-[400px] p-10  top-0 left-0 absolute ' >
      <div className='z-40 bg-black w-[282px] ss:w-[382px] items-center flex justify-center rounded-md text-white text-xl py-3 fixed top-2 left-2'>Mains List of Questions</div>
     <div className='side gap-y-4 my-10' >
    
      {   (pathName==='indefinite-integrals'?loading:defniteSidebarMains?.loading)?<Loading/>:
          (pathName==='indefinite-integrals'?data?.results:defniteSidebarMains?.data?.data?.results)?.map((x,i)=>(
          <div className={`flex  my-2 w-full-10/12 justify-start ${page3===Number(x.Qno) && 'bg-active'} p-3 rounded-md hover:bg-active duration-100` } onClick={()=>pageset(x.Qno)} key={x._id} >
             <div className='text-black'>{x.Qno}</div>
              <div className='text-[10px] ss:text-[13px] text-black ml-2 ' >  <InlineMath math={x.question} /></div>
          </div>
        ))
      }
     </div>
   
   </section>:selectedPage==="jee advance"?(
    <section className='bg-gray2 h-screen overflow-x-hidden overflow-y-scroll z-[1000] w-[300px] ss:w-[400px] p-10  top-0 left-0 absolute ' >
    <div className='z-40 bg-black w-[282px] ss:w-[382px] items-center flex justify-center rounded-md text-white text-xl py-3 fixed top-2 left-2'>Advance List of Questions</div>
     <div className='side gap-y-4 my-10' >
     {/* <div>  <InlineMath math={x.examples} /></div> */}
      {
        (pathName==='indefinite-integrals'?advSideIndQues?.loading:defniteSidebarDefiniteAdvance?.loading)?<Loading/>:
          (pathName==='indefinite-integrals'?advSideIndQues?.data?.results:defniteSidebarDefiniteAdvance?.data?.data?.results)?.map((x,i)=>(
          <div className={`flex  my-2 w-full-10/12 justify-start ${page5===Number(x.Qno) && 'bg-active'} p-3 rounded-md hover:bg-active duration-100` } onClick={()=>pageset(x.Qno)} key={x._id} >
             <div className='text-black'>{x.Qno}</div>
              <div className='text-[10px] ss:text-[13px] text-black ml-2 ' >  <InlineMath math={x.question} /></div>
          </div>
        ))
      }
     </div>
    </section>
   ):<section className='bg-gray2 h-screen overflow-x-hidden overflow-y-scroll z-[1000] w-[300px] ss:w-[400px] p-10  top-0 left-0 fixed ' >
   <div className='z-40 bg-black w-[282px] ss:w-[382px] items-center flex justify-center rounded-md text-white text-xl py-3 fixed top-2 tracking-wider left-2'>Answers</div>
     <div className='side gap-y-4 my-10' >
    
      {
        (pathName==='indefinite-integrals'?answerOfChallege?.loading:moreanswerOfDefinite?.loading)?<Loading/>:
          (pathName==='indefinite-integrals'?answerOfChallege?.data?.results:moreanswerOfDefinite?.data?.results)?.map((x,i)=>(
          <div className={`flex gap-5 my-2 w-full-10/12 justify-start} p-3 rounded-md hover:bg-active duration-100` } key={x._id} >
             <div className='text-blue'>{x.Ano}</div>
              <div className='ss:text-[15px] text-[13px] text-black ml-2 ' >  <InlineMath math={x.answer} /></div>
          </div>
        ))
      }
     </div>
    </section>
   }
   
   </div>
  )
}

export default SawalSidebar
