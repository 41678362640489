import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllLoggedInUsersActions } from '../../redux/actions/Auth/IsAdminAction';
import Loading from '../../components/Loading';
import { DateConverting } from '../../lib/Utils';

const Tab3 = () => {
    const { data,loading } = useSelector(state=>state.getAllLoggedInUsersRed);
    const dispatch = useDispatch();
    useEffect(()=>{
      dispatch(getAllLoggedInUsersActions())
    },[])
    
  return (
    <div>
       { loading ? <Loading/>:
        data?.users?.map(x=>(
            <div key={x._id} className='border-1 m-3 text-start  py-6 mt-4 items-center justify-center  border-black' >
                <p><span className='text-2xl'>Name : </span>{process.env.REACT_APP_ADMIN_SECUIRITY===x.name?"Sahil Bhayya":x.name}</p>
                <p><span className='text-2xl'>Phone number : </span> <span className='text-[3px]'>{x.phoneNumber}</span></p>
                <p><span className='text-2xl'>CreatedAt : </span>{DateConverting(x.createdAt)}</p>
               
            </div>
        ))
      }
    </div>
  )
}

export default Tab3
