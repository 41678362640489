import React, { useState } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../components/Loading'
import Pagination from '../../../components/Pagination'
import { GoBackIcon } from '../../../icons/Icon'
import ReviseTab from './ReviseTab'
import { reviseConceptOfWorkEnergy } from '../../../redux/actions/physicsActions/WorkEnergy/workEnergyAction'


const Tab2 = ({setPage2,selectedPage,page2}) => {
  const {data,loading} = useSelector(state=>state.examplesOfWorkEnergy);
  const [revise,setRevise] = useState(false);
  const [id,setId] = useState(null);
  const [reviseTab,setReviseTab] = useState()
  const dispatch = useDispatch();
 
  const revisedConcept=(id)=>{
    if(!revise){
       dispatch(reviseConceptOfWorkEnergy(id));
       setRevise(true);
      
    }
    setId(id)
   }
  //  useEffect(()=>{
  //     const selectedData = data?.results?.find(x=>x.conceptId === id).conceptId;
  //     console.log(selectedData,'mkmmm',data?.results,id);
  //  },[id])
  return (
    <div className='relative' >
     <main className=' mt-[180px]  flex justify-center items-center text-gray-900'>
     
     <div className='ml-1 w-[1050px]'>
   {
   loading?<Loading/>:
  
   data?.results?.map(x=>(
      <div className='definition relative rounded-md  z-30 cursor-pointer' key={x._id} >
         <div className='' >
              <h2 style={{color:"#005DFF"}}> {x.heading}</h2>
               <div className='flex items-center mb-2 justify-between' >
                <div className='flex items-center mb-2 '>
                <div className='mr-3'>{x.Qno}.</div>
                <div className='defini' style={{color:"#005DFF",fontSize:'1.2rem'}}> {x.Question}</div>
                {x.QuestionLatex && <div className='text-[12px] xs:text-[13px] sm:text-[16px]' ><InlineMath displayMode={true} options={{strict: false}} math={x.QuestionLatex} errorColor={'#cc0000'} /></div>} 
              
                </div>
               
                </div>
                {x.image && <div><img src={x.image} width='200px' alt="" /></div>}
                <div className='defini' style={{fontSize:'1.2rem'}} >{x.solution}</div>
                 
                  {x.solutionLatex&&<div className='text-[12px] xs:text-[13px] sm:text-[16px]'><InlineMath displayMode={true} options={{strict: false}} math={x.solutionLatex} errorColor={'#cc0000'} /></div>}
              
                 
                  <div><img src={x.image1} width='200px' alt="" /></div>
                {
                 x.solution2 &&  <div className='defini' style={{fontSize:'1.2rem'}} >{x.solution2}</div>
               }
               {x.solutionLatex2 && <div className='text-[12px] xs:text-[13px] sm:text-[16px]'><InlineMath displayMode={true} options={{strict: false}} math={x.solutionLatex2} errorColor={'#cc0000'} /></div>}
               
                </div>
                {/* <div className='absolute right-2 bottom-2 bg-gray2 text-white py-2 px-3 ss:px-4 rounded flex' onClick={()=>revisedConcept(x.conceptId)} >
                      <GoBackIcon/>
                </div> */}
                <div className='absolute right-2 bottom-2 bg-gray2 text-white py-2 px-3 ss:px-4 rounded flex' onClick={()=>revisedConcept(x.conceptId)} >
                {reviseTab && (x?.conceptId===data?.results?.find(x=>x?.conceptId === id)?.conceptId)?<div className='button--loading py-[12px]' ></div>:
                   <GoBackIcon/>}  
                </div>
            
                </div>
         ))}
         {!revise &&
         <div className={`mb-3 mt-[18px] ${selectedPage==="examples"?'fixed top-[115px] ss:top-[125px] sm:top-[130px] right-2 items-center left-25 flex justify-center':''} z-40`}>
          <Pagination setPage2={setPage2} page2={page2} selectedPage={selectedPage} exampletData={data}  />
         </div>
         }
     </div>
   
</main>

<div>
  { (revise )&&<div><ReviseTab setRevise={setRevise} selectedPage={selectedPage} setReviseTab = {setReviseTab} /></div> }
</div>

    </div>
  )
}

export default Tab2