import React, { useEffect } from 'react'
import { InlineMath } from 'react-katex'
import { useSelector } from 'react-redux';
import { CloseIcons } from '../../../icons/Icon';

const ReviseTab = ({setRevise,setReviseTab}) => {
    const {data,loading} = useSelector(state=>state.reviseConceptsOfWorkEnergyRed);
    useEffect(()=>{
      setReviseTab(loading)
     },[loading])
  
     if(loading){
      return (
        <div>' '</div>
      )
     }
  return (
    <section className='absolute top-0 rounded-md md:max-w-[800px] right-0 z-50 bg-white definition ml-10' >
    <div >
                  <div className='flex items-center'>
                    <div className='text-[19px] mr-2 '>{data?.data?.conceptNo}.</div>
                     <h1 className='text-2xl flex items-center' style={{color:"#005DFF"}}> {data?.data?.heading}</h1>
                     </div>
                   
                    <div  style={{margin:"10px 0"}} > {data?.data?.definition}</div>
                    {data?.data?.image && <div><img src={data?.data?.image} alt="" width='200px' /></div>}  
                     {data?.data?.concept1 && <div className='.defini'>{data?.data?.concept1}</div> } 
                    {data?.data?.concept && <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]'><InlineMath displayMode={true} options={{strict: false}} math={data?.data?.concept} errorColor={'#cc0000'} /></div>}  
                    {data?.data?.image && <div><img src={data?.data?.image2} alt="" width='200px' /></div>}
                    <div className='.defini'>{data?.data?.concept2}</div>
                    <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]'><InlineMath displayMode={true} options={{strict: false}} math={data?.data?.example} errorColor={'#cc0000'} /></div>
                    <div className='.defini'>{data?.data?.concept3}</div>
                    <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]'><InlineMath displayMode={true} options={{strict: false}} math={data?.data?.example2} errorColor={'#cc0000'} /></div>
                    <div className='.defini'>{data?.data?.divexample}</div>
                    <div className=' absolute top-2 right-2 cursor-pointer hover:bg-deep-blue p-1 hover:text-white rounded-full duration-200' onClick={()=>setRevise(false)} ><CloseIcons/></div>
                </div>
   </section>
  )
}

export default ReviseTab
