import React, { useEffect } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../components/Loading'
import { moreAnswerOfMaximaAction } from '../../../redux/actions/mathsActions/applicationOfDerivative/maximaMinima/maximaAction'
import { moreAnswerOfTangentAction } from '../../../redux/actions/mathsActions/applicationOfDerivative/tangentNormal/tangentAction'

const PrashnaSideBar = ({page,path}) => {
    const {data,loading} = useSelector(state=>path==='tangent-normal'?state.moreAnsTangentRed:state.moreAnsMaximaRed)
    const dispatch = useDispatch()
    useEffect(() => {
      if(path==='tangent-normal'){
         dispatch(moreAnswerOfTangentAction(page));
       
      }else{
        dispatch(moreAnswerOfMaximaAction(page))
       
      }
       
    }, [page,path])
  return (
    <section className='bg-gray2 h-screen overflow-x-hidden overflow-y-scroll z-[1000] w-[300px] ss:w-[400px] p-10  top-0 left-0 fixed ' >
   <div className='z-40 bg-black w-[282px] ss:w-[382px] items-center flex justify-center rounded-md text-white text-xl py-3 fixed top-2 tracking-wider left-2'>Answers</div>
     <div className='side gap-y-4 my-10' >
     {/* <div>  <InlineMath math={x.examples} /></div> */}
      {
        loading?<Loading/>:
          data?.results?.map((x,i)=>(
          <div className={`flex gap-5 my-2 w-full-10/12 justify-start} p-3 rounded-md hover:bg-active duration-100` } key={x._id} >
             <div className='text-blue'>{x.Ano}</div>
              <div className='ss:text-[15px] text-[13px] text-black ml-2 ' >  <InlineMath math={x.answer} /></div>
          </div>
        ))
      }
     </div>
    </section>
  )
}

export default PrashnaSideBar