import React, { useEffect } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux';
import { challengingProblemsSolutionLikes, getByIdChallengingProblemsLikesAction } from '../redux/actions/Challenging-Problems/ChallengingProblemsActions';
import { Button } from '@mui/material';
import { ThumbUpAltIcons, ThumbUpAltOutlinedIcons } from '../icons/Icon';
import { useMemo } from 'react';


const ChallengingSolution = ({ setReviseTab,question,id,setLikes,likes,page2}) => {
    const solutionData = useSelector(state=>state.challengingSolutionsRed);
   // const likedData = useSelector(state=>state.challengingSolutionsLikesRed );
    const {data} = useSelector(state=>state.getByIdchallengingLikesRed);
   
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const hasLikedPost =  useMemo(()=>likes?.find((like) => like === user.id),[likes,user.id]);
    const dispatch = useDispatch();
    useEffect(()=>{
      if(data == null) return 
       setLikes(data.likes)
    },[data?.likes])
useEffect(()=>{
    setReviseTab(solutionData?.loading)
   },[solutionData?.loading])
    
const Likes = () => {
  if (likes?.length > 0) {
    return likes?.find((like) => like === (user.id))
      ? (
        <><ThumbUpAltIcons fontSize="small" />&nbsp;{likes.length > 2 ? `You and ${likes?.length - 1} others` : `${likes?.length} like${likes?.length > 1 ? 's' : ''}` }</>
      ) : (
        <><ThumbUpAltOutlinedIcons fontSize="small" />&nbsp;{likes?.length} {likes?.length === 1 ? 'Like' : 'Likes'}</>
      );
  }

  return <><ThumbUpAltOutlinedIcons fontSize="small" />&nbsp;Like</>;
};
useEffect(()=>{
  dispatch(getByIdChallengingProblemsLikesAction(id))
},[page2])
// dispatch,id,data,likedData
  const handleLike = async (id) => {
     dispatch(challengingProblemsSolutionLikes(id));
    if (!hasLikedPost ) {
      
        setLikes((prev)=>{
          return [...prev,user.id]
        })
      } 
   
  };

 if(solutionData?.loading){
    return (
      <div></div>
    )
   }
  
  return (
    <section className='' >
    <div >
                  <div className='items-center'>
                   
                     <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px] items-center mr-3 defini' > {solutionData?.data?.data?.solution}</div>
                    {solutionData?.data?.data?.solutionLatex && <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]'><InlineMath displayMode={true} options={{strict: false}} math={solutionData?.data?.data?.solutionLatex} errorColor={'#cc0000'} /></div>}  
                    {solutionData?.data?.data?.image && <div><img src={solutionData?.data?.data?.image} alt="" width='300px' /></div>}  

                    {solutionData?.data?.data?.solution2 && <div className='defini text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]'>{solutionData?.data?.data?.solution2}</div>}  
                    {solutionData?.data?.data?.solution2Latex && <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px]'><InlineMath displayMode={true} options={{strict: false}} math={solutionData?.data?.data?.solution2Latex} errorColor={'#cc0000'} /></div>}  
                      
                     </div>
                   
                   
                    {/* <div className=' absolute top-2 right-2 cursor-pointer hover:bg-deep-blue p-1 hover:text-white rounded-full duration-200' onClick={()=>setRevise(false)} ><CloseIcons/></div>
                 */}
                </div>
              
                 
                <Button className='cursor-pointer' onClick={()=>handleLike(question?._id)}><Likes /></Button> 
                        
   </section>
  )
}

export default ChallengingSolution
