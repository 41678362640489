import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PrashnaSideBar from './PrashnaSideBar';
import {moreQuestionOfDevativeAction} from '../../../redux/actions/mathsActions/derevative/derevativeAction'
import { InlineMath } from 'react-katex';
import Pagination from '../../../components/Pagination';
import { moreQuestionOfDifferentialEquationAction } from '../../../redux/actions/mathsActions/differentialEquation/differentialEquationAction';
import Loading from '../../../components/Loading';

const Tab3 = ({ selectedPage,sidebarShow,setSidebarShow }) => {
  const { data,loading } = useSelector(state=>state.moreQueDifferentialEquation);
  const [pageOfderivative,setPageOfderivative] = useState(1);
  const [page4,setPage4] = useState(1);
  const dispatch = useDispatch();
  
  useEffect(() => {
  
    dispatch(moreQuestionOfDifferentialEquationAction(page4));
    
  }, [page4,dispatch])
  return (
    <div className=''>
  
    {/* <PrashnaSideBar/> */}
    {/* this sidebar for bigger screen */}
  <div className='md:block hidden z-50 h-full' ><PrashnaSideBar setPage={setPage4} selectedPage={selectedPage} page={page4}  /></div>
   
   {!sidebarShow&& <div className='z-50 h-full' ><PrashnaSideBar setSidebarShow={setSidebarShow} setPage={setPage4} selectedPage={selectedPage} page={page4}  /></div>}
   
   <section className='md:ml-[400px] mb-[40px] mt-[100px] '>
      <div>
        {
          loading?<Loading/>:
          data?.results?.map(x=>(
           
            <div key={x._id} className='overflow-hidden'>
            
            <div  className='definition flex gap-3 rounded-md'>
               <div>{x.Qno}</div>
               <div > <InlineMath math={x.question} /></div>
               </div>
            </div>
          ))
        }
      </div>
      <div className=' fixed bottom-2 z-40 right-4 mt-3'>
       <Pagination setPage4={setPage4} page4={page4}  selectedPage={selectedPage} conceptData={data} />
  </div>
   </section>
  </div>
  )
}

export default Tab3