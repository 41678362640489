
export const isAdminRed=(state={posts:[]},action)=>{
    switch(action.type){
        case 'ISADMIN_FETCHDATA_REQUEST':
            return {...state, loading:true }
       
        case 'ISADMIN_FETCHDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ISADMIN_FETCHDATA_FAIL':
           return {loading:false,error:action.payload}
    //     case 'ISADMIN_DELETE_REQUEST':
    //        return {...state,loading:true}
    //     case 'ISADMIN_DELETE_SUCCESS':
    //         return {...state,posts:state.posts.filter(x=>x._id!==action.payload),loading:false}
    //    case 'ISADMIN_DELETE_FAIL':
              
                        
        default:
            return state
    }
}


export const getAllLoggedInUsersRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'LOGGED_IN_USERS_REQUEST':
            return { loading:true }
        case 'LOGGED_IN_USERS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'LOGGED_IN_USERS_FAIL':
           return {loading:false,error:action.payload}
        
        default:
            return state
    }
}


export const getAllCosingGuruUsersRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'CODING_GURU_USERS_REQUEST':
            return { loading:true }
        case 'CODING_GURU_USERS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'CODING_GURU_USERS_FAIL':
           return {loading:false,error:action.payload}
        
        default:
            return state
    }
}
