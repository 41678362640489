import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar2 from '../../components/Navbar2';
import Tab1 from './tabs/Tab1';
import Tab2 from './tabs/Tab2';
import Tab3 from './tabs/Tab3';
import { conceptOfProjectile, exampleOfProjectile } from '../../redux/actions/physicsActions/Projectile/projectileAction';

const height = '80px';
const subject = 'Projectile Motion';
const concepts = 'Concepts';
const exercises = 'Exercises';
const examples = 'Examples';

const Projectile = () => {
  const [selectedPage,setSelectedPage] = useState('concepts');
    const [sidebarShow,setSidebarShow] = useState(true);
    const [page,setPage] = useState(1);
    const [page2,setPage2] = useState(1);
    const navigate = useNavigate();
  const dispatch=useDispatch();
 //console.log(selectedPage);
  useEffect(() => {
    if(selectedPage==='concepts'){
       dispatch(conceptOfProjectile(page));
    }if(selectedPage==='examples'){
        dispatch(exampleOfProjectile(page2));
    }
}, [page,page2,selectedPage,dispatch])

  const pageSelected1=()=>{
    setSelectedPage("concepts");
    navigate('?&activeTab=concepts',{replace:true}); 
  }
  const pageSelected2=()=>{
    setSelectedPage("examples");
    navigate('?&activeTab=examples',{replace:true});
  }
  const pageSelected3=()=>{
    setSelectedPage("exercises");
    navigate('?&activeTab=exercises',{replace:true}); 
  }

  
  return (
    <div>
      <Navbar2 height={height} subject={subject} concepts={concepts} exercises={exercises}
        pageSelected1={pageSelected1}  pageSelected2={pageSelected2}  pageSelected3={pageSelected3} examples={examples} selectedPage={selectedPage} setSidebarShow={setSidebarShow} sidebarShow={sidebarShow}  />
    
     <div>
        {
          selectedPage==='concepts'?( <div><Tab1 page={page} setPage={setPage} selectedPage={selectedPage}  /></div>):selectedPage==='examples'?( <div><Tab2 page2={page2} setPage2={setPage2}  selectedPage={selectedPage} /></div>):selectedPage==='exercises'?( <div><Tab3  setSidebarShow={setSidebarShow} sidebarShow={sidebarShow} selectedPage={selectedPage} /></div>):'null'
        }
    </div>
    </div>
  )
}

export default Projectile
