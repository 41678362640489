import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Loading from '../../../components/Loading'
import Pagination from '../../../components/Pagination'


const Tab2 = ({setPage2,selectedPage,page2}) => {
  const {data,loading} = useSelector(state=>state.examplesOfNewton);
  const [revise,setRevise] = useState(false);
  
 //console.log(data);
  // const revisedConcept=(id)=>{
  //   if(!revise){
  //      dispatch(reviseConceptOfDerevative(id));
  //      setRevise(true);
      
  //   }
   
   
  // }
  return (
    <div className='relative' >
     <main className=' mt-[180px]  flex justify-center items-center text-gray-900'>
     
     <div className='ml-1 w-[1050px]'>
   {
   loading?<Loading/>:
  
   data?.results?.map(x=>(
    <div className='definition' key={x._id} >
              <div className='flex'>
                 <div>{x.Qno}.</div>
                 <div className='defini ml-2' style={{color:"#005DFF",fontSize:'1.2rem'}}> {x.Question}</div>
                
              </div>
            
             {/* {x.Question2&&<div className='defini' style={{color:"#005DFF",fontSize:'1.2rem'}}>  <InlineMath math={x.Question2} options={{strict:false}} /></div>}
              */}
               <div><img src={x.image} width='200px' alt="" /></div>
               <div className='defini' style={{fontSize:'1.2rem'}} >{x.solution}</div>
               <div><img src={x.image1} width='200px' alt="" /></div>
                {
                 x.solution2 &&  <div className='defini' style={{fontSize:'1.2rem'}} >{x.solution2}</div>
               }
              
               
           </div>
               ))
         }
         {!revise &&
         <div className={`mb-3 mt-[18px] ${selectedPage==="examples"?'fixed top-[115px] ss:top-[125px] sm:top-[130px] right-2 items-center left-25 flex justify-center':''} z-40`}>
          <Pagination setPage2={setPage2} page2={page2} selectedPage={selectedPage} exampletData={data}  />
         </div>
         }
     </div>
   
</main>

{/* <div>
  { revise && <div><ReviseTab setRevise={setRevise} selectedPage={selectedPage} /></div> }
</div> */}

    </div>
  )
}

export default Tab2


// {/* <div className='definition relative rounded-md  z-30 cursor-pointer' key={x._id} >
// <div className='' >
//      <h2 style={{color:"#005DFF"}}> {x.heading}</h2>
//       <div className='flex items-center mb-2 justify-between' >
//        <div className='flex items-center mb-2 '>
//        <div className='mr-3'>{x.Qno}.</div>
//       <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' style={{color:"#005DFF"}} >  <InlineMath displayMode={true} options={{strict: false}} math={x.question} errorColor={'#cc0000'} /></div>
//        </div>
//        <div className='mr-20'>{x.image&&<img src={x.image} alt="nothing" width='200px' />}</div>
//        </div>
//          <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >
//             <InlineMath displayMode={true} options={{strict: false}} math={x.solution} errorColor={'#cc0000'} />
//          </div>
//        </div>
//        {/* <div className='absolute right-2 bottom-2 bg-gray2 text-white py-2 px-4 rounded flex' onClick={()=>revisedConcept(x.conceptId)} >
//              <GoBackIcon/>
//        </div> */}
//        </div> */}
