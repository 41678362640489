import React from 'react'
import { InlineMath } from 'react-katex'
import { Link } from 'react-router-dom'

const Integration = () => {
  
  
  return (
    <section id='home' className='block ss:flex mx-3 gap-2 items-center mt-6 xs:mt-0 truncate h-[90vh] justify-center' >
  

     <Link to='definite-integrals/?&activeTab=concepts' >
       <div className='bg-black p-4 font-serif rounded mt-2 tracking-wider text-white flex items-center justify-center text-[17px]' >Definite Integrals <InlineMath displayMode={true} options={{strict: false}} math={`\\int_{a}^{b}f(x)dx`} errorColor={'#cc0000'} /></div>
     
     </Link>
     <Link to='indefinite-integrals/?&activeTab=concepts'>
      <div className='bg-blue rounded p-4 font-serif tracking-wider mt-2 xs:mt-0 text-white flex items-center  justify-center text-[17px]' >Indefinite Integrals {""} ∫f(x)dx</div>
      
     </Link>
   </section>
  )
}

export default Integration
