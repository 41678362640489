import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Landing from './scenes/Landing';
import Navbar from './scenes/NavbarForUi';
import MySkills from './scenes/MySkills';
import Projects from './scenes/Projects';
import AccountCreation from './account/AccountCreation';
import Login from './account/Login';
import { FooterForLanding } from './scenes/Footer';

const Front = ({ accountToggle,setAccountToggle,setLoginAfterVerify }) => {
  const userInfoData = localStorage.getItem("userInfo");
  const getOtp = localStorage.getItem('userGetOtp');
  const [menuNavbar,setMenuNavbar]=useState(true);
  const [selectedPage, setSelectedPage] = useState("learn");
  const [isTopOfPage, setIsTopOfPage] = useState(true);
 const confirmOtpInfo = localStorage.getItem('confirmOtpInfo')
  
  useEffect(() => {
    const handleScroll=()=>{
      if(window.scrollY===0){
        setIsTopOfPage(true);
      } 
      else setIsTopOfPage(false)
    }
    window.addEventListener('scroll',()=>{
      if(window.scrollY<=710){
         setIsTopOfPage(true);
      }
      else setIsTopOfPage(false);
      
    })
    return window.removeEventListener('scroll',handleScroll)
  }, [])

const history = useNavigate()
  useEffect(() => {
    if(userInfoData){
      history('/learn');
      
    }
    // if(getOtp){
    //   history('/checkotp');
    // }
    // if(confirmOtpInfo){
    //   history('/onBoarding')
    // }
  }, [userInfoData,getOtp,confirmOtpInfo,history])
if(accountToggle.signup){
  return (
    <div><AccountCreation setAccountToggle={setAccountToggle} accountToggle={accountToggle} /></div>
  )
}
if(accountToggle.sign){
  return (
    <div><Login setLoginAfterVerify={setLoginAfterVerify} setAccountToggle={setAccountToggle} accountToggle={accountToggle} /></div>
  )
}
return (
  <div className="">
  
  <div className='z-50 relative'>
   <Navbar menuNavbar={menuNavbar} isTopOfPage={isTopOfPage} setAccountToggle={setAccountToggle} accountToggle={accountToggle} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
  
   </div>
    <div className='mx-auto md:h-full' >
     {/* { isAbovemediumScreen &&(
        <DotGroup selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      )} */}
     
      <Landing />
  
     
   </div> 
  
  
     <div className='-z-10 w-5/6 mx-auto md:h-full' >
     
      <MySkills/>
     </div>
    
     <div className='w-5/6 mx-auto' >
      <Projects/>
     
     </div>
 
   <FooterForLanding/>
   <div className='gradient' ></div>

   
  </div>
 
);
     }

export default Front