import React from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import Loading from '../../../components/Loading';
import useMediaQuery from '../../../components/hook/UseMediaQuery';
import { formatTime } from '../../../lib/Utils';

const Tab1 = ({data,loading }) => {
 
  const mobile = useMediaQuery('(min-width: 440px)');
  
  return (
    <div className='flex items-center justify-center bg-slate-100' >
    {loading ?<Loading/> : 
    <div className='w-full xs:w-3/4 bg-white flex justify-center flex-col items-center'>
     <div className='border-b-[1px] flex items-center justify-around text-[18px] w-full my-4'>
        <div>
          <p className='text-[15px]'>Score</p>
           <div className='flex'>
             <p className='text-3xl xs:text-5xl mr-2'>{data?.results&&(data?.results[0]?.score * 4 - (data?.results[0]?.attemptedQuestion - data?.results[0]?.score))}</p>
             <p className='text-[14px] text-grey'>{(data&&data?.results&&(data?.results[0]?.score * 4 - (data?.results[0]?.attemptedQuestion - data?.results[0]?.score))<=0?0:((data?.results[0]?.score *4 - (data?.results[0]?.attemptedQuestion - data?.results[0]?.score))/(data?.results[0]?.totalQuestions*4)* 100).toFixed(2))}%</p>
           
           </div>
          <p>Out of {data?.results&&data?.results[0]?.totalQuestions * 4}</p>
        
        </div>
        <div>
          <p className='text-[15px]'>Time Taken</p>
           <div className='flex'>
             <p className='text-3xl xs:text-5xl mr-2'>{(data&&data?.results&&data?.results?.length!==0&&(formatTime(data?.results[0]?.timeTaken)))} <span className='text-[14px] text-grey' >Hrs</span> </p>
            
           </div>
          <p>Out of 1:00:00 hrs</p>
        </div>
     </div>
     <p>Total Questions : {data&&data?.results&&data?.results?.length!==0&&data?.results[0]?.totalQuestions}</p>
     
     <div className='overflow-x-auto whitespace-nowrap w-[280px] vvs:w-[400px]'>  
   <div className=' w-[360px]' >
     <PieChart
  series={[
    {
      data: [
       
        { id: '0', value:data?.results&&data?.results[0]?.score , label: `Correct:${data?.results&&data?.results[0]?.score}` },
        { id: '1', value: data?.results&&(data?.results[0]?.totalQuestions-data?.results[0]?.attemptedQuestion), label: `Left:${data?.results&&(data?.results[0]?.totalQuestions-data?.results[0]?.attemptedQuestion)}`},
        { id: '2', value: data?.results&&data?.results[0]?.attemptedQuestion-data?.results[0]?.score, label: `Incorrect:${data?.results&&data?.results[0]?.attemptedQuestion-data?.results[0]?.score}`,color:'rgb(116, 26, 23)'},
       
      ],
      innerRadius: mobile?30:22,
      outerRadius: mobile?100:80,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: 0,
      endAngle: 360,
      cx: 150,
      cy: 150,
    },
  ]}
  width={mobile?400:370}
  height={300}
/>
</div>  

</div>
      <div className='overflow-x-auto whitespace-nowrap w-[280px] vvs:w-[400px]'>  
   <div className=' w-[370px]' >
      <BarChart
      xAxis={[
        {
          id: 'barCategories',
          data: ['Correct', 'Incorrect'],
          scaleType: 'band',
        },
      ]}
      series={[
        {
          data: [data&&data?.results&&(data?.results[0]?.score),(data?.results&&data?.results[0]?.attemptedQuestion-data?.results[0]?.score)],
        },
      ]}
      width={mobile?440:380}
      height={300}

    /> 
    </div>  

</div> 
<div className='overflow-x-auto whitespace-nowrap w-[280px] vvs:w-[400px]'>  
   <div className=' w-[370px]' >
     <PieChart
  series={[
    {
      data: [
       
        { id: '0', value: data?.results&&data?.results[0]?.score, label: `${data?.results&&data?.results[0]?.score}/${data?.results&&data?.results[0]?.totalQuestions}`,color:'rgba(0,116,0)' },
        { id: '1', value: data?.results&&(data?.results[0]?.totalQuestions-data?.results[0]?.score),color:'white'},
       
      ],
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: 0,
      endAngle: 360,
      cx: 150,
      cy: 150,
    },
  ]}
  width={mobile?400:370}
  height={300}
/>
</div>  

</div> 
</div>  
    }
</div>
  )
}

export default Tab1


//  data&&data?.results&&data?.results[0]?.score,data?.results&&data?.results[0]?.attemptedQuestion-data?.results[0]?.score


//  style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}