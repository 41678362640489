export const conceptsOfFunctionRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'FUNCTION_CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'FUNCTION_CONCEPT_SUCCESS':
            return {...state,data:action.payload,loading:false}
        case 'FUNCTION_CONCEPT_FAIL':
             return {...state,loading:false}
        default:
            return state
    }
}

export const examplesOfFunctionRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'FUNCTION_EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'FUNCTION_EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'FUNCTION_EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const reviseConceptsOfFunctionRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'FUNCTION_REVISE_REQUEST':
            return {...state,loading:true}
        case 'FUNCTION_REVISE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'FUNCTION_REVISE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//more

export const moreQueFunctionRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_FUNCTION_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_FUNCTION_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_FUNCTION_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsFunctionRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_ANS_FUNCTION_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_FUNCTION_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_FUNCTION_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}