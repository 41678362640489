import { Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { InlineMath } from 'react-katex';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../components/Loading';
import { PaginateForTestSeries } from '../../../PaginateTest';
import { responseAnswerOfTestSeriesAction, responseAnswerOfTestSeriesActionAllData } from '../../../../redux/actions/Test/Physics/30Tests/ProjectileTestSeriesAction';

const Level1 = ({ page,setPage,page2,setPage2,page3,setPage3,selectedPage }) => {
  
  const allResponseData = useSelector(state=>state.ResponseAllDataTestSeriesRed);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const [responseOption,setResponseOption] = useState({response:null,id:null})
  const {data,loading } = useSelector(state=>state.physicsTestSeriesRed);
  const responseTest = useSelector(state=>state.ResponseTestSeriesRed);
  const dispatch = useDispatch()
  const optionSelect=(i,id)=>{
    
    if(squaredNum && (data?.results[0]?._id !== squaredNum[0]?.questionId ) &&(squaredNum && squaredNum[0]?.creator!==userData.id)){
      setResponseOption({response:i,id:id});
    }
 }
  function handleSubmit(e){
    e.preventDefault();
       dispatch(responseAnswerOfTestSeriesAction(responseOption.response,responseOption.id));
    }

 useEffect(()=>{
    dispatch(responseAnswerOfTestSeriesActionAllData());
  },[ responseTest,responseOption.response,dispatch]);


const squaredNum = useMemo(()=> {
    if(data?.results){
        return allResponseData?.data?.gettingResponses?.filter(x=>x.questionId === (data?.results[0]._id));
  }
 
}, [allResponseData,data]);


  return (
    <div>
     
      <form onSubmit={handleSubmit}>
<div className='h-screen overflow-auto' >
 <div className=' absolute bottom-0 right-2 mb-3 mt-3'>
    {
     <div className='relative z-40'> <PaginateForTestSeries page={page} setPage = {setPage} page2={page2} setPage2 = {setPage2} page3={page3} setPage3 = {setPage3} data = {data} selectedPage = {selectedPage} /></div>
    }

</div>
<main className='md:ml-[400px]  mt-[100px] flex justify-center items-center text-gray-900'>
<div className='ml-1 w-[900px]'>
{ loading?<Loading />:
data&&data?.results?.map(x=>(
<div key={x._id}>
 <div className='definition' >
    <div className='' >
          <div className='flex' >
           <div className='mr-3'>{x.questionNo}.</div>
           <div>
               <div style={{color:"#005DFF"}}>  {x.question}</div>
              { x.questionLatex && <div className='text-[9px] ml-5 vvs:text-[11px] xs:text-[13px] sm:text-[16px] -z-10 ' style={{color:"#005DFF"}} > <InlineMath displayMode={true} options={{strict: false}} math={x.questionLatex} errorColor={'#cc0000'} /></div> }
           </div>
           </div>
           <div><img className='w-[200px]' src={x.image} alt="" /></div>
        </div>    
     </div> 
    {
     
      x.options.length!== 0 ?(   x.options.map((opt,i)=>(
               <div key={i} className='relative' >
               <div className={`flex z-10 relative definition items-center cursor-pointer rounded ${squaredNum && Number(squaredNum[0]?.correctAnswer)===i && squaredNum[0]?.creator===userData.id&& 'success'}  ${(squaredNum&&squaredNum[0]?.response!==x.correctanswer)&&squaredNum&&squaredNum[0]?.response===i&& squaredNum[0]?.creator===userData.id && "error"} ${(responseOption.response===i && responseOption.id===x._id  ) &&'selecting'}`} onClick={()=>optionSelect(i,x._id)} >
               <div className={`absolute right-4 -top-3 ${squaredNum && Number(squaredNum[0]?.correctAnswer)===i && squaredNum[0]?.creator===userData.id&& 'text-success'} ${ squaredNum&&squaredNum[0]?.response!==x.correctanswer&& squaredNum&&squaredNum[0]?.creator===userData.id &&" text-error"}`}>{(squaredNum && Number(squaredNum[0]?.correctAnswer)===i && squaredNum[0]?.creator===userData.id&&"Correct-Answer")||(squaredNum&&squaredNum[0]?.creator===userData.id&&squaredNum&&squaredNum[0]?.response===i &&   x.correctanswer!==i && "Wrong Answer")}</div>
               <div className='mr-5 text-[19px] option ' >{String.fromCharCode(i+97)}</div>
               <div className='p-2 text-[13px] ss:text-[16px]'> {opt}</div>
             
               <div className={`w-[120px] h-4  absolute right-3 -top-2 -z-40 ${squaredNum && Number(squaredNum[0]?.correctAnswer)===i && squaredNum[0]?.creator===userData.id&& 'bg-white'} ${ squaredNum&&squaredNum[0]?.response!==x.correctanswer&& squaredNum&&squaredNum[0]?.creator===userData.id &&" bg-white"}`} ></div>
              
       </div>
         </div>
               
                   
  ))):(
    x.optionsLatex.map((opt,i)=>(
               <div key={i} className='relative' >
               <div className={`flex z-10 relative definition items-center cursor-pointer rounded ${squaredNum && Number(squaredNum[0]?.correctAnswer)===i && squaredNum[0]?.creator===userData.id&& 'success'}  ${(squaredNum&&squaredNum[0]?.response!==x.correctanswer)&&squaredNum&&squaredNum[0]?.response===i&& squaredNum[0]?.creator===userData.id && "error"} ${(responseOption.response===i && responseOption.id===x._id  ) &&'selecting'}`} onClick={()=>optionSelect(i,x._id)} >
               <div className={`absolute right-4 -top-3 ${squaredNum && Number(squaredNum[0]?.correctAnswer)===i && squaredNum[0]?.creator===userData.id&& 'text-success'} ${ squaredNum&&squaredNum[0]?.response!==x.correctanswer&& squaredNum&&squaredNum[0]?.creator===userData.id &&" text-error"}`}>{(squaredNum && Number(squaredNum[0]?.correctAnswer)===i && squaredNum[0]?.creator===userData.id&&"Correct-Answer")||(squaredNum&&squaredNum[0]?.creator===userData.id&&squaredNum&&squaredNum[0]?.response===i &&   x.correctanswer!==i && "Wrong Answer")}</div>
               <div className='mr-5 text-[19px] option ' >{String.fromCharCode(i+97)}</div>
               <div className='p-2 text-[13px] ss:text-[16px]'> <InlineMath displayMode={true} options={{strict: false}} math={opt} errorColor={'#cc0000'} /></div>
               <div className={`w-[120px] h-4  absolute right-3 -top-2 -z-40 ${squaredNum && Number(squaredNum[0]?.correctAnswer)===i && squaredNum[0]?.creator===userData.id&& 'bg-white'} ${ squaredNum&&squaredNum[0]?.response!==x.correctanswer&& squaredNum&&squaredNum[0]?.creator===userData.id &&" bg-white"}`} ></div>
              
      
       </div>
         </div>
    ))
  )}
  </div>

))}

  </div>
  </main>
 <div className='absolute left-3 md:ml-[400px] bottom-3' >
 {
    (data)&& <Button disabled={(squaredNum&&(data?.results[0]?._id=== squaredNum[0]?.questionId ) &&(squaredNum&& squaredNum[0]?.creator===userData.id)) ||(data?.results&&data?.results[0]._id!==responseOption.id) ?true:false} variant="contained" type='submit' color='success' >Submit
    <span>{responseTest?.loading&&<div className='button--loading py-[12px]'></div> }</span> 
    </Button>
 }
</div>
</div>
 </form> 
 
    </div>
  )
}

export default Level1
