import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {deleteProfileAction, getProfileAction, uploadProfileAction} from '../../redux/actions/downloads/downLoadsAction';
import { CameraIcons,UpdateIcons,DeleteIcons ,CloseIcons } from  '../../icons/Icon';

        
const FileInput = ({ setMessage ,message }) => {
  const [visible,setVisible] = useState(false);
  const { data } = useSelector(state=>state.userProfileRed);
  const uploadData = useSelector(state=>state.uploadProfileRed);
  const deletingData = useSelector(state=>state.deleteProfileRed);
  const [profileShow,setProfileShow] = useState(false);
  const [upload,setUpload] = useState('');
  const [deleteFile,setDeleteFile] = useState('');
  const types = ['image/jpeg','image/png','image/jpg']
  const dispatch = useDispatch();
  
  const handleChange = (e)=>{

    e.preventDefault();
     
    if(!types.includes(e.target.files[0].type)){
      setTimeout(()=>{
        setMessage({...message,showError:false})
      },5000)
      return setMessage({showError:true,errorMessage:"Only .JPG , .PNG , .JPEG image formate is allowed"})
    } 
    if(e.target.files[0].size<=3145728 ){
      setProfileShow(true)
      dispatch(uploadProfileAction(data.filename,e.target.files[0],setUpload));
      
      setTimeout(()=>{
        setProfileShow(false);
       // uploadData.data.imgUrl = null;
       
      },5000)
    }else{
      setMessage({showError:true,errorMessage:"Image size must be less than 3 MB"})
      setTimeout(()=>{
        setMessage({...message,showError:false})
      },5000)
    }
      
  }
  useEffect(()=>{
      dispatch(getProfileAction())
  },[upload,data,dispatch,deletingData]);
 //  profileShow?uploadData?.data?.imgUrl:
 
  return (
    <div>
       
       <div className='relative w-[100px] h-[100px] ss:w-[150px] ss:h-[150px] object-fit justify-center items-center flex border-2 border-sky-500 rounded-full'>
       <img src =  {data?.imgUrl} alt='No profile'
       className='w-full h-full rounded-full object-cover'
       /> 
        
      {uploadData?.loading && <div className='absolute button--loading2 border-2 '></div> } 
       </div>
            {
              !visible && <div onClick={()=>setVisible(true)} > <CameraIcons /></div>
            }
          {
            visible &&  (
              <div className='relative shadow-md w-[120px] ss:w-[150px] flex gap-4 justify-center py-3'>
                <input type="file" id="fileInput" style={{display:"none"}} onChange={handleChange} />
                <label htmlFor="fileInput"  style={{cursor: "pointer"}}>
                <div><UpdateIcons /></div>
               </label>
               <div className={`${(!data.imgUrl ) &&'pointer-events-none' } `} onClick={()=>{
                dispatch(deleteProfileAction(data.filename,setDeleteFile,data?._id));
                data.imgUrl = null;
               // uploadData.data.imgUrl = null;
               }} ><DeleteIcons/></div>
               <div onClick={()=>setVisible(false)} className='absolute top-0 right-0'><CloseIcons /></div>
        </div>
            )
          }
    </div>
  )
}

export default FileInput
