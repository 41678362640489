import React from 'react'

// const Loading = () => {
//   return (
// <div className=' min-h-[82vh] flex items-center  justify-center z-50' >
// <div className="spinner-border text-primary"  role="status">
//   <span className="visually-hidden"></span>
// </div>
// </div>
//   )
// }

import CircularProgress from '@mui/material/CircularProgress';


export const Loading = () => {
  return (
    <div className='min-h-[82vh] flex items-center  justify-center'>
      <CircularProgress />
    </div>
  );
}

export default Loading

export const LoadingContact = () => {
  return (
<div className='z-50' >
<div className="spinner-border text-success"  role="status">
  <span className="visually-hidden"></span>
</div>
</div>
  )
}