import React, { useEffect } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'
import { signout } from '../../../redux/actions/Auth/AuthAction';

const Tab1 = ({setPage,page,selectedPage}) => {
  const {data,loading} = useSelector(state=>state.conceptsOfFunctionRed);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Logout=()=>{
    dispatch(signout(userData?.phoneNumber,navigate));
   }
  if(data.message==='jwt expired'){
       
    return (
      <div className='flex items-center justify-center min-h-screen'>
      <div className='relative  mx-auto my-auto bg-black text-white p-2 max-w-[500px]'>Your Session has expired <span className=' cursor-pointer text-blue' onClick={Logout}  >Click Me</span> to Log In again</div>
      </div>
    )
}
  return (
    <div>
        <main className='mr-2 mt-[150px] flex justify-center items-center text-gray-900'>
  
  <div className='ml-1 w-[1050px]'>
  {
   loading?<Loading/>:
   data?.results?.map(x=>(
    <div className='definition rounded-md mr-1' key={x._id} >
              <div className='d-flex'>
                 <div className='text-[19px] mr-2 ' >{x.conceptNo}</div>
                 <h2 className='text-2xl flex items-center' style={{color:"#005DFF"}}> {x.heading}</h2>
              </div>
            
             { x.definition1 && <div style={{marginTop:"3px"}} className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' > {x.definition1}</div>}
                
                <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' style={{display:"flex",alignItems:"flex-start",marginTop:"6px"}}>  <InlineMath options={{strict:false}} math={x.concept} /></div>
                <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >{x.simpleConcept}</div>
                <div><img src={x.image} alt="" width='300px' /></div>
               

                <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>{x.simpleConcept1}</div>
                {x.concept1 &&<div>
                  
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' ><InlineMath options={{strict:false}} math={x.concept1}/></div>
               
                 { x.image1&&<div><img src={x.image1} alt="" className='w-[350px]' /></div>}
                  
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >{x.simpleConcept2}</div>
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' ><InlineMath options={{strict:false}} math={x.concept2}/></div>
                
                  <div><img src={x.image2} alt=""  
                  className='w-[350px] md:w-[550px]' /></div>
                </div> 
                }
               
                {
                 x.examples &&  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' style={{display:"flex",alignItems:"flex-start",marginTop:"10px"}}>  <InlineMath math={x.examples} options={{strict:false}} /></div>
              
               }

               <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' style={{display:"flex",alignItems:"flex-start",marginTop:"10px"}} > {x.definition2}</div>
               <div><img src={x.image3} alt="" width='400px' /></div>
         
           </div>
          
              )) }
        </div>
   </main>
       <div className='my-3'>
         <Pagination selectedPage={selectedPage} setPage={setPage} page={page} conceptData={data} />
         </div>
    </div>
  )
}

export default Tab1