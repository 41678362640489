import axios from 'axios';

const url = 'https://whiteboardblackpen-tge6.onrender.com/test'
//const url = 'http://localhost:5000/test'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/test`


export const physicsTest1Action=(page)=>async(dispatch)=>{
    dispatch({type:"TEST1_REQUEST"});
    try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/phy?page=${page}&limit=${1}`,{
        headers:{
            Authorization : `Bearer ${userInfo.token}`,
            'Content-Type': "application/json",
        }
     });
    
     dispatch({type:"TEST1_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"TEST1_FAIL"});
    }
  }
export const responseAnswerOfTest1Action=(response,id)=>async(dispatch)=>{
    dispatch({type:"TEST1_RESPONSE_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.post(`${url}/phy/response`,{response,id},{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
  
     dispatch({type:"TEST1_RESPONSE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"TEST1_RESPONSE_FAIL"});
    }
  }
   // all data for response
  export const responseAnswerOfTest1ActionAllData=(questionId)=>async(dispatch)=>{
    dispatch({type:"TEST1_RESPONSE_ALLDATA_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/phy/response/alldata`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
   
     dispatch({type:"TEST1_RESPONSE_ALLDATA_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"TEST1_RESPONSE_ALLDATA_FAIL"});
    }
  }

  // sidebar


  export const physicsTest1SidebarAction=(page)=>async(dispatch)=>{
    dispatch({type:"TEST1_SIDEBAR_REQUEST"});
    try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/phy/sidebar?page=${page}&limit=${5}`,{
        headers:{
            Authorization : `Bearer ${userInfo.token}`,
            'Content-Type': "application/json",
        }
     });
  
     dispatch({type:"TEST1_SIDEBAR_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"TEST1_SIDEBAR_FAIL"});
    }
  }