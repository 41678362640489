

export const contactUsRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'CONTACTUS_REQUEST':
            return {...state,loading:true}
        case 'CONTACTUS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'CONTACTUS_FAIL':
           //return {...state,loading:false};
           return { loading:false,error:action.payload }
        default:
            return state
    }
}

export const getAllMessagesContactUsRed = (state={data:[]},action)=>{
    switch(action.type){
        case 'GET_ALL_CONTACTUS_REQUEST':
            return {...state,loading:true}
        case 'GET_ALL_CONTACTUS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'GET_ALL_CONTACTUS_FAIL':
           //return {...state,loading:false};
           return { loading:false,error:action.payload }
        default:
            return state
    }
}