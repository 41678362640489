import  axios  from "axios";
//
 let url = 'https://whiteboardblackpen-tge6.onrender.com/projectile';

//let url = 'http://localhost:8000/projectile'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/projectile`


const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });
export const conceptOfProjectile=(page)=>async(dispatch)=>{
    dispatch({type:"PROJECTILE_CONCEPT_REQUEST"});
    try {
     const {data} = await API.get(`/concept?page=${page}&limit=${3}`);
     dispatch({type:"PROJECTILE_CONCEPT_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"PROJECTILE_CONCEPT_FAIL"});
    }
  }
  
  // examples
  
  export const exampleOfProjectile=(page2)=>async(dispatch)=>{
    dispatch({type:"PROJECTILE_EXAMPLE_REQUEST"});
    try {
     const {data} = await API.get(`/example?page=${page2}&limit=${2}`);
     dispatch({type:"PROJECTILE_EXAMPLE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"PROJECTILE_EXAMPLE_FAIL"});
    }
  }


  export const reviseConceptOfProjectile=(id)=>async(dispatch)=>{
    dispatch({type:"PROJECTILE_REVISE_REQUEST"});
   
    try {
     const {data} = await API.post(`/revise`,{ id });
     dispatch({type:"PROJECTILE_REVISE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"PROJECTILE_REVISE_FAIL"});
    }
  }

  //more

  export const moreQuestionOfProjectileAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_QUE_PROJECTILE_REQUEST"});
   
    try {
     const {data} = await API.get(`/more/question?page=${page}&limit=${12}`);
     dispatch({type:"MORE_QUE_PROJECTILE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_QUE_PROJECTILE_FAIL"});
    }
  }
  
  export const moreAnswerOfProjectileAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_ANS_PROJECTILE_REQUEST"});
   
    try {
     const {data} = await API.get(`/more/answer?page=${page}&limit=${12}`);
     dispatch({type:"MORE_ANS_PROJECTILE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_ANS_PROJECTILE_FAIL"});
    }
  }