import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar2 from '../../components/Navbar2';
import Tab1 from './tabs/Tab1';
import Tab2 from './tabs/Tab2';
import Tab3 from './tabs/Tab3';
import { allChaptersConceptAction, allChaptersExamplesAction, moreQuestionOfAllChaptersAction } from '../../redux/actions/physicsActions/AllChapters/AllChaptersActions';
//import { conceptOfNewton, exampleOfNewton } from '../../redux/actions/physicsActions/NLM/NewtonAction';


const height = '80px';
const concepts = 'Concepts';
const exercises = 'Exercises';
const examples = 'Examples';
const CircularMotion = () => {
    const [selectedPage,setSelectedPage] = useState('concepts');
    const [sidebarShow,setSidebarShow] = useState(true);
    const [subject,setSubject] = useState('')
    const [page,setPage] = useState(1);
    const [page2,setPage2] = useState(1);
    const [page3,setPage3] = useState(1);
    const [path,setPath] = useState('');
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const location = useLocation();
   useEffect(() => {
   const pat = location.pathname.split('/');
     setPath(pat[3]);
     setSubject(pat[3].split('-').join(' ').charAt(0).toUpperCase()+ pat[3].split('-').join(' ').slice(1));
  }, [selectedPage,location]);
 
useEffect(() => {
  if(selectedPage === 'concepts' && path === `${path}`){
     dispatch(allChaptersConceptAction(page,1,`${path}`));
   
  }else if(selectedPage === 'examples' && path === `${path}`){
      dispatch(allChaptersExamplesAction(page2,2,`${path}`));
     
  }
  

}, [page,page2,selectedPage,dispatch,path])

  const pageSelected1=()=>{
    setSelectedPage("concepts");
    navigate('?&activeTab=concepts',{replace:true}) 
  }
  const pageSelected2=()=>{
    setSelectedPage("examples");
    navigate('?&activeTab=examples',{replace:true})
  }
  const pageSelected3=()=>{
    setSelectedPage("exercises");
    navigate('?&activeTab=exercises',{replace:true}); 
  }
  return (
    <div>
      <Navbar2 height={height} subject={subject} concepts={concepts} exercises={exercises}
        pageSelected1={pageSelected1}  pageSelected2={pageSelected2}  pageSelected3={pageSelected3} examples={examples} selectedPage={selectedPage} setSidebarShow={setSidebarShow} sidebarShow={sidebarShow}  />
    
   <div>
        {
          selectedPage==='concepts'?( <div><Tab1 page={page} setPage={setPage} selectedPage={selectedPage}  /></div>):selectedPage==='examples'?( <div><Tab2 page2={page2} setPage2={setPage2}  selectedPage={selectedPage} /></div>):selectedPage==='exercises'?( <div><Tab3  setSidebarShow={setSidebarShow} sidebarShow={sidebarShow} selectedPage={selectedPage} page2={page2} setPage3 = {setPage3} path = {path}  /></div>):'null'
        }
    </div>
    </div>
  )
}

export default CircularMotion
