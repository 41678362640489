import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { AdminIcon, CloseIcons, CreatorIcon, DownLoadsIcons, LogOutIcon, ProfileIcon, QuizesIcon, ReadingIcon,PsychologyIcons } from '../icons/Icon';
import { signout } from '../redux/actions/Auth/AuthAction'
import useMediaQuery from './hook/UseMediaQuery';


const Sidebar = ({ setNavbarMenu,navbarMenu }) => {
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const isAbovemediumScreen = useMediaQuery('(min-width: 1060px)');
  //const isUserDeleted = useSelector(state=>state.loggedInUsrRemoveRed)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(()=>{
   
   if(isAbovemediumScreen){
      return setNavbarMenu(true)
   }
   
  },[isAbovemediumScreen,setNavbarMenu])
  const Logout=()=>{
   dispatch(signout(userData?.phoneNumber,navigate));
    
  }
  return (
   <section className={`bg-deep-blue h-screen py-5 w-[300px] z-[100] p-10 fixed top-0 ${navbarMenu?"left-[0]":"left-[-300px]"} md:left-[0] ease-in-out duration-300`} >
     <div className='text-white ' >
       <Link to="/ask-to-sahil" ><div className='hover:text-white m-3 cursor-pointer flex gap-2 items-center hover:bg-grey p-2 rounded duration-300'><ReadingIcon /><span>Ask</span></div></Link>
       <Link to="/profile" ><div className='hover:text-white m-3 cursor-pointer flex gap-2 items-center hover:bg-grey p-2 rounded duration-300' ><ProfileIcon /><span>Profile</span></div></Link>
       <Link to="/aboutcreator" ><div className='hover:text-white m-3 cursor-pointer flex gap-2 items-center hover:bg-grey p-2 rounded duration-300' ><CreatorIcon /><span>About Creator</span></div></Link>
       <Link to="/test-series"><div className='hover:text-white m-3 cursor-pointer flex gap-2 items-center hover:bg-grey p-2 rounded duration-300'  ><PsychologyIcons /><span>Test Series</span></div></Link>
       <Link to="/test" ><div className='hover:text-white m-3 cursor-pointer flex gap-2 items-center hover:bg-grey p-2 rounded duration-300' ><QuizesIcon /><span>Test</span></div></Link>
       <Link to="/downloads" ><div className='hover:text-white m-3 cursor-pointer flex gap-2 items-center hover:bg-grey p-2 rounded duration-300' ><DownLoadsIcons /><span>Downloads</span></div></Link>
       <div className='hover:text-white m-3 cursor-pointer flex gap-2 items-center hover:bg-grey p-2 rounded duration-300' onClick={Logout} ><LogOutIcon /> <span>Logout</span> </div>
     {(userData?.phoneNumber===process.env.REACT_APP_PHONENUMBER && userData?.name=== process.env.REACT_APP_ADMIN_SECUIRITY) &&
      <Link to="/admin-catagory" ><div className='hover:text-white m-3 cursor-pointer flex gap-2 items-center hover:bg-grey p-2 rounded duration-300' ><AdminIcon /> <span>Admin</span> </div></Link>} 
    
     </div>
     { !isAbovemediumScreen&& <div className='absolute top-2 right-2 text-white hover:bg-grey p-1 hover:rotate-180 duration-200 rounded-full' onClick={()=>setNavbarMenu(false)} ><CloseIcons/></div>}
   </section>
  )
}

export default Sidebar
