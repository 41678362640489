import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../scenes/Footer';
import Input from '../scenes/inputForContact';
//import Navbar from '../scenes/NavbarForUi';
import { confirmOtpLoginFinalActions, loginActions } from '../../../redux/actions/Auth/AuthAction';

const schema =yup.object({
    confirmOtp:yup
    .string()
    .required("Enter 6 digits OTP") 
    .matches(
      /^[0-9]{6}$/,"OTP is not valid"
    ),
    
  })
const ConfirmOtpFinalLogin = ({setAccountToggle,accountToggle}) => {
    const [show,setShow] = useState(false);
    const [show1,setShow1] = useState(false);
    const [counter, setCounter] = useState(60);
    const userData = localStorage.getItem('userInfo');
    const resendOtp  = useSelector(state=>state.loginRed);
    const confirmOtpInfo = JSON.parse(localStorage.getItem('confirmOtpInfo'))
    const { data,loading,error }  = useSelector(state=>state.confirmLoginFinalLoginRed);
    const { handleSubmit ,
          register,
          formState:{ errors }
        } =  useForm({
    resolver:yupResolver(schema)
  });
  
    const dispatch = useDispatch()
    const history = useNavigate()
    const formSubmit =(inputOtp)=>{
       dispatch(confirmOtpLoginFinalActions({...inputOtp,phoneNumber:confirmOtpInfo?.phoneNumber}));
       setShow(true);
       setTimeout(()=>{
       setShow(false);
       
       },[3000]);
  
    };

    useEffect(() => {
        if(userData){
          setAccountToggle({...accountToggle,sign:false})
          history('/learn');
         
        }
        if(!confirmOtpInfo){
          history('/');
        }
      }, [userData,confirmOtpInfo,history]);
  
        useEffect(() => {
        if(counter>0){
         setTimeout(() => setCounter(counter - 1), 1000);
         
        }
      },[counter]) 
   
  return (
    <div>
       <div className='relative min-h-[100vh]'>
      
       {/* <div className='fixed top-0' > <Navbar  /></div> */}
    
      <section id="contact" className="relative top-20 otpGenerate bg-white border-[0.6px] border-gray-400 max-w-[390px] mx-auto py-28">
    {/* HEADINGS */}
   
   
         <p className="font-playfair font-semibold text-3xl mt-3 text-center">
         Please verify  <span className="text-blue">your number</span>
          </p>
        <p className='text-center text-deep-blue'>We have sent an OTP to your mobile number for confirmation</p>
        {
          resendOtp?.data && <p className='relative top-2 text-center text-green-700'>YOUR OTP : {resendOtp?.data?.otp}</p>
        }
    
        
    {/* FORM & IMAGE */}
    <div className="  mt-5">
        
    { (show &&error) &&
          <div className='text-white rounded-full py-2 bg-error text-center'>{error}</div>
        }
        {(show && data) &&
          <div className='text-white rounded-full py-2 text-center text-bold bg-green-600'>{data?.message}</div>
        }
        { (show1 &&resendOtp?.error) &&
          <div className='text-white rounded-full py-2 bg-error text-center'>{resendOtp?.error}</div>
        }
        {show1 && (resendOtp?.data) &&
          <div className='text-white rounded-full py-2 text-center text-bold bg-green-600'>{resendOtp?.data?.message}</div>
        }
        <form onSubmit={handleSubmit(formSubmit)} className='relative' >
       
        <div className='absolute right-0 top-16 hover:text-blue' >
         <button type="button" onClick={()=> {
         
         if(counter<=0){
           dispatch(loginActions({phoneNumber:confirmOtpInfo?.phoneNumber}));
           setCounter(60);
           setShow1(true);
           setTimeout(()=>{
           setShow1(false);
      },[6000]);
         } 
         
       }} >Resend OTP</button> </div>
        <div className='absolute left-3 bottom-[104px] text-sky-500' > {counter}</div>
        
          <Input
            className="w-full mx-8 border-b-2  outline-none font-semibold placeholder-opaque-black p-3 mt-5"
            type="number"
            id="confirmOtp"
            placeholder="OTP"
            register={{...register("confirmOtp")}}
            errorMessage = {errors.confirmOtp?.message}
            
          />
        
       
          <button
            className={`px-14 w-full tracking-wider py-3 rounded-full  border bg-blue  mt-5 hover:bg-hover-blue hover-border-none text-white mb-4 transition duration-500 relative `}
            type="submit"
          >
          <span>{loading?<div className='button--loading py-[12px]'></div>:'Verify' }</span> 
          </button>
        
        </form>
        
   
      

    </div>
  </section>
    
     <div className='absolute bottom-0 w-full'> <Footer /></div>
    
  </div>
    </div>
  )
}

export default ConfirmOtpFinalLogin

