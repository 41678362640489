import React from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'
import { signout } from '../../../redux/actions/Auth/AuthAction';
import mylogo from '../../../youtube.png'


const Tab1 = ({setPage,page, selectedPage}) => {
  const {data,loading} = useSelector(state=>state.allChaptersConceptsRed);
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const Logout=()=>{
    dispatch(signout(userData?.phoneNumber,navigate));
 }
  if(data.message==='jwt expired'){
       
    return (
      <div className='flex items-center justify-center min-h-screen'>
      <div className='relative  mx-auto my-auto bg-black text-white p-2 max-w-[500px]'>Your Session has expired <span className=' cursor-pointer text-blue' onClick={Logout}  >Click Me</span> to Log In again</div>
      </div>
    )
}
  return (
    <div>
        <main className='mt-[150px] flex justify-center items-center text-gray-900'>
  
  <div className='ml-1 w-[1050px]'>
  {
   loading?<Loading/>:
       data?.results?.map(x=>(
                
            <div key={x._id} className='definition rounded-md' >
            <div className='flex'>
                <div className='mr-2 flex mt-1'>{x.conceptNo}.</div>
                <div className='text-2xl items-center' style={{color:"#005DFF"}}> {x.heading}</div>
            </div>
            
            <div className='text-[17px] mr-8 defini'>{x.definition}</div>
             <div className='text-[12px] xs:text-[14px] sm:text-[18px] overflow-x-auto overflow-y-hidden'><InlineMath displayMode={true} options={{strict: false}} math={x.definitionLatex} errorColor={'#cc0000'} /></div>
             <div>{x.image&&<img src={x.image} alt="nothing" width='300px' />}</div>
             
              <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px] overflow-x-auto overflow-y-hidden' ><InlineMath displayMode={true} options={{strict: false}} math={x.conceptLatex} errorColor={'#cc0000'} /></div>
             
             <div className='defini'>{x.concept}</div>
             {x.videoLink && 
                     <div className='mt-10 bg-lime-300 p-2 w-full flex rounded-md' >
                     
                    <a href={`${x.videoLink}`} className='flex w-full' > <img src={`${mylogo}`}  alt="" width='30px' /><p className='w-full text-center'>Click me for Video Lecture </p></a>
                   
                   </div>
              }
          </div>
              )) 
              
              }
        </div>
   </main>
       <div className='my-3'>
         <Pagination selectedPage={ selectedPage } setPage={ setPage } page={ page } conceptData={ data } />
       </div>
    </div>
  )
}

export default Tab1



 