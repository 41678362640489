import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
//import { mathChapters } from './mathsChapters'
import {mathchapterNamesOfAll} from '../redux/actions/mathsActions/mathsactions';
import { signout } from '../redux/actions/Auth/AuthAction'
import Loading from '../components/Loading'
const Maths = () => {
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const [navbarMenu,setNavbarMenu] = useState(false);
 
    const dispatch=useDispatch();
    const {data,loading} = useSelector(state=>state.mathchapterName);
    const history = useNavigate()
    const log = useRef(true);
    useEffect(() => {
      if(log.current){
          dispatch(mathchapterNamesOfAll());
          log.current=false
      }
    
    if(!userData){
       history('/',{replace:true});
    }
    
    }, [userData,dispatch,history]);
    const singleItem=async(id)=>{
    
      const item =await data?.data?.find(x=>x._id===id).chapterName.split(' ').join('-').toLowerCase()
      history(`/subject/maths/${item}`,{replace:true})
    //redirect("/login");
    }
   
    const Logout=()=>{
      dispatch(signout());
     history('/',{replace:true})
    }
   
  if(loading){
      return (
        <div>
          <Loading />
        </div>
       )
  }
  if(data.message==='jwt expired'){
       
       return (
         <div className='text-center mt-4 mx-auto my-auto bg-black text-white p-2 max-w-[500px]'>Your Session has expired <span className=' cursor-pointer text-blue' onClick={Logout} >Click Me</span> to Log In again</div>
       )
  }
 
  return (
    <div>
     <div className='fixed w-full top-0' ><Navbar  navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} /></div>
       
       <Sidebar navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} />
    <div className={`md:ml-[300px] mt-[86px] mr-2  ease-in-out duration-300 `}>
    {data?.data?.map(chapter=>(
      <Link onClick={()=>singleItem(chapter._id)} key={chapter._id} >
          <div className='flex rounded  hover:bg-deep-blue hover:text-white transition duration-200 ease-in-out border-b p-10' key={chapter.chapterNo} >
            <div className='text-[17px] sm:text-[20px] ml-2 sm:ml-16 cursor-pointer font-roboto font-semi ' >{chapter.chapterName}</div>
          </div>
      </Link>
    ))}
    
   </div>
   </div>
  )
}

export default Maths
//text-[8px] xs:text-[13px] sm:text-[16px]