import React, { useEffect, useMemo, useState } from 'react'
import { Button } from '@mui/material';
import { ModeCommentOutlinedIcons, ThumbUpAltIcons, ThumbUpAltOutlinedIcons } from '../../icons/Icon';
import { AddLikeToSahilAskAction,  RemoveLikeToSahilAskAction } from '../../redux/actions/Ask-to-sahil/AskToSahilAction';
import { useDispatch } from 'react-redux';
const HandleOneByOne = ({ post,setId,setShowComment }) => {
    const [likes,setLikes] = useState(post?.likes);
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const hasLikedPost =  useMemo(()=>likes?.find((like) => like === user.id),[likes,user.id]);
 
    const dispatch = useDispatch();
    useEffect(()=>{
      setLikes(post?.likes)
    },[post?.likes])
    const handleLike = async (id) => {
       
        if (!hasLikedPost ) {
            dispatch(AddLikeToSahilAskAction(id));
            setLikes((prev)=>{
              return [...prev,user.id]
            })
          } 
          else{
            dispatch(RemoveLikeToSahilAskAction(id));
            setLikes(likes?.filter((id) => id !== user.id));
          }
       
       };
   
    const commentOnPosts = (id)=>{
      setId(id)
      setShowComment(true);
     
    } 
       const Likes = () => {
         if (likes?.length > 0) {
           return likes?.find((like) => like === (user.id))
             ? (
               <><ThumbUpAltIcons fontSize="small" />&nbsp;{likes.length > 2 ? `You and ${likes?.length - 1} others` : `${likes?.length} like${likes?.length > 1 ? 's' : ''}` }</>
             ) : (
               <><ThumbUpAltOutlinedIcons fontSize="small" />&nbsp;{likes?.length} {likes?.length === 1 ? 'Like' : 'Likes'}</>
             );
         }
       
         return <><ThumbUpAltOutlinedIcons fontSize="small" />&nbsp;Like</>;
       };
  return (
    <div>
       <div className='definition relative rounded-md max-w-2xl border m-auto mt-2'>
             
               <div className='border-b-[2px] border-cyan-600 text-center text-2xl'>{post.name}</div>
               <div className='border-b-[1px] border-grey py-2'>{post.question}</div>
              <div className='flex' >
                 <Button  onClick={()=>handleLike(post._id)}><Likes /></Button> 
                 <Button className='flex items-center' onClick={()=>commentOnPosts(post._id)} >
                <ModeCommentOutlinedIcons fontSize="small" />&nbsp;
                <p>{post?.comments?.length}</p>
                
                </Button>
                <p className='relative flex items-center'>comments</p>
               
              </div>
               
                
            </div>
    </div>
  )
}

export default HandleOneByOne
