import React, { useEffect,useState } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import { physicsTest1SidebarAction } from '../../redux/actions/Test/Physics/physicsTest1Action';
import { useLocation } from 'react-router-dom';
import { multiTestphysicsSidebarAction } from '../../redux/actions/Test/Physics/multiTestAction';


const TestSidebar1 = ({ setPage,page }) => {
  const { data,loading } = useSelector(state=>state.physicsTest1SidebarRed);
  const multipleData =  useSelector(state=>state.multiTestphysicsSidebarRed);
  const [path,setPath] = useState('')
  const pageset =(i)=>{
     setPage(Number(i));
    }
    const location = useLocation();
   useEffect(() => {
   const pat = location.pathname.split('/');
     setPath(pat[2]);
    
  }, [location,path]);
  
   const dispatch = useDispatch();
   useEffect(() => {
      if(page%5===1){
        if(path === 'single-correct') {
           dispatch(physicsTest1SidebarAction(Math.ceil(page/5)))
        }else {
          dispatch(multiTestphysicsSidebarAction(Math.ceil(page/5)))
        }
       
      }
   }, [page,dispatch,path])
  
  return (
 
       <section className={`bg-gray2 h-screen overflow-x-hidden overflow-y-scroll z-[1000] w-[300px] ss:w-[400px] p-10   top-0  absolute  duration-300`} >
    <div className={`z-40 bg-black w-[282px] ss:w-[382px] items-center flex justify-center rounded-md text-white  text-xl py-3 fixed top-2 left-2 ease-in-out duration-300`}>Test List of Questions</div>
     <div className=' gap-y-4 my-10' >
    
      {
        (path==='single-correct'?loading:multipleData?.loading)?<Loading />:
          (path==='single-correct'?data?.results:multipleData?.data?.results)?.map((x,i)=>(
          <div className={`flex gap-2 my-2 w-full-10/12 justify-start ${page===Number(x.Qno) && 'bg-active'} p-3 rounded-md hover:bg-active duration-100` } onClick={()=>pageset(x.Qno)} key={x._id} >
             <div className='text-white p-2 rounded-full bg-black h-6 w-6 flex items-center justify-center'>{x.Qno}</div>
             <div className='text-white' >{x.question}</div>
            {x.question2 && <div className='text-[10px] ss:text-[13px] -left-2 relative text-white ' >  <InlineMath math={x.question2} /></div>}
           
          </div>
        ))
      
         
        
        
      }
     </div>
    </section>
   
  )
}

export default TestSidebar1


  