import { useCallback, useState } from 'react';
import Drag from './Drag';
import DataHandling from './DataHandling';

function DownLoads() {
  const [state,setState] = useState(false);
  const [avatar,setAvatar] = useState(null);
  const [url,setUrl]  =  useState();
  const onDrop = useCallback(acceptedFiles => {
    setUrl(URL.createObjectURL(acceptedFiles[0]));
    setAvatar(acceptedFiles[0]);
  }, [])
  
  return (
   <div>
    <Drag setState={setState} onDrop={onDrop}  avatar={avatar} />
    <DataHandling avatar={avatar} state={state} />
</div>
  );
}

export default DownLoads;