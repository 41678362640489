import  axios  from "axios";


let url = 'https://whiteboardblackpen-tge6.onrender.com/applicationOfderivativetangent';
//let url = 'http://3.110.197.80/api/applicationOfderivativetangent';
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/applicationOfderivativemaxima`
const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });
export const conceptOfTangent=(page)=>async(dispatch)=>{
    dispatch({type:"TANGENT_CONCEPT_REQUEST"});
    try {
     const {data} = await API.get(`/concept?page=${page}&limit=${2}`);
    // console.log(data,'lplplp');
     dispatch({type:"TANGENT_CONCEPT_SUCCESS",payload:data})
    } catch (error) {
   
      dispatch({type:"TANGENT_CONCEPT_FAIL"});
    }
  }
  
  // examples
  
  export const exampleOfTangent=(page2)=>async(dispatch)=>{
    dispatch({type:"TANGENT_EXAMPLE_REQUEST"});
    try {
     const {data} = await API.get(`/example?page=${page2}&limit=${2}`);
     //console.log(data);
     dispatch({type:"TANGENT_EXAMPLE_SUCCESS",payload:data})
    } catch (error) {
   
      dispatch({type:"TANGENT_EXAMPLE_FAIL"});
    }
  }


  export const reviseConceptOfTangent=(id)=>async(dispatch)=>{
    dispatch({type:"TANGENT_REVISE_REQUEST"});
   
    try {
     const {data} = await API.post(`/revise`,{ id });
     dispatch({type:"TANGENT_REVISE_SUCCESS",payload:data})
    } catch (error) {
   
      dispatch({type:"TANGENT_REVISE_FAIL"});
    }
  }

//more
  export const moreQuestionOfTangentAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_QUE_TANGENT_REQUEST"});
   
    try {
      //console.log(id,'kljkjkjkdsd');
     const {data} = await API.get(`/more/question?page=${page}&limit=${12}`);
     //console.log(data);
     dispatch({type:"MORE_QUE_TANGENT_SUCCESS",payload:data})
    } catch (error) {
   
      dispatch({type:"MORE_QUE_TANGENT_FAIL"});
    }
  }
  
  export const moreAnswerOfTangentAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_ANS_TANGENT_REQUEST"});
   
    try {
     const {data} = await API.get(`/more/answer?page=${page}&limit=${12}`);
    
     dispatch({type:"MORE_ANS_TANGENT_SUCCESS",payload:data})
    } catch (error) {
   
      dispatch({type:"MORE_ANS_TANGENT_FAIL"});
    }
  }