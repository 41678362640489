import React from 'react'


const TabToggle = ({ selectedPage,pageSelected1,pageSelected3,pageSelected4,pageSelected2,concepts,examples,exercises,codingGuru }) => {
 
  return (
    <div>
     
     <div className=' py-4 bg-slate-100'>
    <div className='overflow-x-auto whitespace-nowrap w-full vvs:w-full' style={{scrollbarWidth: 'none'}}>
    <div className='items-center justify-center topics relative flex top-5 w-[500px] ss:w-full m-auto'  >
      <div className='flex mb-3 ml-0 justify-center items-center text-[11px] sm:text-[19px] '  >
        <div className={`duration-300 ${(selectedPage==='concepts')&&' bg-slate-600 text-white p-2'} `} onClick={pageSelected1} >{concepts}</div>
        <div className={`duration-300 ${(selectedPage==='examples')&& 'bg-slate-600 text-white p-2 '} `} onClick={pageSelected2}>{examples}</div>
        <div className={`duration-300 ${(selectedPage==='exercises')&& 'bg-slate-600 text-white p-2'} `} onClick={pageSelected3}>{exercises}</div>
        <div className={`duration-300 ${(selectedPage==='codingGuru')&& 'bg-slate-600 text-white p-2'} `} onClick={pageSelected4}>{codingGuru}</div>
      </div>
      </div> 
    </div>
   
    </div>
  </div>
  )
}

export default TabToggle
