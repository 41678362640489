import React from 'react'
//import useMediaQuery from "../../../components/hook/UseMediaQuery";
import { motion } from "framer-motion";

const Landing = ({ setSelectedPage }) => {
  //  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="learn" className="" >
      {/* <div className='flex md:order-2  justify-center basic-3/5  mt-16 md:mt-32' >
         {
          isAboveLarge ?(
            <div className='relative -top-12 w-[460px] mr-12 h-[460px] cursor-pointer '><Earth/></div>
           
           
          ):(
            <div className='w-[380px] h-[380px]'><Earth/></div>
        )}
        </div> */}
         {/* MAIN TEXT */}
       <div className="mt-28">
        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="uppercase text-5xl vvs:text-6xl ss:text-8xl sm:text-9xl text-blue mt-6 font-black text-center text-image"  >
          Concepts {""}
            {/* <span
              className="xs:relative text-deep-blue xs:font-semibold mx-auto xs:before:content-brush
              before:absolute before:-left-[35px] w-[100px] before:-top-[99px] before:z-[-1]"
            > 
            Physics &  Maths 
             </span> */}
          
           
          </p>
          <p className='uppercase text-5xl vvs:text-6xl ss:text-8xl sm:text-9xl font-black text-center' >
            of
          </p>
          <p className='uppercase text-5xl vvs:text-6xl ss:text-8xl sm:text-9xl text-blue font-black text-center' >
            Physics
          </p>
          {/* <p className='font-playfair text-lg font-black text-center' >
            With Sahil Bhayya
          </p> */}
          <p className="text-4xl mx-auto relative text-blue max-w-[500px] font-playfair -z-10 text-center my-2 md:text-start">
            With Sahil {""}
            <span
              className="xs:relative text-deep-blue xs:font-semibold z-[-1000] xs:before:content-brush
              before:absolute before:-left-[35px] w-[100px] before:-top-[99px] before:z-[-1]"
            > 
            Bhayya
             </span>
          
           
          </p>
         
         
         <p className='text-center font-serif mt-3' >Physics is not just to memorize everything.  </p>
         <p className='text-center font-serif' >Physics is just an imagination or ability of a person to think what's happening around you.</p>
          <p className='text-center font-serif'>Teaching is just a quality not a quantity.</p>
          
         
        </motion.div>

      
      {/* CALL TO ACTIONS */}
      <motion.div
      className="flex mt-5 justify-center md:justify-start"
       initial='hidden'
       whileInView="visible"
       viewport={{ once: true, amount: 0.5 }}
       transition={{ delay: 0.2, duration: 0.5 }}
       variants={{
           hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
      >
          
         
      </motion.div>
     
      <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
         
        </motion.div>
      </div>
     
  </section>
  )
}

export default Landing
