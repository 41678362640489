
export const getAllAskToSahilRed=(state={data:[]},action)=>{
    switch(action.type){
    //    case 'GET_ASK_TO_SAHIL_REQUEST':
    //        return {...state,loading:true}
        case 'GET_ASK_TO_SAHIL_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'GET_ASK_TO_SAHIL_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const createAskToSahilRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'CREATE_ASK_TO_SAHIL_REQUEST':
            return {...state,loading:true}
        case 'CREATE_ASK_TO_SAHIL_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'CREATE_ASK_TO_SAHIL_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const CreateCommentsToSahilAskRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'CREATE_COMMENTS_REQUEST':
            return {...state,loading:true}
        case 'CREATE_COMMENTS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'CREATE_COMMENTS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

// export const GetCommentsToSahilAskRed=(state={data:[] },action)=>{
//     switch(action.type){
//         case 'GET_COMMENTS_REQUEST':
//             return {...state,loading:true}
//         case 'GET_COMMENTS_SUCCESS':
//            return {...state,data:action.payload,loading:false}
//         case 'GET_COMMENTS_FAIL':
//            return {...state,loading:false}
//         default:
//             return state
//     }
// }

export const AddLikeToSahilAskRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'ADD_LIKE_REQUEST':
            return {...state,loading:true}
        case 'ADD_LIKE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ADD_LIKE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}


export const RemoveLikeToSahilAskRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'REMOVE_LIKE_REQUEST':
            return {...state,loading:true}
        case 'REMOVE_LIKE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'REMOVE_LIKE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}


//  NESTED COMMENT


export const AddCommentsOnCommentsToSahilAskRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'CREATE_COMMENTS_COMMENTS_REQUEST':
            return {...state,loading:true}
        case 'CREATE_COMMENTS_COMMENTS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'CREATE_COMMENTS_COMMENTS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const AddLikeOnCommentsToSahilAskRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'ADD_LIKE_ON_COMMENT_REQUEST':
            return {...state,loading:true}
        case 'ADD_LIKE_ON_COMMENT_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ADD_LIKE_ON_COMMENT_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}


export const RemoveLikeOnCommentsToSahilAskRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'REMOVE_LIKE_ON_COMMENT_REQUEST':
            return {...state,loading:true}
        case 'REMOVE_LIKE_ON_COMMENT_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'REMOVE_LIKE_ON_COMMENT_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}