import React, { useState} from 'react'
import ProgrssBar from './ProgressBar'
import {useDropzone} from 'react-dropzone'
import { uploadPdfFile } from '../../redux/actions/downloads/downLoadsAction';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';

const Drag = ({ onDrop,avatar,setState }) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [alert,setAlert] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const { getRootProps, getInputProps } = useDropzone({onDrop});
    const dispatch=useDispatch();
    const handleSubmit = (e)=>{
        e.preventDefault();
        setState(true)
        setAlert(true)
        dispatch(uploadPdfFile(avatar,setUploadPercentage)) 
        setTimeout(() =>{
          setAlert(false)
        },2460);

        setTimeout(() =>{
          setUploadPercentage(0);
          setState(false)
        },2000);
       }   
  
       
   
    if(process.env.REACT_APP_PHONENUMBER!==userInfo.phoneNumber){
      return (
        <div className='text-center text-3xl font-bold mt-8 mb-16' >Files for download</div>
      )
    }
    return (
      <div  >
    <form onSubmit={handleSubmit} action="/uploads" encType="multipart/form-data" className='w-[290px] mx-auto' method="POST">
    {
      alert &&  <div className="alert alert-success" role="alert">
                  Successfully Uploaded
               </div>
    }
   
    <div className="p-8 mt-3 relative flex bg-green-400 items-center justify-center mx-auto" style={{width: "18rem", minHeight:'200px', border:'2px dashed white'}}>
   
       <div {...getRootProps({ className:'dropZone'})}>
        <input className="input-zone" {...getInputProps()} />
        <h6 className="card-subtitle mb-2 text-muted"><span className='text-white' >Drag n Drop or</span>  <span className='pointer'>Choose a file</span></h6>
        
        </div>
    </div>
      <Button type='submit' variant="contained" disabled={avatar?false:true}  >Submit</Button>
     
      <section className='position-relative  mx-1 ' >
       
      {
        alert &&<ProgrssBar percentage={uploadPercentage} />
      }
        
            
         
        </section>

      
      </form>
      
        </div>
    );
}

export default Drag
