import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const Tab3 = () => {
  const {data,loading} = useSelector(state=>state.moreQueIndifinite);
  
  return (
    <div className='relative mt-[170px]' >
      <Link to='exercises/previousyears?&activeTab=jeemains' >
          <div className=' mt-11 max-w-full  ml-4 mr-2' >
             <div className='examples rounded-lg text-1xl ss:text-2xl hover:bg-sideScroll duration-300 hover:text-white text-black flex mt-6 font-serif font-semi p-4 ss:p-7'>Previous Years Problems</div>
          </div>
      </Link>
      <Link to='exercises/moreproblems' >
          <div className=' max-w-full  ml-4 mr-2' >
             
             <div className='examples relative rounded-lg duration-300 hover:bg-sideScroll hover:text-white p-4 ss:p-7  mt-2 cursor-pointer text-grey font-serif font-semi text-1xl ss:text-2xl' > <div className='absolute text-blue h-full flex justify-center items-center text-[15px] right-4   top-0 '>{data?.totalItems} Questions</div> Challenges For You</div>
          </div>
      </Link>
    </div>
  )
}

export default Tab3
