export const conceptsOfTangentRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'TANGENT_CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'TANGENT_CONCEPT_SUCCESS':
            return {...state,data:action.payload,loading:false}
        case 'TANGENT_CONCEPT_FAIL':
             return {...state,loading:false}
        default:
            return state
    }
}

export const examplesOfTangentRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'TANGENT_EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'TANGENT_EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TANGENT_EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const reviseConceptsOfTangentRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'TANGENT_REVISE_REQUEST':
            return {...state,loading:true}
        case 'TANGENT_REVISE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TANGENT_REVISE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//more

export const moreQueTangentRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_TANGENT_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_TANGENT_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_TANGENT_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsTangentRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_ANS_TANGENT_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_TANGENT_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_TANGENT_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}