import axios from 'axios';

const url = 'https://whiteboardblackpen-tge6.onrender.com/multi-test'
//const url = 'http://localhost:8000/multi-test';
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/multi-test`

export const multiTestAction=(page)=>async(dispatch)=>{
    dispatch({type:"MULTI_TEST1_REQUEST"});
    try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/phy?page=${page}&limit=${1}`,{
        headers:{
            Authorization : `Bearer ${userInfo.token}`,
            'Content-Type': "application/json",
        }
     });
    
     dispatch({type:"MULTI_TEST1_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"MULTI_TEST1_FAIL"});
    }
  }
export const multiTestresponseAnswerAction=(response,id)=>async(dispatch)=>{
    dispatch({type:"MULTI_TEST1_RESPONSE_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.post(`${url}/phy/response`,{response,id},{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
   console.log(data,'responseaaalll');
     dispatch({type:"MULTI_TEST1_RESPONSE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MULTI_TEST1_RESPONSE_FAIL"});
    }
  }
   // all data for response
  export const multiTestresponseAnswerActionAllData=()=>async(dispatch)=>{
    dispatch({type:"MULTI_TEST1_RESPONSE_ALLDATA_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/phy/response/alldata`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
   
     dispatch({type:"MULTI_TEST1_RESPONSE_ALLDATA_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"MULTI_TEST1_RESPONSE_ALLDATA_FAIL"});
    }
  }

  // sidebar


  export const multiTestphysicsSidebarAction=(page)=>async(dispatch)=>{
    dispatch({type:"MULTI_TEST1_SIDEBAR_REQUEST"});
    try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/phy/sidebar?page=${page}&limit=${5}`,{
        headers:{
            Authorization : `Bearer ${userInfo.token}`,
            'Content-Type': "application/json",
        }
     });
    
     dispatch({type:"MULTI_TEST1_SIDEBAR_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"MULTI_TEST1_SIDEBAR_FAIL"});
    }
  }