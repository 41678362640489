import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar2 from '../../components/Navbar2';
import { conceptOfMaxima,exampleOfMaxima } from '../../redux/actions/mathsActions/applicationOfDerivative/maximaMinima/maximaAction';
import { conceptOfTangent, exampleOfTangent } from '../../redux/actions/mathsActions/applicationOfDerivative/tangentNormal/tangentAction';
//import { conceptOfDerevative, exampleOfDerevative } from '../../redux/actions/mathsActions/derevative/derevativeAction';
import { conceptOfLimits, exampleOfLimits } from '../../redux/actions/mathsActions/LimitsActions/LimitAction';
import Tab1 from './tabs/Tab1';
import Tab2 from './tabs/Tab2';
import Tab3 from './tabs/Tab3';


const height = '70px';
const concepts = 'Concepts'
const exercises = 'Exercises';
const examples = 'Examples';

const ApplicationDerivativeTab = () => {
    const [selectedPage,setSelectedPage] = useState('concepts');
    const [sidebarShow,setSidebarShow] = useState(true);
    const [page,setPage] = useState(1);
    const [page2,setPage2] = useState(1);
    const [path,setPath] = useState('')
    const navigate = useNavigate();
    const dispatch=useDispatch();
 
    const location = useLocation();
    useEffect(() => {
      const pat = location.pathname.split('/');
      setPath(pat[4]);
      
 }, [selectedPage])
  useEffect(() => {
    if(selectedPage==='concepts'){
      if(path==='tangent-normal'){
        dispatch(conceptOfTangent(page));
      }else {
        dispatch(conceptOfMaxima(page));
      }
       
    }if(selectedPage==='examples'){
      if(path==='tangent-normal'){
        dispatch(exampleOfTangent(page2));
      }else{
        dispatch(exampleOfMaxima(page2));
      }
      
    }
}, [page,page2,selectedPage,path])

  const pageSelected1=()=>{
    setSelectedPage("concepts");
    navigate('?&activeTab=concepts',{replace:true}) 
  }
  const pageSelected2=()=>{
    setSelectedPage("examples");
    navigate('?&activeTab=examples',{replace:true})
  }
  const pageSelected3=()=>{
    setSelectedPage("exercises");
    navigate('?&activeTab=exercises',{replace:true}); 
  }
  return (
    <div>
        <Navbar2 height={height} subject={path==='tangent-normal'?'Tangent & normal':'Maxima minima'} concepts={concepts} exercises={exercises}
        pageSelected1={pageSelected1}  pageSelected2={pageSelected2}  pageSelected3={pageSelected3} examples={examples} selectedPage={selectedPage} setSidebarShow={setSidebarShow} sidebarShow={sidebarShow}  />
    
   <div>
        {
          selectedPage==='concepts'?( <div><Tab1 page={page} setPage={setPage} path={path} selectedPage={selectedPage}  /></div>):selectedPage==='examples'?( <div><Tab2 page2={page2} path={path} setPage2={setPage2}  selectedPage={selectedPage} /></div>):selectedPage==='exercises'?( <div><Tab3 path={path} setSidebarShow={setSidebarShow} sidebarShow={sidebarShow} selectedPage={selectedPage} /></div>):'null'
        }
    </div>
    </div>
  )
}

export default ApplicationDerivativeTab
