import React from 'react';
import { motion } from "framer-motion";
import useMediaQuery from '../../../../components/hook/UseMediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Test = () => {
    const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <div>
       <div className="md:flex max-w-[1100px] items-center mx-auto md:justify-between md:gap-8 md:mt-20">
        <motion.div
          className="md:w-1/3 "
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-serif font-bold text-5xl text-black text-center mt-20 md:mt-2   ">
            TEST <span className="text-blue">SERIES</span>
          </p>
        
          <p className="mt-3 max-w-[300px] xs:max-w-[400px] mx-auto mb-7 text-center font-serif">
            In every 15 days, A test is taken to ask the concepts which are taught so that your confidence level
            increase after every test.

          </p>
        </motion.div>
        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <LazyLoadImage
              src="assets/test.png"
              alt='assets/test.png'
              //effect='blur'
              placeholderSrc="assets/test.png"
              threshold='100'
              className=" hover:filter hover:saturate-200 transition duration-500 w-full max-w-[400px] md:max-w-[550px]"
               
             />
              
           
          ) : (
            <div className=' flex justify-center w-full'>
            <LazyLoadImage
              src="assets/test.png"
              alt='assets/test.png'
              //effect='blur'
              placeholderSrc="assets/test.png"
              threshold='100'
              className=" hover:filter hover:saturate-200 transition duration-500 max-w-[250px] md:max-w-[450px]"
               
             />
              
            </div>
          )}
        </div>
    </div>
    </div>
  )
}

export default Test
