import axios from 'axios';

const url = 'https://whiteboardblackpen-tge6.onrender.com/contact-us'
//const url = 'http://localhost:8000/contact-us'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/contact-us`

const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });
export const contactUsActions=(inputDatas)=>async(dispatch)=>{
    dispatch({type:"CONTACTUS_REQUEST"});
   
    try {
     
     const { data } = await axios.post(`${url}/send`,inputDatas);
    
     dispatch({type:"CONTACTUS_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"CONTACTUS_FAIL", payload:
      error.response&&error.response.data.message
      ?error.response.data.message
      :error.message});
    }
  }

  export const allMessagesForContactUsActions = () => async(dispatch) => {
    dispatch({type:"GET_ALL_CONTACTUS_REQUEST"});
   
    try {
     
     const { data } = await API.get(`get-all-messages`);
    
     dispatch({type:"GET_ALL_CONTACTUS_SUCCESS",payload:data});
     
    } catch (error) {
    
      dispatch({type:"GET_ALL_CONTACTUS_FAIL", payload:
      error.response&&error.response.data.message
      ?error.response.data.message
      :error.message});
    }
  }