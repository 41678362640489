import React, { useState } from 'react'
import LineGradient from "../tools/LineGradient";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from './inputForContact';
import { contactUsActions } from '../../../redux/actions/contactActiona/contactUsAction';
import { LoadingContact } from '../../../components/Loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const schema =yup.object({
  name:yup.string().required("Name is a required field"),
  phoneNumber:yup
  .string()
  .required("phone number is a required field") 
  .matches(
    /^[0-9]{10}$/,"Phone number is not valid"
  ),
  email:yup.string().required("Email is a required field") ,
  message:yup.string().required("Message is a required field")
  
})

const Contact = () => {
  const {data,loading,error } = useSelector(state=>state.contactUsRed);
  const [showMessage,setShowMessage] = useState(false)
 
  const [diss,setDiss] = useState(false)
  const { handleSubmit ,
          register,
          formState:{ errors }
        } =  useForm({
    resolver:yupResolver(schema)
  });
    const dispatch = useDispatch()

    const formSubmit =(data)=>{
      setShowMessage(true)
       if(errors.message || errors.name || errors.email || errors.phoneNumber){
        setDiss(true)
       }
       setTimeout(()=>{
        setShowMessage(false)
        setDiss(true)
       },[3000])
    
       dispatch(contactUsActions(data))
    }
  return (
    <section id="contact" className="py-4 w-full">
    
       <p className='text-center text-2xl text-deep-blue font-playfair py-2 mb-4' >
        If you like my content and try to contact me please message.
       </p>
    {/* HEADINGS */}
         <p className="font-playfair font-semibold text-4xl mb-5 text-center text-blue">
            CONTACT <span className="orange_gradient">US</span>
          </p>
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.5 }}
      variants={{
        hidden: { opacity: 0, x: 50 },
        visible: { opacity: 1, x: 0 },
      }}
      className="flex justify-end w-full"
    >
      <div>
        <div className="flex md:justify-end my-2 ">
          <LineGradient width="w-1/2" />
        </div>
      </div>
    </motion.div>

    {/* FORM & IMAGE */}
    <div className="md:flex md:justify-between gap-16 mt-5">
    <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }} 
        className="basis-1/2 flex justify-center"
      >
       
        <LazyLoadImage
              src="/assets/mess.png"
              alt='image loading'
              width='300px'
              //effect='blur'
              placeholderSrc="/assets/mess.png"
              threshold='100'
              className=" relative object-contain"
               
             />
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
        className="basis-1/2 mt-10 md:mt-0"
      >
      <div className='text-center' >
      { (showMessage&&error) &&
          <div className='text-white rounded-full py-2 bg-error text-center'>{error}</div>
        }
      {
        (showMessage&& data )&&(loading ? <LoadingContact />: <div className='bg-green-500 text-white py-2 rounded ' >{data?.message}</div>)
      }
      </div>
        <form
          target="_blank"
          onSubmit={handleSubmit(formSubmit)}
        >
          <Input
            className="w-full border-b-2  outline-none font-semibold placeholder-opaque-black p-3"
            id='name'
            type="text"
            placeholder="NAME"
            register={{...register("name")}}
            errorMessage = {errors.name?.message}
           />
          <Input
            id='email'
            type="email"
            placeholder="EMAIL"
            register={{...register("email")}}
            errorMessage = {errors.email?.message}
            
          />
         

          <Input
            className="w-full border-b-2  outline-none font-semibold placeholder-opaque-black p-3 mt-5"
            type="number"
            id="phoneNumber"
            placeholder="phone number"
            register={{...register("phoneNumber")}}
            errorMessage = {errors.phoneNumber?.message}
            
          />
         

          <textarea
            className="w-full border-[0.5px] border-gray-400 bg-transparent rounded outline-none placeholder-opaque-black p-3 mt-5"
            name="message"
            id="message"
            placeholder="Write Your Message"
            rows="5"
            cols="50"
            {...register("message", {
              required: true,
              maxLength: 100,
            })}
           
          />
         {errors.message && (
            <p className="text-danger mt-1">
             this is a required field
            </p>
          )} 

          <button
            className={`${(!errors.message && !errors.name && !errors.email && !errors.phoneNumber && diss) &&'bg-red' }hover:tracking-widest px-10 py-3 rounded-full  border bg-img font-semi-bold mt-5 hover-border-none hover:scale-x-110  transition duration-500`}
            type="submit"
           // disabled={(errors.message || errors.name|| errors.email ||errors.phoneNumber) & true }
          >
            Submit Feedback
          </button>
        </form>
      </motion.div>
     

    </div>
    <div className='gradient' ></div>
  </section>
  )
}

export default Contact
