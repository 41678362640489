
export const mockTestAllQuestionsRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'MOCK_TEST_REQUEST':
            return {...state,loading:true}
        case 'MOCK_TEST_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MOCK_TEST_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const responseAnswerOfMockTestRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'MOCK_TEST_RESPONSE_REQUEST':
            return {...state,loading:true}
        case 'MOCK_TEST_RESPONSE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MOCK_TEST_RESPONSE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}



export const responseAnswerOfMockTestAllDataRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'MOCK_TEST_RESPONSE_ALLDATA_REQUEST':
            return {...state,loading:true}
        case 'MOCK_TEST_RESPONSE_ALLDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MOCK_TEST_RESPONSE_ALLDATA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}
// answer sheets
export const getAnswerSheetsMockTestAllDataRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'MOCK_TEST_ANSWER_SHEETS_REQUEST':
            return {...state,loading:true}
        case 'MOCK_TEST_ANSWER_SHEETS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MOCK_TEST_ANSWER_SHEETS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}
// sidebar


export const responseAnswerOfMockTestUpdateRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'MOCK_TEST_RESPONSE_UPDATE_REQUEST':
            return {...state,loading:true}
        case 'MOCK_TEST_RESPONSE_UPDATE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MOCK_TEST_RESPONSE_UPDATE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const getresultsMockTestRed = (state={data:[]},action)=>{
    switch(action.type){
        case 'MOCK_TEST_GET_RESULTS_REQUEST':
            return {...state,loading:true}
        case 'MOCK_TEST_GET_RESULTS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MOCK_TEST_GET_RESULTS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}
// resultssss
export const resultsMockTestRed = (state={data:[],loading:false},action)=>{
    switch(action.type){
        case 'MOCK_TEST_RESULTS_REQUEST':
            return {...state,loading:true}
        case 'MOCK_TEST_RESULTS_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MOCK_TEST_RESULTS_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//  TIMERRRRR;


export const timerMockTestRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MOCK_TEST_TIMER_REQUEST':
            return {...state,loading:true}
        case 'MOCK_TEST_TIMER_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MOCK_TEST_TIMER_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}



export const mockTesResultTimeRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'MOCK_TEST_RESULT_REQUEST':
            return {...state,loading:true}
        case 'MOCK_TEST_RESULT_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MOCK_TEST_RESULT_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}


