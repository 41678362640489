import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch,useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../scenes/inputForContact';
import { loginActions } from '../../../redux/actions/Auth/AuthAction';
import { CloseIcons } from '../../../icons/Icon';
import { Footer, SignSignupNavbar } from '../scenes/Footer';
import Loading from '../../../components/Loading'
import ConfirmOtpFinalLogin from './ConfirmOtpFinalLogin';

const schema =yup.object({
    phoneNumber:yup
    .string()
    .required("Enter valid phone number") 
    .matches(
      /^[0-9]{10}$/,"Phone number is not valid"
    ),
    
  })

const Login = ({ setAccountToggle,accountToggle,setLoginAfterVerify }) => {
  const [show,setShow] = useState(false)
  const confirmOtpInfo = JSON.parse(localStorage.getItem('confirmOtpInfo'));
  const { data,loading,error }  = useSelector(state=>state.loginRed);
  const { handleSubmit ,
          register,
          formState:{ errors }
        } =  useForm({
    resolver:yupResolver(schema)
  });
 
    const dispatch = useDispatch()
    const formSubmit =(inputOtp)=>{
      setLoginAfterVerify(false)
       dispatch(loginActions(inputOtp));
       setShow(true);
       setTimeout(()=>{
        setShow(false);
       
       },[5000]);
      
  
    };
   

  if(confirmOtpInfo ){
    return(
      <div>
        <ConfirmOtpFinalLogin setAccountToggle = {setAccountToggle} accountToggle = {accountToggle} />
       
      </div>
    )
  }
    if(loading){
      return (
        <div><Loading /></div>
      )
    }
   
  return (
    <div className='min-h-[91.3vh] truncate'>
    <div className='fixed top-0 right-0 w-full' >
   <SignSignupNavbar />
   </div>
    <section id="contact" className="relative otpGenerate  bg-white border-[0.6px] border-gray-400 ss:max-w-[370px] max-w-[350px] mt-16 mx-auto py-28">
    {/* HEADINGS */}
         <p className="font-playfair font-semibold text-3xl mt-3 text-center">
         Please <span className="text-blue">Login</span>
          </p>
          <div className='absolute end-0 top-0 p-1 rounded-full m-1 saturate-200 duration-200 hover:shadow-md hover:bg-neutral-300' 
               onClick={()=>setAccountToggle({...accountToggle,sign:false})} >  <CloseIcons/></div>
    
    {/* FORM & IMAGE */}
    <div className="  mt-5">
        
    { (show &&error) &&
          <div className='text-white rounded-full py-2 bg-error text-center'>{error}</div>
        }
        {(show && data) &&
          <div className='text-white rounded-full py-2 text-center text-bold bg-green-600'>{data?.message}</div>
        }

        <form onSubmit={handleSubmit(formSubmit)} >
        <div className='flex' >
        <div className=' h-5 w-8 mt-16 ' >+91</div>
        <div className='w-full mr-1' >
        <Input
            className="w-full ml-1 p-3 mt-5"
            type="number"
            id="phoneNumber"
            placeholder="phone number"
            register={{...register("phoneNumber")}}
            errorMessage = {errors.phoneNumber?.message}
            
          />
        </div>
        </div>
          <button
            className={`px-14 w-full tracking-wide py-3 bg-blue hover:bg-hover-blue rounded-full  mt-5 text-white hover:text-yellow mb-4 transition duration-500`}
            type="submit"
        
          >
           Log In
          </button>

        </form>
     
        <button type="button"  onClick={()=>setAccountToggle({...accountToggle,signup:true,sign:false})} >SIGNUP FOR A NEW ACCOUNT</button>

    </div>
    
  </section>
  <div className='fixed bottom-0 w-full -z-10' ><Footer/></div>
  
  </div>
  )
}

export default Login


