import React, { useEffect } from 'react'
import { allMessagesForContactUsActions } from '../../redux/actions/contactActiona/contactUsAction'
import { useDispatch, useSelector } from 'react-redux';
import { DateConverting } from '../../lib/Utils';
import Loading from '../../components/Loading';

const Tab2 = () => {
    const { data,loading } = useSelector(state=>state.getAllMessagesContactUsRed);
   
    const dispatch = useDispatch();
    useEffect(()=>{
     dispatch(allMessagesForContactUsActions())
    },[])
  return (
    <div>
      { loading ? <Loading/>:
        data?.allMessages?.map(x=>(
            <div key={x._id} className='border-1 m-3 text-start  py-6 mt-4 items-center justify-center  border-black' >
                <p><span className='text-2xl'>Name : </span>{x.name}</p>
                <p><span className='text-2xl'>Email : </span>{x.email}</p>
                <p><span className='text-2xl'>Phone number : </span>{x.phoneNumber}</p>
                <p><span className='text-2xl'>Message : </span>{x.message}</p>
                <p><span className='text-2xl'>CreatedAt : </span>{DateConverting(x.createdAt)}</p>
               
            </div>
        ))
      }
    </div>
  )
}

export default Tab2
