import React,{useEffect,useState} from 'react'
import useMediaQuery from '../components/hook/UseMediaQuery';


const SelectingQuestion = ({ setPage,data,allResponseData,path }) => {
    const mobile = useMediaQuery('(min-width: 600px)');
    const divArray = new Array(data?.totalItems).fill(null);
    const [questionNumbers, setQuestionNumbers] = useState([]);
    const addQuestionNumber = (questionNo) => {
      if (!questionNumbers.includes(questionNo)) {
        // If not found, update the state by appending the new questionNo
        setQuestionNumbers(prevNumbers => [...prevNumbers, questionNo]);
      }
    };

    useEffect(()=>{
      
      allResponseData?.data?.gettingResponses?.forEach(x => {
        if( x.chapter === path ) {
           addQuestionNumber(x.questionNo);
        }
       
      });
    },[allResponseData,path,questionNumbers]);
 
  return (
    <div>
        <div className={`mt-0 overflow-auto container whitespace-nowrap flex ${mobile&&'grid'} grid-cols-10 grid-flow-row gap-1`} >
    {divArray.map((_, i) => (
        <div key={i} onClick={()=>setPage( i + 1 )} >
          <div className={`w-[39px] h-[39px] border-1 flex justify-center items-center  cursor-pointer rounded ${i+1 === data?.results[0].questionNo && "border-3 border-lime-600 text-lime-600"} ${questionNumbers.includes(i+1)? 'bg-grey text-white':'bg-white'}`} >{i + 1}</div>
        </div>
      ))}
      </div>
    </div>
  )
}

export default SelectingQuestion
