import React, { useEffect, useState } from 'react'
import { InlineMath } from 'react-katex';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination';
import { GoBackIcon } from '../../../icons/Icon';
import { reviseConceptOfMaxima } from '../../../redux/actions/mathsActions/applicationOfDerivative/maximaMinima/maximaAction';
import { reviseConceptOfTangent } from '../../../redux/actions/mathsActions/applicationOfDerivative/tangentNormal/tangentAction';
import ReviseTab from './ReviseTab';

const Tab2 = ({setPage2,selectedPage,page2,path}) => {
  const {data,loading} = useSelector(state=>path==='tangent-normal'?state.examplesOfTangentRed:state.examplesOfMaximaRed);
  const [revise,setRevise] = useState(false);
  const dispatch = useDispatch();
  
  const revisedConcept=(id)=>{
    if(!revise){
       if(path==='tangent-normal'){
         dispatch(reviseConceptOfTangent(id));
       }else{
        dispatch(reviseConceptOfMaxima(id));

       }
      
       setRevise(true);
      
    }
   
   
  }
  return (
    <div className='relative' >
     <main className=' mt-[180px]  flex justify-center items-center text-gray-900'>
     
     <div className='ml-1 w-[1050px]'>
   {
   loading?<Loading/>:
   path==='tangent-normal'?
  (
    data?.results?.map(x=>(
      <div className='definition relative rounded-md  z-30 cursor-pointer' key={x._id} >
         <div className='' >
              <h2 style={{color:"#005DFF"}}> {x.heading}</h2>
               <div className='flex items-center mb-2 justify-between' >
                <div className='flex items-center mb-2 '>
                <div className='mr-3'>{x.Qno}.</div>
                <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' style={{color:"#005DFF"}} >  <InlineMath displayMode={true} options={{strict: false}} math={x.question} errorColor={'#cc0000'} /></div>
                
                </div>
                <div className='mr-2'>{x.image&&<img src={x.image} alt="nothing" width='200px' />}</div>
                </div>
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >
                     <InlineMath displayMode={true} options={{strict: false}} math={x.solution} errorColor={'#cc0000'} />
                  </div>
                </div>
                <div className='absolute right-2 bottom-2 bg-gray2 text-white py-2 px-3  rounded flex' onClick={()=>revisedConcept(x.conceptId)} >
                      <GoBackIcon/>
                </div>
                </div>

               ))
  ):(
    <div>
       {
        data?.results?.map(x=>(
                <div className='definition relative' key={x._id} >
                    <h2 style={{color:"#005DFF"}}> {x.heading}</h2>
                     <div className='flex'>
                          <div className='fs-6 mr-3 ' >{x.Qno}</div>
                          <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>  <InlineMath displayMode={true} options={{strict: false}} math={x.question} errorColor={'#cc0000'} /></div>
                     </div>
                 
                     <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>
                          <InlineMath displayMode={true} options={{strict: false}} math={x.solution} errorColor={'#cc0000'} />
                          <div><img src={x.image} alt="" width='390px' /></div>
                          <InlineMath displayMode={true} options={{strict: false}} math={x.solution1} errorColor={'#cc0000'} />
                          
                     </div>
                     <div>
                          <div><img src={x.image2} alt="" width='400px' /></div>
                        <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' ><InlineMath displayMode={true} options={{strict: false}} math={x.solution2} errorColor={'#cc0000'} /></div>  
                          
                     </div>
                     <div className='absolute right-2 bottom-2 bg-gray2 text-white rounded px-3 py-2 flex' onClick={()=>revisedConcept(x.conceptId)} >
                     <GoBackIcon/>
                   </div>
                </div>
               
            ))
         }
    </div>
  )
   
         }
         {!revise &&
         <div className={`mb-3 mt-[18px] ${selectedPage==="examples"?'fixed top-[137px] right-2 items-center left-25 flex justify-center':''} z-40`}>
          <Pagination setPage2={setPage2} page2={page2} selectedPage={selectedPage} exampletData={data}  />
         </div>
         }
     </div>
   
</main>

<div>
  { revise && <div><ReviseTab setRevise={setRevise} path={path} selectedPage={selectedPage} /></div> }
</div>

    </div>
  )
}

export default Tab2