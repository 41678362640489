
export const physicsTestSeriesRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'TEST_SERIES_REQUEST':
            return {...state,loading:true}
        case 'TEST_SERIES_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TEST_SERIES_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const ResponseTestSeriesRed=(state={data:[]},action)=>{
    switch(action.type){
        case 'TEST_SERIES_RESPONSE_REQUEST':
            return {...state,loading:true}
        case 'TEST_SERIES_RESPONSE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TEST_SERIES_RESPONSE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//ALLDATA for mains

export const ResponseAllDataTestSeriesRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'TEST_SERIES_RESPONSE_ALLDATA_REQUEST':
            return {...state,loading:true}
        case 'TEST_SERIES_RESPONSE_ALLDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TEST_SERIES_RESPONSE_ALLDATA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

// sidebar


export const physicsTestSeriesSidebarRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'TEST_SERIES_SIDEBAR_REQUEST':
            return {...state,loading:true}
        case 'TEST_SERIES_SIDEBAR_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'TEST_SERIES_SIDEBAR_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}