import React from 'react'
import { MenuIcons } from '../icons/Icon'
import useMediaQuery from './hook/UseMediaQuery';
import mylogo from '../mylogo.png';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';


const Navbar = ({ setNavbarMenu,navbarMenu }) => {
  const isAbovemediumScreen = useMediaQuery('(min-width: 1060px)');
  const mobile = useMediaQuery('(min-width: 560px)');
  const [path,setPath] = useState('');
  const location = useLocation();
  
  useEffect(() => {
    const pat = location.pathname.split('/');
      setPath(pat[1]);
      
      //setSubject(pat[3].split('-').join(' ').charAt(0).toUpperCase()+ pat[3].split('-').join(' ').slice(1));
   }, [location]);
  return (
    <nav id='navbar' className={`z-40 flex justify-end  bg-deep-blue ${navbarMenu?'ml-[300px]':'ml-[0px]'} ease-in-out duration-300 `} >
      <div className={`py-3 w-full flex justify-between text-white text-[18px] sm:text-[25px] font-semi-bold font-playfair`} >
       
       {/* <img src={mylogo} alt="" className='rounded-full w-[48px] ml-4 h-[48px]' /> */}
       <h1 className='ml-4' >{mobile?<div className='flex items-center justify-center' ><img src={mylogo} alt="" className='w-[45px] h-[45px] rounded-full ' /><span className='border-l-4 border-blue px-2 ml-2' >  whiteBoardBlackPen</span> </div>: <img src={mylogo} className='w-[42px] h-[42px] rounded-full' alt="" /> }</h1>  

     {path!=='challenging-problems' && !isAbovemediumScreen&&<div className='mr-4 cursor-pointer flex items-center ' onClick={()=>setNavbarMenu(true)} ><MenuIcons /></div> } 
      </div>
    </nav>
  )
}

export default Navbar
