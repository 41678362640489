import React from 'react'
import { Link } from 'react-router-dom'

const ApplicationDerivative = () => {
  return (
    <div className='relative ' >
    <Link to='tangent-normal' >
        <div className=' mt-11 max-w-full  ml-4 mr-2' >
           <div className='examples rounded-lg text-1xl ss:text-2xl hover:bg-sideScroll duration-300 hover:text-white text-black flex mt-6 font-serif font-semi p-4 ss:p-7'>Tangent and Normal and dy/dx as Rate of Measure</div>
        </div>
    </Link>
    <Link to='maxima-minima' >
        <div className='max-w-full  ml-4 mr-2' >
           
           <div className='examples relative rounded-lg duration-300 hover:bg-sideScroll hover:text-white p-4 ss:p-7  mt-2 cursor-pointer text-grey font-serif font-semi text-1xl ss:text-2xl' >Maxima and Minima of a Function</div>
        </div>
    </Link>
  </div>
  )
}

export default ApplicationDerivative
