import  axios  from "axios";


let url = 'https://whiteboardblackpen-tge6.onrender.com/applicationOfderivativemaxima';
//let url = 'http://3.110.197.80/api/applicationOfderivativemaxima';
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/applicationOfderivativemaxima`
const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });
export const conceptOfMaxima=(page)=>async(dispatch)=>{
    dispatch({type:"MAXIMA_CONCEPT_REQUEST"});
    try {
     const {data} = await API.get(`/concept?page=${page}&limit=${2}`);
     dispatch({type:"MAXIMA_CONCEPT_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MAXIMA_CONCEPT_FAIL"});
    }
  }
  
  // examples
  
  export const exampleOfMaxima=(page2)=>async(dispatch)=>{
    dispatch({type:"MAXIMA_EXAMPLE_REQUEST"});
    try {
     const {data} = await API.get(`/example?page=${page2}&limit=${2}`);
     dispatch({type:"MAXIMA_EXAMPLE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MAXIMA_EXAMPLE_FAIL"});
    }
  }


  export const reviseConceptOfMaxima=(id)=>async(dispatch)=>{
    dispatch({type:"MAXIMA_REVISE_REQUEST"});
   
    try {
     const {data} = await API.post(`/revise`,{ id });
     dispatch({type:"MAXIMA_REVISE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MAXIMA_REVISE_FAIL"});
    }
  }

//more
  export const moreQuestionOfMaximaAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_QUE_MAXIMA_REQUEST"});
   
    try {
      const {data} = await API.get(`/more/question?page=${page}&limit=${12}`);
      dispatch({type:"MORE_QUE_MAXIMA_SUCCESS",payload:data})
    } catch (error) {
       dispatch({type:"MORE_QUE_MAXIMA_FAIL"});
    }
  }
  
  export const moreAnswerOfMaximaAction=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_ANS_MAXIMA_REQUEST"});
   
    try {
     const {data} = await API.get(`/more/answer?page=${page}&limit=${12}`);
    
     dispatch({type:"MORE_ANS_MAXIMA_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"MORE_ANS_MAXIMA_FAIL"});
    }
  }