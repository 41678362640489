import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch,useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../scenes/inputForContact';
import { getOtpActions } from '../../../redux/actions/Auth/AuthAction';
import { CloseIcons } from '../../../icons/Icon';
import { Footer, SignSignupNavbar } from '../scenes/Footer';
import Loading from '../../../components/Loading';
import CheckOtpOfAccountCreation from './CheckOtpOfAccountCreation';

const schema =yup.object({
  name:yup.string().required("name is a required field"),
  phoneNumber:yup
  .string()
  .required("phone number is a required field") 
  .matches(
    /^[0-9]{10}$/,"Phone number is not valid"
  ),
  
});

const AccountCreation = ({setAccountToggle,accountToggle}) => {

  const [show,setShow] = useState(false);
  const getOtp = localStorage.getItem('userGetOtp');
  const { data,loading,error }  = useSelector(state=>state.getOtpRed);
  const { handleSubmit ,
          register,
          formState:{ errors }
        } =  useForm({
    resolver:yupResolver(schema)
  });
   
    const dispatch = useDispatch();
    
    const formSubmit =(inputData)=>{
     
       dispatch(getOtpActions(inputData));
       setShow(true)
       setTimeout(()=>{
        setShow(false)
       },[5000]);
     
    }

    if( getOtp ){
      return (
        <div>
        <CheckOtpOfAccountCreation setAccountToggle = {setAccountToggle} accountToggle = {accountToggle}  />
      </div>
      )
      
    }
   
    if(loading){
      return (
        <div><Loading /></div>
      )
    }
    
  return (
    <div className='relative min-h-[100vh] truncate' >
    <div className='fixed top-0 right-0 w-full' >
   <SignSignupNavbar />
   </div>
    <section id="contact" className="relative top-16 otpGenerate bg-white border-[0.6px] border-gray-400 ss:max-w-[370px] max-w-[350px] mx-auto py-28">
    {/* HEADINGS */}
         <p className="font-playfair font-semibold text-4xl mt-3 text-center">
           WEL <span className="text-blue">COME</span>
          </p>
          <div className='absolute end-0 top-0 p-1 rounded-full m-1 saturate-200 duration-200 hover:shadow-md hover:bg-neutral-300' 
               onClick={()=>setAccountToggle({...accountToggle,signup:false})} >  <CloseIcons/></div>
    
     
    {/* FORM & IMAGE */}
    <div className="relative mt-5">
     
      
        { (show &&error) &&
          <div className='text-white text-[14px] rounded-full py-2 bg-error text-center'>{error}</div>
        }
        {(show && data) &&
          <div className='text-white rounded-full py-2 text-center text-bold bg-green-600'>{data?.message}</div>
        }
        
        <form onSubmit={handleSubmit(formSubmit)} >
       
          <Input
            className="w-full  outline-none font-semibold placeholder-opaque-black p-3"
            id='name'
            type="text"
            placeholder="NAME"
            register={{...register("name")}}
            errorMessage = {errors.name?.message}
            
          />
         <div className='flex' >
          <div className=' h-5 w-8 mt-16 ' >+91</div>
          <div className='w-full mr-1' >
          <Input
            className="w-full mx-8 font-semibold  outline-none  placeholder-opaque-black p-3 mt-5"
            type="number"
            id="phoneNumber"
            placeholder="phone number"
            register={{...register("phoneNumber")}}
            errorMessage = {errors.phoneNumber?.message}
           />
          </div>
          </div>
          <button
            className={`w-full px-14 py-3 rounded-full  border bg-blue text-white mt-5 hover:bg-hover-blue hover-border-none  hover:text-footer-light mb-4 transition duration-500`}
            type="submit"
        
          >
            GET OTP
          </button>
        </form>
       
        <button type="button"  onClick={()=>setAccountToggle({...accountToggle,signup:false,sign:true})} >I ALREADY HAVE AN ACCOUNT</button>
     
     
    </div>
  </section>
  <div className='fixed bottom-0 w-full -z-10' ><Footer /></div>
  </div>
  )
}

export default AccountCreation

