import moment from 'moment';

export const Capatilized = (data) =>{
    return data?.results[0]?.chapter.split('-').join(' ').charAt(0).toUpperCase()+data?.results[0]?.chapter.split('-').join(' ').slice(1);
    
}

// date converting for created At
export const DateConverting = (date) => {
   
    const formattedDate = moment(date).format('MMMM Do, YYYY [at] h:mm:ss A');
    return formattedDate
}

export const formatTime = (seconds) => {
    if (seconds <= 0) return '00:00:00';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };


 export function getTimeDifference(timestamp) {
    const createdAt = moment(timestamp);
    const now = moment();

    const diffInYears = now.diff(createdAt, 'years');
    if (diffInYears > 0) {
        return `${diffInYears} y`;
    }

    const diffInMonths = now.diff(createdAt, 'months');
    if (diffInMonths > 0) {
        return `${diffInMonths} mo`;
    }

    const diffInDays = now.diff(createdAt, 'days');
    if (diffInDays > 0) {
        return `${diffInDays} d`;
    }

    const diffInHours = now.diff(createdAt, 'hours');
    if (diffInHours > 0) {
        return `${diffInHours} h`;
    }

    const diffInMinutes = now.diff(createdAt, 'minutes');
    if (diffInMinutes > 0) {
        return `${diffInMinutes} m`;
    }

    const diffInSeconds = now.diff(createdAt, 'seconds');
    return `${diffInSeconds} s`;
}

// Example usag
 // Output: e.g., "16 h", "16 m", "16 s", "16 y"