import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar2 from '../../../components/Navbar2'
import Jeeadvance from './Jee/Jeeadvance';
import JeeMains from './Jee/Jeemains';
import { useDispatch } from 'react-redux'

import { advanaceOfIndifiniteDatas, jeemains } from '../../../redux/actions/mathsActions/mathsactions';
import SawalSidebar from '../../../components/SawalSidebar';
import { advanceOfDefinite, jeemainsOfDefinite } from '../../../redux/actions/mathsActions/definiteIntegral/conceptDefiniteAction';

const concepts = 'Jee Mains'
const examples = 'Jee Advance';

const PreviousPaper = () => {
    
    const [selectedPage,setSelectedPage] = useState('jee mains');
    const [subject,setSubject] = useState("Jee Mains");
    const [page3,setPage3] = useState(1)
    const [page5,setPage5] = useState(1)
    
    //const subject=useRef("Jee Mains")
    const [sidebarShow,setSidebarShow] = useState(true)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [pathName,setPathName] = useState('');
    //console.log(difiniteDataForConcept,'bhbhh');
    const location = useLocation();
    useEffect(()=>{
        const path = location.pathname.split('/');
    setPathName(path[4]);
    //console.log(path[4],'lklkl');
    },[pathName,location])
    useEffect(() => {
    
   
      if(selectedPage==='jee mains'){ 
        if(pathName==='indefinite-integrals'){
          dispatch(jeemains(page3));
          
        }
        else if(pathName==='definite-integrals'){
           dispatch(jeemainsOfDefinite(page3));
          
        }

      }
      if(selectedPage === 'jee advance'){
        if(pathName==='indefinite-integrals'){
        dispatch(advanaceOfIndifiniteDatas(page5))
        }
        else if(pathName==='definite-integrals'){
          dispatch(advanceOfDefinite(page5))
        }
       }
       
    }, [page3,page5,selectedPage,pathName,dispatch])
    const pageSelected1=()=>{
      
       setSelectedPage("jee mains");
       setSubject("Jee Mains")
       navigate('?&activeTab=jeemains', { replace:true }) 
      
    }
    const pageSelected2=()=>{
        
         setSelectedPage("jee advance");
         setSubject("Jee Advance")
        navigate('?&activeTab=jeeadvance',{replace:true}) 
    }

  return (
    <div>
    {/* this sidebar for bigger screen */}
    <div className='md:block hidden z-50 h-full' ><SawalSidebar setPage3={setPage3} setPage5={setPage5} selectedPage={selectedPage} page3={page3} page5={page5}  pathName={pathName} /></div>
     
     {!sidebarShow&& <div className='z-50 h-full' ><SawalSidebar setSidebarShow={setSidebarShow} setPage3={setPage3} selectedPage={selectedPage} page3={page3} page5={page5} setPage5={setPage5}  pathName={pathName} /></div>}
     
      <nav>
        <Navbar2 concepts={concepts} examples={examples} sidebarShow={sidebarShow} setSidebarShow={setSidebarShow}
           subject={subject}  pageSelected1={pageSelected1} pageSelected2={pageSelected2} selectedPage={selectedPage} 
         />
     </nav>
       <div>
         {selectedPage==="jee mains"?<JeeMains page3={page3} setPage3={setPage3} selectedPage={selectedPage} pathName={pathName} />:<Jeeadvance page5={page5} setPage5={setPage5} selectedPage={selectedPage} pathName={pathName} />}
       </div>
   </div>   
  )
}

export default PreviousPaper
