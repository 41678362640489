import React from 'react'
import { getTimeDifference } from '../../../lib/Utils';


const NestedComments = ({ nestedPost }) => {
   

  return (
    <div>
          {
            nestedPost?.commentsOnComments?.map(x=>(
               <div key={x._id}>
                 <div className='bg-gray-300 inline-block p-2 ml-8 rounded-md'  >
                   <div className=' ' >
                     <p className='font-bold'>{x?.postedOnComment?.name===process.env.REACT_APP_ADMIN_SECUIRITY?"Sahil Bhayya":x?.postedOnComment?.name}</p>
                     <p className="">{x.text}</p>
                     </div>
                   
                    </div>
                    <p className='text-gray-500 ml-8 mb-2 cursor-pointer'>{getTimeDifference(x?.createdAt)}</p>
                    </div> 
            ))

           }
                       
            
    </div>
  )
}

export default NestedComments
