import React from 'react'
import { Capatilized } from '../../lib/Utils'


const TabToggle = ({selectedPage,pageSelected1,pageSelected2,concepts,examples,data}) => {
 
  return (
    <div>
     
     <div className=' py-5 bg-slate-100'>
     {/* <Link to="/learn" className='absolute top-2 left-2' ><Button variant="contained" size="small">back</Button></Link> */}
       <div className='ss:flex justify-between mx-16' >
         <p className='text-2xl'>JEE-{data?.results[0]?.class}-{Capatilized(data)}</p>
        <p>Total Time: 1:00hrs | Total Marks: {data?.results[0]?.totalQuestions * 4}</p>
       </div>
       
     
    <div className='items-center justify-center topics relative flex top-5'>
         <div className='flex mb-3 ml-2  text-[15px] sm:text-[19px]' >
        <div className={`${(selectedPage==='concepts')&&'actives'} bg-gray `} onClick={pageSelected1} >{concepts}</div>
        <div className={`duration-300 ${(selectedPage==='examples')&&'actives'} `} onClick={pageSelected2}>{examples}</div>
        
      </div>
     
    </div>
   
    </div>
  </div>
  )
}

export default TabToggle
