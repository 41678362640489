import React from 'react';
import { motion } from "framer-motion";
import useMediaQuery from '../../../../components/hook/UseMediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Youtube = () => {
    const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <div>
       <div className="md:flex max-w-[1100px] items-center mx-auto md:justify-between md:gap-8 md:mt-20">
       <motion.div
          className="md:w-1/3 "
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-serif font-bold text-5xl text-blue text-center mt-20 md:mt-2   ">
            YOU <span className="text-black">TUBE</span>
          </p>
        
          <p className="mt-3 max-w-[280px] xs:max-w-[400px] mx-auto mb-7 text-center font-serif">
            There is a my YouTube channel <span className='text-blue' >(Click on YouTube logo)</span> in which, i teach physics.
            I cover core concepts and problems and the level is from zero to advance . i also give the solution of IRODOV,HC VERMA CENGAGE(bm sharma),DC PANDEY,PREVIOUS YEARS e.c.t 
          </p>
        </motion.div>
        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
           <a  href="https://www.youtube.com/watch?v=G5_4pbLDY50">
           <LazyLoadImage
              src="assets/youtube.png"
              alt='assets/youtube.png'
              //effect='blur'
              placeholderSrc="assets/youtube.png"
              threshold='100'
              className=" hover:filter hover:saturate-200 transition duration-500 w-full max-w-[300px] md:max-w-[400px] mr-3"
             />
              
              </a>
           
          ) : (
            <div className=' flex justify-center'>
            <a href="https://www.youtube.com/watch?v=G5_4pbLDY50">
              <LazyLoadImage
              src="assets/youtube.png"
              alt='assets/youtube.png'
              //effect='blur'
              placeholderSrc="assets/youtube.png"
              threshold='100'
              className=" hover:filter hover:saturate-200 transition duration-500 w-full max-w-[300px] sm:max-w-[450px]"
             />
               </a>
            </div>
          )}
        </div>
       
    </div>
    
    </div>
  )
}

export default Youtube