import axios from 'axios';

const url = 'https://whiteboardblackpen-tge6.onrender.com/mock-test'
//const url = 'http://localhost:8000/mock-test'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/mock-test`


export const MockTestAllQuestionsAction = (page,chapter)=>async(dispatch)=>{
   
    dispatch({type:"MOCK_TEST_REQUEST"});
    try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
     const {data} = await axios.get(`${url}/total-questions/${chapter}?page=${page}&limit=${1}`,{
        headers:{
            Authorization : `Bearer ${userInfo.token}`,
            'Content-Type': "application/json",
        },
        
     });
     dispatch({type:"MOCK_TEST_SUCCESS",payload:data})
    } catch (error) {
      dispatch({type:"MOCK_TEST_FAIL",payload:error});
    }
  }


export const responseAnswerOfMockTestAction=(response,id)=>async(dispatch)=>{
    dispatch({type:"MOCK_TEST_RESPONSE_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.post(`${url}/response`,{response,id},{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
    
     dispatch({type:"MOCK_TEST_RESPONSE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MOCK_TEST_RESPONSE_FAIL"});
    }
  }


   // all data for response
  export const responseAnswerOfMockTestAllDataAction = (chapter) => async(dispatch) => {
    dispatch({type:"MOCK_TEST_RESPONSE_ALLDATA_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/total-response/${chapter}`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
    
     dispatch({type:"MOCK_TEST_RESPONSE_ALLDATA_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"MOCK_TEST_RESPONSE_ALLDATA_FAIL"});
    }
  };

  export const getAnswerSheetsMockTestAllDataAction = (chapter) => async(dispatch) => {
    dispatch({type:"MOCK_TEST_ANSWER_SHEETS_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const { data } = await axios.get(`${url}/answer-sheets/${chapter}`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
    
     dispatch({type:"MOCK_TEST_ANSWER_SHEETS_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"MOCK_TEST_ANSWER_SHEETS_FAIL"});
    }
  }

// update answer
  export const responseAnswerOfMockTestUpdateAction = (response,questionId) => async(dispatch) => {
  
    dispatch({type:"MOCK_TEST_RESPONSE_UPDATE_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.patch(`${url}/response-update`,{response,questionId},{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
    
     dispatch({type:"MOCK_TEST_RESPONSE_UPDATE_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"MOCK_TEST_RESPONSE_UPDATE_FAIL"});
    }
  }

  // get final results

  export const getResultsMockTestAction = ( chapter ) => async(dispatch) => {
 
    dispatch({type:"MOCK_TEST_GET_RESULTS_REQUEST"});
   try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const { data } = await axios.get(`${url}/get-final-result/${chapter}`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
     }
     });
   
   
     dispatch({type:"MOCK_TEST_GET_RESULTS_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"MOCK_TEST_GET_RESULTS_FAIL"});
    }
  }


  export const resultsMockTestAction = (chapter,timeTaken) => async(dispatch) => {
    dispatch({type:"MOCK_TEST_RESULTS_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const { data } = await axios.post(`${url}/final-results`,{chapter,timeTaken},{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
     }
     });
   
   
     dispatch({type:"MOCK_TEST_RESULTS_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"MOCK_TEST_RESULTS_FAIL"});
    }
  }

  // TIMERRR

  export const timerMockTestAction = (setRemainingTime,chapter) => async(dispatch) => {
    dispatch({type:"MOCK_TEST_TIMER_REQUEST"});
    
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/timer/${chapter}`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
   
     setRemainingTime(data.time);

     dispatch({type:"MOCK_TEST_TIMER_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"MOCK_TEST_TIMER_FAIL"});
    }
  }

