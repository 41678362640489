import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch,useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../scenes/inputForContact';
import { checkOtpActions, getOtpActions } from '../../../redux/actions/Auth/AuthAction';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../scenes/Footer';

const schema =yup.object({
    textOtp:yup
    .string()
    .required("Enter 6 digits OTP") 
    .matches(
      /^[0-9]{6}$/,"OTP is not valid"
    ),
    
  })

const CheckOtpOfAccountCreation = ({setAccountToggle , accountToggle}) => {
  const [show,setShow] = useState(false);
  const [show1,setShow1] = useState(false);
  const [counter, setCounter] = React.useState(60);
  const resendOtp  = useSelector(state=>state.getOtpRed);
  //const [menuNavbar,setMenuNavbar]=useState(false);
  const [timerShow,setTimerShow] = useState(false)
  const { data,loading,error }  = useSelector(state=>state.checkOtpRed);
  const userData = localStorage.getItem('userInfo');
  const getOtp = JSON.parse(localStorage.getItem('userGetOtp'));
  const { handleSubmit ,
          register,
          formState:{ errors }
        } =  useForm({
    resolver:yupResolver(schema)
  });
  
    const dispatch = useDispatch()
    const history = useNavigate()
    const formSubmit =(inputOtp)=>{

       dispatch(checkOtpActions({...inputOtp,phoneNumber:getOtp?.phoneNumber}));
       setShow(true);
       setTimeout(()=>{
        setShow(false);
       },[3000]);
      
       
    }
    
    
    React.useEffect(() => {
     if(counter>0){
      setTimeout(() => setCounter(counter - 1), 1000);
      
     }
      
    }, [counter]);
  
    useEffect(() => {
      if(userData){
        setAccountToggle({...accountToggle,signup:false})
        history('/learn');
        
      }
    if(!getOtp){
      history('/');
    }
    }, [userData,getOtp,history]);
    
    // if(accountToggle.sign){
    //   return (
    //      <div><Login setLoginAfterVerify={setLoginAfterVerify} setAccountToggle={setAccountToggle} accountToggle={accountToggle} /></div>
      
    //   )
    // }
  return (
    <div className='relative  min-h-[100vh]'>
    {/* <div className=' absolute top-0' > <Navbar setAccountToggle={setAccountToggle} menuNavbar={menuNavbar} /></div>
    */}
    <section id="contact" className="relative top-20 otpGenerate bg-white max-w-[370px] border-[0.6px] border-gray-400 mx-auto py-28">
         <p className="font-playfair font-semibold text-3xl mt-3 text-center">
         Please verify  <span className="text-blue">your number</span>
          </p>
        <p className='text-center text-deep-blue'>We have sent an OTP to your mobile number</p>
    
        <p className='text-center relative top-2' >{getOtp?.phoneNumber}</p>

        {/*  Removal things  */}
        {  resendOtp?.data && 
           <p className='relative text-center top-2 text-green-700' >YOUR OTP : {resendOtp?.data?.otp}</p>
        }
       
    {/* FORM & IMAGE */}
    <div className="  mt-5">
        
    { (show &&error) &&
          <div className='text-white rounded-full py-2 bg-error text-center'>{error}</div>
        }
        {(show && data) &&
          <div className='text-white rounded-full py-2 text-center text-bold bg-green-600'>{data?.message}</div>
        }
        { (show1 &&resendOtp?.error) &&
          <div className='text-white rounded-full py-2 bg-error text-center'>{resendOtp?.error}</div>
        }
        {show1 && (resendOtp?.data) &&
          <div className='text-white rounded-full py-2 text-center text-bold bg-green-600'>{resendOtp?.data?.message}</div>
        }
       
        <form onSubmit={handleSubmit(formSubmit)} className='relative' >
        <div className='absolute right-0 top-16 hover:text-blue' > 
        <button type="button" onClick={()=> {
         
          if(counter<=0){
            dispatch(getOtpActions({phoneNumber:getOtp?.phoneNumber,name:getOtp?.name}));
            setCounter(60);
            setShow1(true);
            setTimerShow(true);
            setTimeout(()=>{
            setShow1(false);
       },[6000]);
         } 
          
        }}
         >Resend OTP
        </button>
         </div>
        <div className='absolute left-3 bottom-[104px] text-sky-500' > {timerShow&&counter}</div>
        
          <Input
            className="w-full mx-8 border-b-2  outline-none font-semibold placeholder-opaque-black p-3 mt-5"
            type="number"
            id="textOtp"
            placeholder="OTP"
            register={{...register("textOtp")}}
            errorMessage = {errors.textOtp?.message}
            
          />
        
     
          <button
            className={`px-14 w-full tracking-wider py-3 rounded-full relative border bg-blue  mt-5 hover:bg-hover-blue hover-border-none text-white mb-4 transition duration-500`}
            type="submit"
            
            disabled={data?.message && true}
          >
             <span>{loading?<div className='button--loading py-[12px]'></div>:'Verify' }</span> 
          </button>
        </form>
    

    </div>
  </section>
    
     <div className='absolute bottom-0 w-full'> <Footer /></div>
    
  </div>
  )
}

export default CheckOtpOfAccountCreation


