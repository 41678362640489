import React from 'react'
import useMediaQuery from '../components/hook/UseMediaQuery';
import { CloseIcons, MenuIcons } from '../icons/Icon'
import mylogo from '../mylogo.png'

const NavbarTest = ({ setNavbarMenu,navbarMenu }) => {
  const isAbovemediumScreen = useMediaQuery('(min-width: 1060px)');
  const mobile = useMediaQuery('(min-width: 550px)');
 
  return (
    <div>
       <nav id='navbar' className={` flex justify-end bg-gray2 md:ml-[400px] duration-100`} >
      <div className={`py-3 w-full flex justify-between text-white text-[18px] sm:text-[25px] font-semi-bold font-playfair`} >
       
       <h1 className='ml-4' >{mobile?<div className='flex items-center justify-center' ><img src={mylogo} alt="" className='w-[45px] h-[45px] rounded-full ' /><span className='border-l-4 border-blue px-2 ml-2' >  whiteBoardBlackPen</span> </div>: <img src={mylogo} className='w-[42px] h-[42px] rounded-full' alt="" /> }</h1>  
       { !isAbovemediumScreen&&<div className='mr-4 cursor-pointer' onClick={()=>setNavbarMenu(!navbarMenu)} >{!navbarMenu ?<CloseIcons/>:<MenuIcons />}</div> }
      </div>
    </nav>
    </div>
  )
}

export default NavbarTest


//  ${navbarMenu?'ml-[0px]':'ml-[400px]'}