import axios from 'axios';

//const url =   'http://localhost:8000';
const url = 'https://whiteboardblackpen-tge6.onrender.com'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}`

// ADMIN  all usrs for sign in 
export const isAdminAllUsrsActions=()=>async(dispatch)=>{
  dispatch({type:"ISADMIN_FETCHDATA_REQUEST"})
    try {
      const userInfo=  JSON.parse(localStorage.getItem('userInfo'))
      const { data } = await axios.get(`${url}/auth/admin`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
     dispatch({type:"ISADMIN_FETCHDATA_SUCCESS",payload:data});
    
    } catch (error) {
    
      dispatch({type:"ISADMIN_FETCHDATA_FAIL",
      payload:
      error.response&&error.response.data.message
      ?error.response.data.message
      :error.message});
    }
  }


  export const isAdminDeleteUsrsActions=(id)=>async(dispatch)=>{
    dispatch({type:"ISADMIN_DELETE_REQUEST"});
   
    try {
    
      const userInfo=  JSON.parse(localStorage.getItem('userInfo'));
     
       await axios.delete(`${url}/auth/admin/delete`,{
      headers:{
        'Content-Type': "application/json",
        Authorization : `Bearer ${userInfo.token}`,
       
    },
    data:{id:id},
     });
 
     dispatch({type:"ISADMIN_DELETE_SUCCESS",payload:id});
    
    } catch (error) {
    
      dispatch({type:"ISADMIN_DELETE_FAIL",
      payload:
      error.response&&error.response.data.message
      ?error.response.data.message
      :error.message});
    }
  }


  // GET LOGGED IN USERS


  export const getAllLoggedInUsersActions = () => async (dispatch) => {
    dispatch({type:"LOGGED_IN_USERS_REQUEST"})
      try {
        const userInfo=  JSON.parse(localStorage.getItem('userInfo'))
       const { data } = await axios.get(`${url}/auth/get-all-logged-in-user`,{
        headers:{
          Authorization : `Bearer ${userInfo.token}`,
          'Content-Type': "application/json",
      }
       });
       dispatch({type:"LOGGED_IN_USERS_SUCCESS",payload:data});
      
      } catch (error) {
      
        dispatch({type:"LOGGED_IN_USERS_FAIL",
        payload:
        error.response&&error.response.data.message
        ?error.response.data.message
        :error.message});
      }
    }


    export const getAllCodingGuruUsersActions = () => async (dispatch) => {
      dispatch({type:"CODING_GURU_USERS_REQUEST"})
        try {
          const userInfo=  JSON.parse(localStorage.getItem('userInfo'))
         const { data } = await axios.get(`${url}/contact-us/coding-guru-get-all-messages`,{
          headers:{
            Authorization : `Bearer ${userInfo.token}`,
            'Content-Type': "application/json",
        }
         });
         dispatch({type:"CODING_GURU_USERS_SUCCESS",payload:data});
        
        } catch (error) {
        
          dispatch({type:"CODING_GURU_USERS_FAIL",
          payload:
          error.response&&error.response.data.message
          ?error.response.data.message
          :error.message});
        }
      }