import axios from 'axios';

const url = 'https://whiteboardblackpen-tge6.onrender.com/30Test'
//const url = 'http://localhost:8000/30Test'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/30Test`
const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });
export const testSeriesChapterName = () => async(dispatch)=>{
    dispatch({type:"TEST_SERIES_CHAPTER_NAME_REQUEST"});
     try {
      const {data} = await API.get(`${url}/chaptername`);
      dispatch({type:"TEST_SERIES_CHAPTER_NAME_SUCCESS",payload:data})
     } catch (error) {
      dispatch({type:"TEST_SERIES_CHAPTER_NAME_FAIL"});
     
     }
  }