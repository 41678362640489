import React, { useEffect, useState } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../components/Loading';
import { testSeriesSidebarAction } from '../../../../redux/actions/Test/Physics/30Tests/ProjectileTestSeriesAction';

const TestSeriesSidebar2 = ({ selectedPage,page2,setPage2,path }) => {
    const { data,loading } = useSelector(state=>state.physicsTestSeriesSidebarRed);
    const [st,setSt] = useState(1);
    const pageset =(i)=>{
    setPage2(Number(i));
   }
   
   const dispatch = useDispatch();
   useEffect(() => {
    if(path===`${path}` &&((page2%5===1|| (st===1&&selectedPage==='examples')))){
       
        dispatch(testSeriesSidebarAction(Math.ceil(page2/5),2,`${path}`))
        setTimeout(()=>{
          setSt(2)
        },[1000])
    }

 }, [dispatch,selectedPage,page2 ])
  return (
    <section className={`bg-gray2 h-screen overflow-x-hidden overflow-y-scroll z-[1000] w-[300px] ss:w-[400px] p-10   top-0  absolute  duration-300`} >
    <div className={`z-40 bg-black w-[282px] ss:w-[382px] items-center flex justify-center rounded-md text-white  text-xl py-3 fixed top-2 left-2 ease-in-out duration-300`}>Test List of Questions</div>
     <div className=' gap-y-4 my-10' >
    
      {
          loading?<Loading />:
          (data?.results)?.map((x,i)=>(
          <div className={`flex gap-2 my-2 w-full-10/12 justify-start ${page2 === Number(x.Qno) && 'bg-active'} p-3 rounded-md hover:bg-active duration-100` } onClick={()=>pageset(x.Qno)} key={x._id} >
            
             <div className='text-white p-2 rounded-full bg-black h-6 w-6 flex items-center justify-center'>{x.Qno}</div>
             <div >
             <div className='text-white' >{x.question}</div>
             
             {x.question2 && <div className='text-[10px] -left-1 relative text-white ' >  <InlineMath math={x.question2} /></div>}
             </div>
          </div>
        ))
       }
     </div>
    </section>
  )
}

export default TestSeriesSidebar2
