import axios from 'axios';

//const url =   'http://localhost:8000';
  const url = 'https://whiteboardblackpen-tge6.onrender.com'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}`

export const getOtpActions=(inputDatas)=>async(dispatch)=>{
    dispatch({type:"GETOTP_REQUEST"});
   
    try {
     const { data } = await axios.post(`${url}/sendotp`,inputDatas);
     dispatch({type:"GETOTP_SUCCESS",payload:data});
    
     localStorage.setItem('userGetOtp',JSON.stringify(data))
    } catch (error) {
    
      dispatch({type:"GETOTP_FAIL",
      payload:
      error.response&&error.response.data.message
      ?error.response.data.message
      :error.message});
    }
  }

  export const checkOtpActions=(inputDatas)=>async(dispatch)=>{
    dispatch({type:"CHECKOTP_REQUEST"});
   
    try {
     const { data } = await axios.post(`${url}/checkotp`,inputDatas);
     dispatch({type:"CHECKOTP_SUCCESS",payload:data});
    // dispatch({type:"CONFIRM_OTP_LOGIN_SUCCESS",payload:data});
     localStorage.setItem('userInfo',JSON.stringify(data))
    } catch (error) {
    
      dispatch({type:"CHECKOTP_FAIL",
      payload:
      error.response&&error.response.data.message
      ?error.response.data.message
      :error.message});
    }
  }


  export const loginActions=(inputDatas)=>async(dispatch)=>{
    dispatch({type:"LOGIN_REQUEST"});
   
    try {
     const { data } = await axios.post(`${url}/login`,inputDatas);
    
     dispatch({type:"LOGIN_SUCCESS",payload:data});
     localStorage.setItem('confirmOtpInfo',JSON.stringify(data))
    } catch (error) {
    
      dispatch({type:"LOGIN_FAIL",
      payload:
      error.response&&error.response.data.message
      ?error.response.data.message
      :error.message});
    }
  }

// confirm otp during login

export const confirmOtpLoginFinalActions=(inputDatas)=>async(dispatch)=>{
  dispatch({type:"CONFIRM_OTP_LOGIN_REQUEST"});
 
  try {
   const { data } = await axios.post(`${url}/confirmotp`,inputDatas);
   dispatch({type:"CONFIRM_OTP_LOGIN_SUCCESS",payload:data});
   localStorage.setItem('userInfo',JSON.stringify(data));
   
  } catch (error) {
  
    dispatch({type:"CONFIRM_OTP_LOGIN_FAIL",
    payload:
    error.response&&error.response.data.message
    ?error.response.data.message
    :error.message});
  }
}
  //  delete-logged-in-user

// LOGOUT

  export const signout =(phoneNumber,history)=>async(dispatch)=>{
   // const history = useNavigate();
    dispatch({type:"USER_SIGNOUT"});

    dispatch({type:"REMOVE_LOGGED_IN_USR_REQUEST"});
 
    try {
      const userInfo=  JSON.parse(localStorage.getItem('userInfo'));
     
     const data= await axios.delete(`${url}/delete-logged-in-user`,{
     headers:{
       'Content-Type': "application/json",
       Authorization : `Bearer ${userInfo.token}`,
      
   },
     data:{phoneNumber:phoneNumber},
    });

     dispatch({type:"REMOVE_LOGGED_IN_USR_SUCCESS",payload:data});
     localStorage.removeItem('userInfo');
     localStorage.removeItem('userGetOtp');
     localStorage.removeItem('confirmOtpInfo');
     history('/',{replace:true});
    } catch (error) {
    
      dispatch({type:"REMOVE_LOGGED_IN_USR_FAIL",
      payload:
      error.response&&error.response.data.message
      ?error.response.data.message
      :error.message});
    }
}