import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import useMediaQuery from "../../../components/hook/UseMediaQuery";
import mylogo from '../../../mylogo.png'


const Navbar = ({isTopOfPage,accountToggle,setAccountToggle,menuNavbar }) => {
  const isAboveSmallScreen  = useMediaQuery("(min-width:1060px)");
  const mobile = useMediaQuery("(min-width:520px)");
  const [path, setPath] = useState(false);
  const navbarBackGround = isTopOfPage ? "":`${path==='about-us'?'bg-white shad':'bg-white shad'}`;
  
  
  const location = useLocation();

  useEffect(() => {
    const pat = location.pathname.split('/');
    setPath(pat[1]);
    
}, [path,location])
  return (
    <nav className={`${navbarBackGround}  z-[999]  w-full fixed top-0 py-[13px]`} >
      <div className='flex relative justify-between mx-auto items-center w-full ss:w-5/6' >
         <Link to="/" className={`font-playfair duration-300 text-[17px] ss:text-[24px] font-semibold ${isTopOfPage && path==='about-us'?'text-footer-light hover:text-white ':'text-blue hover:text-footer-light'} ml-5 ss:ml-0`} >{mobile?<div className='flex items-center justify-center' ><img src={mylogo} alt="" className='w-[45px] h-[45px] rounded-full ' /><span className='border-l-4 border-blue px-2 ml-2' >  whiteBoardBlackPen</span> </div>: <img src={mylogo} className='w-[42px] h-[42px] rounded-full' alt="" /> }</Link>
       
           
             {
              menuNavbar ?(
                <div className={`flex gap-4 absolute end-0 md:relative mr-12 ss:mr-1 justify-between w-[140px] ss:w-[230px]  font-playfair text-sm `} >
                  <Link to='/' className={`${isTopOfPage&&path==='about-us'?'text-footer-light hover:text-white':'text-blue hover:text-footer-light'} duration-300`} >
                     Learn
                  </Link>
               
            <Link to="/about-us" className={` ${isTopOfPage&&path==='about-us'?'text-footer-light hover:text-white':'text-blue hover:text-footer-light'} text-[13px] ss:text-[15px] duration-300 text-blue`}>About</Link>
            
            <Link to='/contact' className={`${isTopOfPage&&path==='about-us'?'text-footer-light hover:text-white':'text-blue hover:text-footer-light'} duration-300`} >Contact</Link>
              </div>
              ):null
             }
             
            
          
         
           {
            isAboveSmallScreen?(
              <div className=''>
              <div className='flex'>
             <div className={`px-3 py-1 cursor-pointer font-playfair ${isTopOfPage&&path==='about-us'?'text-footer-light hover:text-white':'text-blue hover:text-footer-light'}  duration-300`} onClick={()=>setAccountToggle({...accountToggle,sign:true})} >Sign In</div>
             {
              menuNavbar &&<div className={`border-1 border-yellow rounded-full px-3  hover:bg-footer-light transition duration-500 hover:text-blue py-1 text-[16px] ${isTopOfPage&&path==='about-us'?'text-footer-light hover:text-white':'text-blue'} font-playfair cursor-pointer`}
                       onClick={()=>setAccountToggle({...accountToggle,signup:true})} >Create an Account</div>
                     
             }
             </div>
          </div>
            ):(
            
              <div>
              {
                isTopOfPage?(
                  <div className=''>
                   <div className='flex absolute top-14 end-0 '>
                 <div className={`px-3 py-1 cursor-pointer text-blue font-playfair ${isTopOfPage&&path==='about-us'?'text-footer-light hover:text-white':'text-blue'} duration-300`} onClick={()=>setAccountToggle({...accountToggle,sign:true})} >Sign In</div>
                 <div className={`border-1 border-yellow hover:bg-footer-light  mr-5 ss:mr-0 transition duration-500 hover:text-blue rounded-full px-3 py-1  font-playfair cursor-pointer ${isTopOfPage&&path==='about-us'?'text-footer-light hover:text-white':'text-blue'}`} onClick={()=>setAccountToggle({...accountToggle,signup:true})} >Create an Account</div>
             
              </div>
              </div>
              ): <div>{!menuNavbar &&<div className='px-3 py-1 cursor-pointer text-blue font-playfair' onClick={()=>setAccountToggle({...accountToggle,sign:true})} >Sign In</div>}</div>
              
              }
             
              </div>
             )
          } 
      </div>
    </nav>
  )
}

export default Navbar;


