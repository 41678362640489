import axios from 'axios';

const url = 'https://whiteboardblackpen-tge6.onrender.com/30Test'
//const url = 'http://localhost:8000/30Test'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/30Test`

export const testSeriesGetAction=(page,level,chapter)=>async(dispatch)=>{
  
    dispatch({type:"TEST_SERIES_REQUEST"});
    try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (chapter){
      const {data} = await axios.get(`${url}/projectile/getall/${level}/${chapter}/${100+page}?page=${page}&limit=${1}`,{
        headers:{
            Authorization : `Bearer ${userInfo.token}`,
            'Content-Type': "application/json",
        },
        
     });
     dispatch({type:"TEST_SERIES_SUCCESS",payload:data})
    }
     

     
    } catch (error) {
      
      dispatch({type:"TEST_SERIES_FAIL",payload:error});
    }
  }
export const responseAnswerOfTestSeriesAction=(response,id)=>async(dispatch)=>{
    dispatch({type:"TEST_SERIES_RESPONSE_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.post(`${url}/response`,{response,id},{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
    
     dispatch({type:"TEST_SERIES_RESPONSE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"TEST_SERIES_RESPONSE_FAIL"});
    }
  }
   // all data for response
  export const responseAnswerOfTestSeriesActionAllData=()=>async(dispatch)=>{
    dispatch({type:"TEST_SERIES_RESPONSE_ALLDATA_REQUEST"});
   
    try {
     
     const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     const {data} = await axios.get(`${url}/response/alldata`,{
      headers:{
        Authorization : `Bearer ${userInfo.token}`,
        'Content-Type': "application/json",
    }
     });
    
     dispatch({type:"TEST_SERIES_RESPONSE_ALLDATA_SUCCESS",payload:data})
    } catch (error) {
     
      dispatch({type:"TEST_SERIES_RESPONSE_ALLDATA_FAIL"});
    }
  }

  // sidebar


  export const testSeriesSidebarAction=(page,level,chapter)=>async(dispatch)=>{
    dispatch({type:"TEST_SERIES_SIDEBAR_REQUEST"});
    try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (chapter){
     const { data } = await axios.get(`${url}/sidebar/${level}/${chapter}/${1000+page}?page=${page}&limit=${5}`,{
        headers:{
            Authorization : `Bearer ${userInfo.token}`,
            'Content-Type': "application/json",
        }
     });
 
     dispatch({type:"TEST_SERIES_SIDEBAR_SUCCESS",payload:data});
    }
    } catch (error) {
     
      dispatch({type:"TEST_SERIES_SIDEBAR_FAIL"});
    }
  }