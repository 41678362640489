import React from 'react'
import { InlineMath } from 'react-katex'
import { useSelector } from 'react-redux';
import { CloseIcons } from '../../../icons/Icon';

const ReviseTab = ({ setRevise,path }) => {
    const reviseData = useSelector(state=>path==='tangent-normal'?state.reviseConceptsOfTangentRed:state.reviseConceptsOfMaximaRed);
   
  return (
    <section className='absolute top-0 rounded-md md:max-w-[800px] -right-4 z-50 bg-white definition ml-10' >
    <div> 
     <div className='text-3xl py-2 flex justify-center' style={{color:"#005DFF"}}>{reviseData?.data?.data?.heading}</div>
     <div className='text-[17px] font-bold pb-3'>{reviseData.data?.data?.definition1}</div>
    <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>
      <InlineMath displayMode={true} options={{strict: false}} math={reviseData?.data?.data?.concept} errorColor={'#cc0000'} />
    </div>
    <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>
      <InlineMath displayMode={true} options={{strict: false}} math={reviseData?.data?.data?.examples} errorColor={'#cc0000'} />
    </div>
    <div>{reviseData?.data?.data?.definition2}</div>
    
    
    
      <div className=' absolute top-2 right-3 cursor-pointer' onClick={()=>setRevise(false)} ><CloseIcons/></div>
    </div>
   </section>
  )
}

export default ReviseTab
