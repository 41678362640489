import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import NestedLikesTime from './NestedComments/NestedLikesTime';
import NestedComments from './NestedComments/NestedComments';
import { AskToSahilGetAction } from '../../redux/actions/Ask-to-sahil/AskToSahilAction';

const CommentsFlats = ({ id,localComment,setLocalComment,setOuterCommentId,setComment,comment,setShowNestedComment,setIndex }) => {
    const { data } = useSelector(state=>state.getAllAskToSahilRed);
    const creteNested = useSelector(state=>state.CreateCommentsToSahilAskRed);
  useEffect(()=>{
  
     const value= data?.questions?.find(x=>x._id===id);
     setLocalComment(value?.comments)
    },[id,data?.questions,setLocalComment]);
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(AskToSahilGetAction());
   },[dispatch,creteNested]);

  return (
    <div >
    
      {
        localComment?.map((x,i)=>(
            <div key={x._id}>
             <div className="m-3">
             <div className='bg-gray-300 max-w-md inline-block p-2  rounded-md'>
                        <p className='font-bold'>{x?.postedBy?.name===process.env.REACT_APP_ADMIN_SECUIRITY?"Sahil Bhayya":x?.postedBy?.name}</p>
                        <p className="">{x?.text}</p>
            </div>
                 
                  <NestedLikesTime nestedPost = {x} id={id} localComment={localComment} setOuterCommentId={setOuterCommentId} setComment={setComment} comment={comment} setShowNestedComment={setShowNestedComment}  setIndex={setIndex} i={i}  />
                  {
                    x?.commentsOnComments?.length!==0 &&<div><NestedComments nestedPost = {x} id={id} setIndex={setIndex} /></div>
                  }
                  
               
                </div>
            </div>
        ))
      }
      <div>
      
    </div>
    </div>
  )
}

export default CommentsFlats
