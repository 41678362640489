import  axios  from "axios";

let url = 'https://whiteboardblackpen-tge6.onrender.com/challenging-problems'
//let url = 'http://localhost:8000/challenging-problems'
//let url = `${process.env.REACT_APP_BACKEND_DOMAIN}/challenging-problems`


const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });

  export const challengingProblemsQuestionsAction = (page) => async(dispatch)=>{
    dispatch({type:"CHALLENGING_PROBLEMS_REQUEST"});
    try {
     const {data} = await API.get(`/questions?page=${page}&limit=${5}`);
     dispatch({type:"CHALLENGING_PROBLEMS_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"CHALLENGING_PROBLEMS_FAIL"});
    }
  }
  

  export const AllchallengingProblemsSolutionAction=(page)=>async(dispatch)=>{
    
    dispatch({type:"ALL_CHALLENGING_SOLUTIONS_REQUEST"});
     
    try {
     const { data } = await API.get(`/solutions?page=${page}&limit=${5}`);
     dispatch({type:"ALL_CHALLENGING_SOLUTIONS_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"ALL_CHALLENGING_SOLUTIONS_FAIL"});
    }
  }

  export const challengingProblemsSolution=(id)=>async(dispatch)=>{
    
    dispatch({type:"CHALLENGING_SOLUTION_REQUEST"});
     
    try {
     const {data} = await API.post(`/solution`,{ id });
     dispatch({type:"CHALLENGING_SOLUTION_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"CHALLENGING_SOLUTION_FAIL"});
    }
  }


  // likes 


  export const challengingProblemsSolutionLikes = (likeId) => async(dispatch)=>{
   
    dispatch({type:"CHALLENGING_SOLUTION_LIKES_REQUEST"});
     
    try {
     const {data} = await API.patch(`/${likeId}`);
     dispatch({type:"CHALLENGING_SOLUTION_LIKES_SUCCESS",payload:data});
    
    } catch (error) {
    
      dispatch({type:"CHALLENGING_SOLUTION_LIKES_FAIL"});
    }
  }



  export const getByIdChallengingProblemsLikesAction = (id) => async(dispatch)=>{
   
    dispatch({type:"ALL_DATA_CHALLENGING_LIKES_REQUEST"});
     
    try {
     const {data} = await API.get(`/all-data/${id}`);
     dispatch({type:"ALL_DATA_CHALLENGING_LIKES_SUCCESS",payload:data});
     
    } catch (error) {
    
      dispatch({type:"ALL_DATA_CHALLENGING_LIKES_FAIL"});
    }
  }
  
  
  