import React from 'react';
import ReactPlayer from 'react-player';
//import useMediaQuery from '../components/hook/UseMediaQuery';
const Irodov = () => {
    //const mobile = useMediaQuery('(min-width: 660px)');
  return (
    <div>
     <p className='text-center mt-3 text-2xl'>
        Here, I'll provide IRODOV problems and video solutions of each and every problems.
        and i'll provide all the questions in download. you can download the questions as a pdf formate.
     </p>
     <p className='text-center mt-3 text-2xl'>
        59 to 82 solutions
     </p>
     <div className='mt-4  player-wrapper' >

      <ReactPlayer 
      className='react-player '
        url='https://www.youtube.com/watch?v=G5_4pbLDY50'
        width='100%'
        height='100%'
       controls = {true}
       
    />
    </div>
    </div>
  )
}

export default Irodov
//  https://www.youtube.com/watch?v=G5_4pbLDY50