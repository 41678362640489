import React from 'react'
import { Link } from 'react-router-dom'

const DailyPracticeTest = () => {
  return (
    <div className='relative ' >
      <div className='text-8xl text-center font-sans' >DO IT</div>
    <Link to='single-correct' >
        <div className=' mt-11 max-w-full  ml-4 mr-2' >
           <div className='examples rounded-lg text-1xl ss:text-2xl hover:bg-sideScroll duration-300 bg-black text-white hover:text-white flex mt-6 font-serif font-semi p-4 ss:p-7'>Single Correct Option</div>
        </div>
    </Link>
    <Link to='multiple-correct' >
        <div className='max-w-full  ml-4 mr-2' >
           
           <div className='examples relative rounded-lg duration-300 bg-blue text-white hover:bg-sideScroll hover:text-white p-4 ss:p-7  mt-2 cursor-pointer font-serif font-semi text-1xl ss:text-2xl' >More Than One Correct Options</div>
        </div>
    </Link>
  </div>
  )
}

export default DailyPracticeTest
