import CloseIcon from '@mui/icons-material/Close';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import QuizIcon from '@mui/icons-material/Quiz';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SystemUpdateAltIcvscode from '@mui/icons-material/SystemUpdateAlt';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import  ThumbUpAltOutlinedIcon  from '@mui/icons-material/ThumbUpAltOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';

export const CloseIcons =()=>{
    return <div className='cursor-pointer' ><CloseIcon/></div> 
}

export const GoBackIcon =()=>{
    return (
        <div className='w-[2px] xs:w-[10px] flex justify-center items-center cursor-pointer' ><SettingsBackupRestoreIcon/></div>
    )
}

export const MenuIcons =()=>{
    return <div className='cursor-pointer' ><MenuIcon/></div> 
}

export const LogOutIcon =()=>{
    return <div className='cursor-pointer' ><LogoutIcon /></div> 
}

export const QuizesIcon =()=>{
    return <div className='cursor-pointer' ><QuizIcon /></div> 
}

export const ProfileIcon =()=>{
    return <div className='cursor-pointer' ><PersonAddAltIcon /></div> 
}


export const ReadingIcon =()=>{
    return <div className='cursor-pointer' ><AutoStoriesIcon /></div> 
}

export const CreatorIcon =()=>{
    return <div className='cursor-pointer' ><SupervisorAccountIcon /></div> 
}

export const AdminIcon =()=>{
    return <div className='cursor-pointer' ><AdminPanelSettingsIcon /></div> 
}

export const DownLoadsIcons =()=>{
    return <div className='cursor-pointer' ><DownloadIcon /></div> 
}


export const DeleteIcons =()=>{
    return <div className='cursor-pointer' ><DeleteIcon /></div> 
}

export const CameraIcons =()=>{
    return <div className='cursor-pointer' ><CameraAltIcon /></div> 
}

export const UpdateIcons =()=>{
    return <div className='cursor-pointer' ><SystemUpdateAltIcvscode /></div> 
}

export const PsychologyIcons =()=>{
    return <div className='cursor-pointer' ><PsychologyAltIcon /></div> 
}

export const YouTubeIcons =()=>{
    return <div className='cursor-pointer' ><YouTubeIcon /></div> 
}

export const ArrowIcons =()=>{
    return <div className='cursor-pointer' ><DoubleArrowIcon /></div> 
}

export const VisibilityIcons =()=>{
    return<VisibilityIcon />
}

export const SendIcons = () =>{
    return <SendIcon />
}
export const Buttons = ({variant,type,color,text,endIcon,data,onClick}) => {
  return <div><Button variant = {variant} type = {type} color = {color} endIcon = {endIcon} onClick={onClick} >{`${text}`}
  {data?.loading&& <div className='button--loading py-[12px]'> </div>}
   </Button></div>
}

export const ThumbUpAltIcons = () =>{
    return <ThumbUpAltIcon />
}

export const ThumbUpAltOutlinedIcons = () =>{
    return <ThumbUpAltOutlinedIcon />
}

export const ModeCommentOutlinedIcons = () =>{
    return <ModeCommentOutlinedIcon />
}

export const FavoriteIcons = () =>{
    return <FavoriteIcon />
}



