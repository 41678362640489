import React,{useState,useEffect} from 'react'
import Timer from './Timer';
import { useDispatch, useSelector } from 'react-redux';
import { MockTestAllQuestionsAction, getResultsMockTestAction } from '../redux/actions/Mock-Test/MockTestAction';
import { useLocation } from 'react-router-dom';
import { Buttons,SendIcons, VisibilityIcons } from '../icons/Icon';
import Results from './Results/Results';
import { Button } from '@mui/material';


const MockTest = () => {
  const [page,setPage] = useState(1);
  const [selectedPage,setSelectedPage] = useState('concepts');
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const [showScreen,setShowScreen] = useState(true);
  const [showResults,setShowResults] = useState(false)
  const [path,setPath] = useState('');
  const timerId = JSON.parse(localStorage.getItem(`timer_${user.id+path}`));
  const mockTestQuestions = useSelector(state=>state.mockTestAllQuestionsRed);
  const [getResultsData,setGetResultsData] = useState();
  const {data,loading}  = useSelector(state=>state.getresultsMockTestRed);
  const [finishedMockTestAfterCompleteTimer,setFinishedMockTestAfterCompleteTimer] = useState(false);
    const [startMockTest,setStartMockTest] = useState({
      mockTestDurationHours : 1,
      start : false
    });
    
  //  const {data} = useSelector(state=>state.timerMockTestRed);
    const dispatch=useDispatch();
  
    const location = useLocation();
  
  useEffect(() => {
    const pat = location.pathname.split('/');
      setPath(pat[2]);
   }, [location,path]);
    useEffect(() => {
     if(startMockTest.start ){
       dispatch(MockTestAllQuestionsAction(page,path));
     }
      
     }, [page,dispatch,startMockTest,path]);
    
  //useEffect(()=>{
   
    // if(data?.time <= 0){
     
    //    setFinishedMockTestAfterCompleteTimer(true);
    //  }
    // },[data]);
    useEffect(()=>{
      mockTestQuestions.data = []
      
      },[]);
  
    function startTimerForUser(userId, duration) {
        const startTime = new Date().getTime();
  
       if(!timerId ){
        
          localStorage.setItem(`timer_${userId}`, JSON.stringify({ startTime, duration }));
        }
        
      }

      useEffect(()=>{
       
        if(path==='') return
        dispatch(getResultsMockTestAction(path));
        
     },[finishedMockTestAfterCompleteTimer,dispatch,data,path]);
     
     useEffect(()=>{
      setTimeout(()=>{
       setShowScreen(false)
      },5000)
   },[]);

   if(showScreen){
    return <div className='text-center my-2 text-2xl font-bold'>LOADING...</div>
   }

  if(data.results?.length!==0 && path!=='' ){
    return <div>{
      showResults ? <Results selectedPage={selectedPage} setSelectedPage={setSelectedPage} loading = {loading} finishedMockTestAfterCompleteTimer = { finishedMockTestAfterCompleteTimer } path = { path } user={user} />:
      <div className='min-h-screen flex bg-slate-100 justify-center items-center'>
      <div >
      <div className='bg-white shadow-md p-3 rounded m-3'>
        <p className='pb-3'>You have completed your test for {path}.</p>
           
      <div className='flex relative items-center justify-center'>
      {/* <Button variant="contained" onClick={resultOfMockTest} color='success' endIcon=<VisibilityIcons/> >
     {results.loading? <div className='button--loading py-[12px]'> </div>:<div>View results</div> }
      </Button> */}
      <Buttons variant={"contained"} type = {"button"}  color='success' endIcon = {<VisibilityIcons />} data = {null} onClick={()=>setShowResults(true)} text = {'View results'} />

      </div>
    
    </div>
        </div>
        </div>
    }</div>
  }
  return (
    <div>
      {mockTestQuestions?.data?.length!==0 && startMockTest.start ? (
        <div>
        
          <Timer selectedPage={selectedPage} setSelectedPage={setSelectedPage}
             finishedMockTestAfterCompleteTimer={finishedMockTestAfterCompleteTimer} 
             page = {page} setPage = {setPage} path = { path }  mockTestQuestions = { mockTestQuestions }
             setFinishedMockTestAfterCompleteTimer = { setFinishedMockTestAfterCompleteTimer } user={user} setGetResultsData= {setGetResultsData}
           />
          {/* Other quiz content goes here */}
        </div>
      ) : (
        <div className='min-h-screen flex justify-center items-center bg-slate-100 '>
        <div className='m-2'>
         <p className='text-2xl mb-3 xs:text-3xl text-center'>Read Carefully Before Starting Mock Test</p>
           <div className='w-full ss:w-[500px] bg-white shadow-md p-4 rounded'>
          
          <div className='flex '>
           <div className='p-1 absolute bg-black rounded-full flex m-2'></div> <p className='w-full ml-6'>4 marks would be awarded for every question answered correctly, while <span className='bg-yellow font-bold'>negative marking</span>  will be done for every <span className='bg-yellow font-bold'>incorrect answer</span>. This implies that  <span className='bg-yellow font-bold'>1 mark is deducted</span>  every time a candidate answers the question wrongly.</p>  <br/>
           
          </div>
          <div className='flex '>
           <div className='p-1 absolute bg-black rounded-full flex m-2'></div> <p className='w-full ml-6'><span className='bg-yellow font-bold'>No negative</span> marking for <span className='bg-yellow font-bold'>Not Attempted Questions</span>.</p>  <br/>
           
          </div>
          <div className='flex relative'>
           <div className='p-1 absolute bg-black rounded-full flex m-2'></div> <p className='w-full ml-6'>Keep your eye on the timer and finish before time runs out.</p>  <br/>
           
          </div>
          <div className='flex '>
           <div className='p-1 absolute bg-black rounded-full flex m-2'></div> <p className='w-full ml-6'>Questions are single correct choice. </p>  <br/>
           
          </div> 
          <div className='flex '>
           <div className='p-1 absolute bg-black rounded-full flex m-2'></div> <p className='w-full ml-6'>The timer will be calculated from the time you start your test, if you <span className='bg-yellow font-bold'>leave the test midway</span> then the<span className='bg-yellow font-bold'>time will be cosidered</span> as well.</p>  <br/>
           
          </div>  
        <div className='flex justify-center mt-3' onClick={()=>{
          setStartMockTest({...startMockTest,start:true});
          startTimerForUser(user.id+path,3600);
         // dispatch(mockTestStartTimeAction()) 
        // dispatch(MockTestAllQuestionsAction(page,path));
        }}>
          {/* <Button variant="contained" type='submit' color='success' endIcon = {<SendIcons />} >Start Mock Test
          {mockTestQuestions?.loading&& <div className='button--loading py-[12px]'> </div>}</Button>
        */}
        
        <Buttons variant={"contained"} type = {"submit"} color='success' endIcon = {<SendIcons />} data = {mockTestQuestions} text = {'Start Mock Test'} />
        
        </div>
        </div>
        </div>
        </div>
      )}
      
    </div>
  )
}

export default MockTest
