import React from 'react'

const Empty = () => {
  return (
    <div className='min-h-screen justify-center items-center flex text-4xl'>
      Coming Soon
    </div>
  )
}

export default Empty
