import React from 'react';
import { motion } from "framer-motion";
import useMediaQuery from '../../../../components/hook/UseMediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Atom = () => {
    const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <div>
       <div className={`md:flex ${!isAboveLarge&& 'flex flex-col-reverse'}  max-w-[1100px] items-center mx-auto md:justify-between md:gap-8 md:mt-20`}>
       
        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
           
              
              <LazyLoadImage
              src="assets/physics.png"
              alt='image loading'
              //effect='blur'
              placeholderSrc="assets/physics.png"
              threshold='100'
              className="hover:filter hover:saturate-200 transition duration-500 w-full max-w-[400px] md:max-w-[400px] mr-3"
               
             />
             
           
          ) : (
            <div className='flex justify-center w-full'>
              {/* <img
                alt="test"
                className=" hover:filter hover:saturate-200 transition duration-500 max-w-[270px] sm:max-w-[450px]"

                src="assets/physics.png"
              /> */}
              <LazyLoadImage
              src="assets/physics.png"
              alt='image loading'
              //effect='blur'
              placeholderSrc="assets/physics.png"
              threshold='100'
              className="hover:filter hover:saturate-200 transition duration-500 max-w-[270px] sm:max-w-[450px]"
               
             />
            </div>
          )}
        </div>
        <motion.div
          className="md:w-1/3 "
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-serif font-bold text-5xl text-blue text-center mt-20 md:mt-2   ">
            PHY <span className="text-black">SICS</span>
          </p>
        
          <p className="mt-3 max-w-[250px] xs:max-w-[400px] mx-auto mb-7 text-center font-serif">
            I'll provide A-Z concepts of A-Z chapters, means from basic mathematics
            to modern physics. I'm giving my best to make very highly rated content on the internet world
            absolutely free. 

          </p>
        </motion.div>
    </div>
    </div>
  )
}

export default Atom
