export const conceptsOfDefinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'DEFINITE_CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'DEFINITE_CONCEPT_SUCCESS':
            return {...state,data:action.payload,loading:false}
        case 'DEFINITE_CONCEPT_FAIL':
             return {...state,loading:false}
        default:
            return state
    }
}

export const examplesOfDefinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'DEFINITE_EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'DEFINITE_EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'DEFINITE_EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const reviseConceptsOfDefinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'DEFINITE_REVISE_REQUEST':
            return {...state,loading:true}
        case 'DEFINITE_REVISE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'DEFINITE_REVISE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}
//jeemains
export const jeemainsOfDifinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'JEEMAINS_DEFINITE_REQUEST':
            return {...state,loading:true}
        case 'JEEMAINS_DEFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'JEEMAINS_DEFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}
//sidebar mains

export const sidebarMainsOfDefinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'SIDEBAR_DEFINITE_MAIN_REQUEST':
            return {...state,loading:true}
        case 'SIDEBAR_DEFINITE_MAIN_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'SIDEBAR_DEFINITE_MAIN_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}




// ADVANCE


export const advanceOfDifinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'ADVANCE_DEFINITE_REQUEST':
            return {...state,loading:true}
        case 'ADVANCE_DEFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'ADVANCE_DEFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}
//sidebar

export const sidebarAdvanceOfDefinite=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'SIDEBAR_DEFINITE_ADVANCE_REQUEST':
            return {...state,loading:true}
        case 'SIDEBAR_DEFINITE_ADVANCE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'SIDEBAR_DEFINITE_ADVANCE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}




//more questions


export const moreQueDedifiniteRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_DEFINITE_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_DEFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_DEFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsDedifiniteRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_ANS_DEFINITE_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_DEFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_DEFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//below 4 rani 4 raja ke liye

// response for mains

export const ResponseJeemainsDifiniteRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'RESPONSE_JEEMAINS_ANS_DIFINITE_REQUEST':
            return {...state,loading:true}
        case 'RESPONSE_JEEMAINS_ANS_DIFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'RESPONSE_JEEMAINS_ANS_DIFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//ALLDATA for mains

export const ResponseJeemainsAllDataDifiniteRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'RESPONSE_JEEMAINS_ANS_DIFINITE_ALLDATA_REQUEST':
            return {...state,loading:true}
        case 'RESPONSE_JEEMAINS_ANS_DIFINITE_ALLDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'RESPONSE_JEEMAINS_ANS_DIFINITE_ALLDATA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

// response for advance

export const ResponseAdvanceDifiniteRed=(state={data:[] },action)=>{
    switch(action.type){
        case 'RESPONSE_ADVANCE_ANS_DIFINITE_REQUEST':
            return {...state,loading:true}
        case 'RESPONSE_ADVANCE_ANS_DIFINITE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'RESPONSE_ADVANCE_ANS_DIFINITE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//ALLDATA for advance

export const ResponseAdvanceAllDataDifiniteRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'RESPONSE_ADVANCE_ANS_DIFINITE_ALLDATA_REQUEST':
            return {...state,loading:true}
        case 'RESPONSE_ADVANCE_ANS_DIFINITE_ALLDATA_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'RESPONSE_ADVANCE_ANS_DIFINITE_ALLDATA_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}