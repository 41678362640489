import React, { useEffect, useState } from 'react'
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/Pagination';
import Loading from '../../components/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { signout } from '../../redux/actions/Auth/AuthAction';
const Tab1 = ({setPage,page,selectedPage}) => {
    //const {data,loading} = useSelector(state=>state.alldata);
 // const userData = JSON.parse(localStorage.getItem('userInfo'));

    const {data:{ data },loading} = useSelector(state=>state.concepts);
    const difiniteDataForConcept = useSelector(state=>state.conceptsOfDefinite)
    const [pathName,setPathName] = useState('');
    const userData = JSON.parse(localStorage.getItem('userInfo'));
   //console.log(difiniteDataForConcept,'bhbhh',data);
   const location = useLocation();
  // console.log(difiniteDataForConcept);
  useEffect(() => {
   
    const path = location.pathname.split('/');
    setPathName(path[4])
   // console.log(path);
   //setPage(5)
  }, [pathName,location])
  const dispatch = useDispatch();
  const navigate = useNavigate();

 const Logout=()=>{
  dispatch(signout(userData?.phoneNumber,navigate));
}
 
  if(difiniteDataForConcept.data.message==='jwt expired'){
       
    return (
      <div className='flex items-center justify-center min-h-screen'>
      <div className='relative  mx-auto my-auto bg-black text-white p-2 max-w-[500px]'>Your Session has expired <span className=' cursor-pointer text-blue' onClick={Logout}  >Click Me</span> to Log In again</div>
      </div>
    )
}

  return (
    <div>
        <main className=' mt-[150px] flex justify-center items-center text-gray-900'>
  
  <div className='ml-1 w-[1050px]'>
  {
   (pathName==='indefinite-integrals'?loading:difiniteDataForConcept?.loading)?<Loading/>:
    (pathName==='indefinite-integrals'?data?.results:difiniteDataForConcept?.data?.data?.results)?.map(x=>(
                <div className='definition rounded-md' key={x.uuid} >
                  <div className='flex items-center'>
                    <div className='text-[19px] mr-2 '>{x.conceptNo}.</div>
                     <h1 className='text-2xl flex items-center' style={{color:"#005DFF"}}> {x.heading}</h1>
                     </div>
                   
                    <div style={{fontSize:'1.05rem',margin:"10px 0"}} > {x.definition1}</div>
                    <div>
                     
                       <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >  <InlineMath math={x.concept} /></div>
                       <div>{x.image&&<img src={x.image} alt="nothing" width='200px' />}</div>
                     </div>
                     <div className='text-[18px] mb-2' > {x.definition2}</div>
                      <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >  <InlineMath math={x.examples} /></div>
                      
                </div>
              )) }
        </div>
   </main>
       <div className='my-3 '>
       {
        
        (data||difiniteDataForConcept)&&  <Pagination selectedPage={selectedPage} setPage={setPage} page={page} conceptData={pathName==='indefinite-integrals'?data:difiniteDataForConcept?.data?.data} />
       }
      
         </div>
    </div>
  )
}

export default Tab1
