import React, { useEffect, useState } from 'react'
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/Pagination';

import { reviseConcept } from '../../redux/actions/mathsActions/mathsactions';
import ReviseTab from './extraScreen/ReviseTab';
import { GoBackIcon } from '../../icons/Icon';
import Loading from '../../components/Loading';
import { useLocation } from 'react-router-dom';
import { reviseConceptsOfDefinite } from '../../redux/reducers/mathsReducers/definiteIntegral/dfiniteConceptReducer';
import { reviseOfDefinite } from '../../redux/actions/mathsActions/definiteIntegral/conceptDefiniteAction';
const Tab2 = ({setPage2,selectedPage,page2}) => {
  const userData = JSON.parse(localStorage.getItem('userInfo'));
  const [revise,setRevise] = useState(false);
  const [pathName,setPathName] = useState('')
  const {data:{data,creator},loading} = useSelector(state=>state.examples);
  const exampleDataDefinite = useSelector(state=>state.examplesOfDefinite);
  const location = useLocation();
 
  const dispatch = useDispatch()
 useEffect(()=>{
  const path = location.pathname.split('/');
  setPathName(path[4])
 },[])
  const revisedConcept=(id)=>{
    if(!revise){
       dispatch(reviseConcept(id));
       dispatch(reviseOfDefinite(id));
      // console.log(reviseData,'llplpmnmnmn');
       setRevise(true);
      
    }
   
   
  }
  // if(loading){
  //   return <Loading/>
  // }
  return (
    <div className='relative' >
     <main className='  mt-[180px]  flex justify-center items-center text-gray-900'>
     
     <div className='ml-1 w-[1050px]'>
   {
    (pathName==='indefinite-integrals'?loading:exampleDataDefinite?.loading)?<Loading/>:
  
   (pathName==='indefinite-integrals'?data?.results:exampleDataDefinite?.data?.data?.results)?.map(x=>(
      <div className='definition relative rounded-md  z-30 cursor-pointer' key={x.uuid} >
         <div className='' >
              <h2 style={{color:"#005DFF"}}> {x.heading}</h2>
               <div className='flex items-center mb-2 justify-between' >
                <div className='flex items-center mb-2 '>
                <div className='mr-3'>{x.Qno}.</div>
                <div style={{color:"#005DFF"}} >  <InlineMath displayMode={true} options={{strict: false}} math={x.question} errorColor={'#cc0000'} /></div>
                </div>
                </div>
                <div className='mr-20'>{x.image&&<img src={x.image} alt="nothing" width='200px' />}</div>

                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>
                     <InlineMath displayMode={true} options={{strict: false}} math={x.solution} errorColor={'#cc0000'} />
                  </div>
                </div>
                <div className='absolute right-2 bottom-2 bg-gray2 text-white py-2 px-4 rounded flex' onClick={()=>revisedConcept(x.conceptId)} >
                      <GoBackIcon/>
                </div>
                </div>

               ))
         }
         {!revise &&
         <div className={`mb-3 mt-[18px] ${selectedPage==="examples"?'fixed top-[137px] right-2 items-center left-25 flex justify-center':''} z-40`}>
          <Pagination setPage2={setPage2} page2={page2} selectedPage={selectedPage} exampletData={pathName==='indefinite-integrals'?data:exampleDataDefinite?.data?.data} />
         </div>
         }
     </div>
   
</main>

<div>
  { revise && <div><ReviseTab setRevise={setRevise} pathName={pathName} /></div> }
</div>

    </div>
  )
}

export default Tab2
