import axios from 'axios'
let url = 'https://whiteboardblackpen-tge6.onrender.com';
//let url = `${process.env.REACT_APP_BACKEND_DOMAIN}`

const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });
export const mathchapterNamesOfAll=()=>async(dispatch,getState)=>{
  dispatch({type:"MATHCHAPTERNAME_REQUEST"});
   try {
    const {data} = await API.get(`/mathchapter`)
    dispatch({type:"MATHCHAPTERNAME_SUCCESS",payload:data})
   } catch (error) {
    dispatch({type:"MATHCHAPTERNAME_FAIL"});
    
   }
}


export const conceptOf=(page)=>async(dispatch)=>{
  dispatch({type:"CONCEPT_REQUEST"});
  try {
   const {data} = await API.get(`/post?page=${page}&limit=${2}`);
   dispatch({type:"CONCEPT_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"CONCEPT_FAIL"});
  }
}

// examples

export const exampleOf=(page2)=>async(dispatch)=>{
  dispatch({type:"EXAMPLE_REQUEST"});
  try {
   const {data} = await API.get(`/example?page=${page2}&limit=${2}`);
  
   dispatch({type:"EXAMPLE_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"EXAMPLE_FAIL"});
  }
}

// jee mains

export const jeemains=(page)=>async(dispatch)=>{
  dispatch({type:"JEEMAINS_REQUEST"});
  try {
   const {data} = await API.get(`/jeemains?page=${page}&limit=${1}`);
 
   dispatch({type:"JEEMAINS_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"JEEMAINS_FAIL"});
  }
}


//jee advance

export const advanaceOfIndifiniteDatas=(page)=>async(dispatch)=>{
  dispatch({type:"ADVANCE_INDIF_QUESTIONS_REQUEST"});
  try {
   const {data} = await API.get(`/advance/indifinite/datas?page=${page}&limit=${1}`);
  
   dispatch({type:"ADVANCE_INDIF_QUESTIONS_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"ADVANCE_INDIF_QUESTIONS_FAIL"});
  }
}



export const sidebarQuestionOfAdvanceIndifinite=(page)=>async(dispatch)=>{
  dispatch({type:"SIDEBAR_ADV_IND_QUESTIONS_REQUEST"});
 
  try {
   
   const {data} = await API.get(`/sidebar/adv/indifinite?page=${page}&limit=${5}`);
  
   dispatch({type:"SIDEBAR_ADV_IND_QUESTIONS_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"SIDEBAR_ADV_IND_QUESTIONS_FAIL"});
  }
}





export const reviseConcept=(id)=>async(dispatch)=>{
  dispatch({type:"REVISE_REQUEST"});
 
  try {
   const {data} = await API.post(`${url}/concept`,{id});
  
   dispatch({type:"REVISE_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"REVISE_FAIL"});
  }
}

export const sidebarQuestionOfJeeMains=(page)=>async(dispatch)=>{
  dispatch({type:"SIDEBAR_MAIN_REQUEST"});
 
  try {
   
   const {data} = await API.get(`${url}/sidebar?page=${page}&limit=${5}`);
 
   dispatch({type:"SIDEBAR_MAIN_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"SIDEBAR_MAIN_FAIL"});
  }
}

export const moreQuestionOfIndifinite=(page)=>async(dispatch)=>{
  dispatch({type:"MORE_QUE_INDIFINITE_REQUEST"});
 
  try {
   
   const {data} = await API.get(`${url}/morequeindifinite?page=${page}&limit=${12}`);
   dispatch({type:"MORE_QUE_INDIFINITE_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"MORE_QUE_INDIFINITE_FAIL"});
  }
}

export const moreAnswerOfIndifinite=(page)=>async(dispatch)=>{
  dispatch({type:"MORE_ANS_INDIFINITE_REQUEST"});
 
  try {
   const {data} = await API.get(`${url}/moreansindifinite?page=${page}&limit=${12}`);
 
   dispatch({type:"MORE_ANS_INDIFINITE_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"MORE_ANS_INDIFINITE_FAIL"});
  }
}

// response jee mains

export const responseAnswerOfJeemains=(response,id)=>async(dispatch)=>{
  dispatch({type:"RESPONSE_JEEMAINS_ANS_INDIFINITE_REQUEST"});
 
  try {
  
   const userInfo = JSON.parse(localStorage.getItem('userInfo'))
   const {data} = await axios.post(`${url}/indifinite/response/mains`,{response,id},{
    headers:{
      Authorization : `Bearer ${userInfo.token}`,
      'Content-Type': "application/json",
  }
   });
   dispatch({type:"RESPONSE_JEEMAINS_ANS_INDIFINITE_SUCCESS",payload:data})
  } catch (error) {
    dispatch({type:"RESPONSE_JEEMAINS_ANS_INDIFINITE_FAIL"});
  }
}
 // all data
export const responseAnswerOfJeemainsAllData=()=>async(dispatch)=>{
  dispatch({type:"RESPONSE_JEEMAINS_ANS_INDIFINITE_ALLDATA_REQUEST"});
 
  try {
   const userInfo = JSON.parse(localStorage.getItem('userInfo'))
   const {data} = await axios.get(`${url}/indifinite/response/mains/alldata`,{
    headers:{
      Authorization : `Bearer ${userInfo.token}`,
      'Content-Type': "application/json",
  }
   });
   dispatch({type:"RESPONSE_JEEMAINS_ANS_INDIFINITE_ALLDATA_SUCCESS",payload:data})
  } catch (error) {
  
    dispatch({type:"RESPONSE_JEEMAINS_ANS_INDIFINITE_ALLDATA_FAIL"});
  }
}

// ADVANCE RESPONCE
export const responseAnswerOfAdvanceIndifinite=(response,id)=>async(dispatch)=>{
  dispatch({type:"RESPONSE_ADVANCE_ANS_INDIFINITE_REQUEST"});
 
  try {
   const userInfo = JSON.parse(localStorage.getItem('userInfo'))
   const {data} = await axios.post(`${url}/indifinite/response/advance`,{response,id},{
    headers:{
      Authorization : `Bearer ${userInfo.token}`,
      'Content-Type': "application/json",
  }
   });
   dispatch({type:"RESPONSE_ADVANCE_ANS_INDIFINITE_SUCCESS",payload:data})
  } catch (error) {

    dispatch({type:"RESPONSE_ADVANCE_ANS_INDIFINITE_FAIL"});
  }
}
 // all data for advance
export const responseAnswerOfAdvanceIndifiniteAllData=()=>async(dispatch)=>{
  dispatch({type:"RESPONSE_ADVANCE_ANS_INDIFINITE_ALLDATA_REQUEST"});
 
  try {
 
   const userInfo = JSON.parse(localStorage.getItem('userInfo'))
   const {data} = await axios.get(`${url}/indifinite/response/advance/alldata`,{
    headers:{
      Authorization : `Bearer ${userInfo.token}`,
      'Content-Type': "application/json",
  }
   });
 
   dispatch({type:"RESPONSE_ADVANCE_ANS_INDIFINITE_ALLDATA_SUCCESS",payload:data})
  } catch (error) {
    dispatch({type:"RESPONSE_ADVANCE_ANS_INDIFINITE_ALLDATA_FAIL"});
  }
}
