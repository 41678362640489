import React from "react";
import { Footer, SignSignupNavbar } from '../../home/uiDesign/scenes/Footer';
import FileInput from "./FileInput";
import {  useSelector } from "react-redux";
import { useState } from "react";

const YourProfile = () => {
    const userInformation = JSON.parse(localStorage.getItem('userInfo'));
    const uploadData = useSelector(state=>state.uploadProfileRed);
    const [message,setMessage] = useState({
        showError:false,
        errorMessage:''
    });

    return (
        <div className='min-h-screen item-center' >
       
        
         <SignSignupNavbar/>
         <div className='border text-white bg-error w-full sm:w-2/4 text-center rounded-md absolute'>
          {
            message.showError && <p>{message.errorMessage}</p>
          }
       </div>
         <div className="m-3 absolute right-0">
         <FileInput setMessage = { setMessage } message = {message} uploadData = {uploadData} />
         </div>
           <div className='max-w-[330px] xs:max-w-[420px] border-1 mx-auto text-center py-6 mt-60 flex items-center justify-center border-black' >
               <div className='text-1xl xs:text-2xl'>
               <p className='text-2xl xs:text-3xl font-serif pb-4' >Your Personal Information</p>
               <div className='flex' >
                  <label htmlFor="name" className='px-2 pb-3'>Name:</label>
               <div className={`${userInformation.name.length>=20?'text-[10px]':'text-[15px]'}`}>{userInformation.name}</div>

               </div>
             
               <div className='flex ' >
               <label htmlFor="name" className='px-2'>Phone number:</label>
                <div  >{userInformation.phoneNumber}</div>
                </div>
               </div>
               
          </div>
       <div className='absolute bottom-0 w-full' ><Footer/></div>
     </div> 
    )
}

export default YourProfile



