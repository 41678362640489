import React, { useEffect, useState } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../components/Loading'
import Pagination from '../../../components/Pagination'
import { GoBackIcon } from '../../../icons/Icon'
import {reviseConceptOfDerevative} from '../../../redux/actions/mathsActions/derevative/derevativeAction'
import { reviseConceptOfLimits } from '../../../redux/actions/mathsActions/LimitsActions/LimitAction'
import { reviseConceptsOfLimitsRed } from '../../../redux/reducers/mathsReducers/limits/LimitsReducers'
import ReviseTab from './ReviseTab'

const Tab2 = ({ setPage2,selectedPage,page2 }) => {
  const {data,loading} = useSelector(state=>state.examplesOfLimitsRed);
  const [revise,setRevise] = useState(false);
  const dispatch = useDispatch();
 
  const revisedConcept=(id)=>{
    if(!revise){
       dispatch(reviseConceptOfLimits(id));
       setRevise(true);
      
    }
   
   
  }
  return (
    <div className='relative' >
     <main className='  mt-[180px] flex justify-center items-center text-gray-900'>
     
     <div className='ml-1 w-[1050px]'>
   {
   loading?<Loading/>:
  
   data?.results?.map(x=>(
      <div className='definition relative rounded-md  z-30 cursor-pointer' key={x._id} >
         <div className='' >
              <h2 style={{color:"#005DFF"}}> {x.heading}</h2>
               <div className='flex items-center mb-2 justify-between' >
                <div className='flex items-center mb-2 '>
                <div className='mr-3'>{x.Qno}.</div>
                <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' style={{color:"#005DFF"}} >  <InlineMath displayMode={true} options={{strict: false}} math={x.question} errorColor={'#cc0000'} /></div>
                </div>
                <div className='mr-20'>{x.image&&<img src={x.image} alt="nothing" width='200px' />}</div>
                </div>
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >
                     <InlineMath displayMode={true} options={{strict: false}} math={x.solution} errorColor={'#cc0000'} />
                  </div>
                </div>
                <div className='absolute right-2 bottom-2 bg-gray2 text-white py-2 px-4 rounded flex' onClick={()=>revisedConcept(x.conceptId)} >
                      <GoBackIcon/>
                </div>
                </div>

               ))
         }
         {!revise &&
         <div className={`mb-3 mt-[18px] ${selectedPage==="examples"?'fixed top-[115px] ss:top-[125px] sm:top-[130px] right-2 items-center left-25 flex justify-center':''} z-40`}>
          <Pagination setPage2={setPage2} page2={page2} selectedPage={selectedPage} exampletData={data}  />
         </div>
         }
     </div>
   
</main>

<div>
  { revise && <div><ReviseTab setRevise={setRevise} selectedPage={selectedPage} /></div> }
</div>

    </div>
  )
}

export default Tab2