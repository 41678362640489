import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AccountCreation from '../../account/AccountCreation';
import Login from '../../account/Login';
import { FooterForLanding } from '../Footer';
import Navbar from '../NavbarForUi'
import Atom from './Atom';
import Previous from './Previous';
import Test from './Test';
import Youtube from './Youtube';


const menuNavbar=true;
const navbarColor='bg-blue'
const About = ({ accountToggle,setAccountToggle,setLoginAfterVerify }) => {
    const [isTopOfPage, setIsTopOfPage] = useState(true);
    const userData = localStorage.getItem('userInfo');
    useEffect(() => {
        const handleScroll=()=>{
          if(window.scrollY===0) setIsTopOfPage(true);
          else setIsTopOfPage(false)
        }
        window.addEventListener('scroll',()=>{
          if(window.scrollY<=960) setIsTopOfPage(true);
          else setIsTopOfPage(false)
        })
        return window.removeEventListener('scroll',handleScroll)
      }, []);
      const history = useNavigate()
      useEffect(() => {
        if(userData){
          history('/learn');
          
        }
       // console.log(loading);
      }, [userData,history]);
   
      if(accountToggle.signup){
        return (
          <div><AccountCreation setAccountToggle={setAccountToggle} accountToggle={accountToggle} /></div>
        )
      }
      if(accountToggle.sign){
        return (
          <div><Login setLoginAfterVerify={setLoginAfterVerify} setAccountToggle={setAccountToggle} accountToggle={accountToggle} /></div>
        )
      }
  return (
    <div>
         <Navbar isTopOfPage={isTopOfPage} menuNavbar={menuNavbar} navbarColor={navbarColor} setAccountToggle={setAccountToggle} accountToggle={accountToggle} />
     
     <div className='bg-blue'>
        <div className='py-36 z-50 sm:py-28 mx-3 flex justify-center items-cente'>
           <div className=''>
           <h1 className='uppercase leading-[46px] ss:leading-[72px] sm:leading-[96px] ss:text-8xl sm:text-9xl -top-4 relative text-6xl  text-center font-black text-white font-sans' >creating the best content to prepare for jee and neet</h1> 
            <p className='text-center text-footer-light max-w-full ss:max-w-[470px] mx-auto font-sansr mt-6'>We're creating a masterpiece content to help the students who are preparing for
            JEE and NEET. This content has been  made for those students who are really struggling with a good concept and precise notes and formulae.
             We'are doing a lot of hard work. you just need to stay with us. it's our promise to you ,
            we'll never leave you alone.


             </p>
             <p className='text-center text-white tracking-wide max-w-full ss:max-w-[470px] mx-auto font-sansr mt-6' >This is my Passion and Passion doesn't ask anything except</p>
             <p className='text-center text-white'>''Are You Happy Man.''</p>
            
            </div>
        </div>
     </div>

    {/* test */}
   
    <div className='pb-16 pt-5' ><Test/></div>
     <div className='pb-16 pt-5' ><Atom /></div>  
   
    <div className='pb-16 pt-5' ><Previous /></div>
     <div className='pb-20 pt-5 ' ><Youtube/></div> 
    
     <div className=' pt-5' ><FooterForLanding /></div> 
     <div className='gradient'></div>
    </div>
  )//py-60 sm:py-44 md:py-56
}

export default About
