import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavbarForTestSeires } from '../../../components/Navbar2';
import Level1 from './levels/Level1';
import { testSeriesGetAction } from '../../../redux/actions/Test/Physics/30Tests/ProjectileTestSeriesAction';
import TestSeriesSidebar from './TestSeriesSidebar/TestSeriesSidebar';
import TestSeriesSidebar2 from './TestSeriesSidebar/TestSeriesSidebar2';
import TestSeriesSidebar3 from './TestSeriesSidebar/TestSeriesSidebar3';

//const height = '10px';
const concepts = 'Level 1'
const exercises = 'Level 3';
const examples = 'Level 2';
const ProjectileTestSeries = () => {
    const [selectedPage,setSelectedPage] = useState('concepts');
    const [subject,setSubject] = useState('');
    const [navbarMenu,setNavbarMenu] = useState(true);
    const [path,setPath] = useState('');
    const [page,setPage] = useState(1);
    const [page2,setPage2] = useState(1);
    const [page3,setPage3] = useState(1);
    const navigate = useNavigate();
    const dispatch=useDispatch();
  
   //jee advance ;
   const location = useLocation();
   useEffect(() => {
   const pat = location.pathname.split('/');
     setPath(pat[2]);
     setSubject(pat[2].split('-').join(' '));
  }, [selectedPage,location]);
  //console.log(path,'apthhhh');
  useEffect(() => {
    if(selectedPage === 'concepts' && path === `${path}`){
       dispatch(testSeriesGetAction(page,1,`${path}`));
    }else if(selectedPage === 'examples' && path === `${path}`){
        dispatch(testSeriesGetAction(page2,2,`${path}`));
    }
    else if(selectedPage === 'exercises' && path === `${path}`){
      dispatch(testSeriesGetAction(page3,3,`${path}`));
  }
  
}, [page,page2,page3,selectedPage,dispatch,path])

const pageSelected1=()=>{
    setSelectedPage("concepts");
    navigate('?&activeTab=concepts',{replace:true}) 
  }
  const pageSelected2=()=>{
    setSelectedPage("examples");
    navigate('?&activeTab=examples',{replace:true})
  }
  const pageSelected3=()=>{
    setSelectedPage("exercises");
    navigate('?&activeTab=exercises',{replace:true}); 
  }
  return (
    <div>
     <div className='md:block hidden  z-50 h-full' >{selectedPage==='concepts'?(<TestSeriesSidebar path = {path} page={page} setPage={setPage} selectedPage={selectedPage} />):selectedPage==='examples'?(<TestSeriesSidebar2 path = {path} page2={page2} setPage2={setPage2} selectedPage={selectedPage} />):<TestSeriesSidebar3 path = {path} page3={page3} setPage3={setPage3} selectedPage={selectedPage} />}</div>
      {!navbarMenu&& <div className='z-50  h-full' >{selectedPage==='concepts'?<TestSeriesSidebar path = {path} page={page} setPage={setPage} navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} selectedPage = {selectedPage} />:selectedPage==='examples'?<TestSeriesSidebar2 path = {path} page2={page2} setPage2={setPage2} navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} selectedPage = {selectedPage} />:<TestSeriesSidebar3 path = {path} page3={page3} setPage3={setPage3} navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} selectedPage = {selectedPage} />}</div>}
     
     
     <NavbarForTestSeires  subject={subject} concepts={concepts} 
        exercises={exercises} pageSelected1={pageSelected1} pageSelected2={pageSelected2}  
        pageSelected3={pageSelected3} examples={examples} 
        selectedPage={selectedPage} navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu}  />
    
   <div>
        <div><Level1 page = {page} page2 = {page2} page3 = {page3} setPage = {setPage} setPage2={setPage2} setPage3 = {setPage3} selectedPage={selectedPage}  /></div>
    </div>
    </div>
  )
}

export default ProjectileTestSeries
