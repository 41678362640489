import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import Navbar from '../components/Navbar';
import { physicschapterNamesOfAll } from '../redux/actions/physicsActions/ChapterActions/chapterActions';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../components/Sidebar';
import Loading from '../components/Loading';
import { signout } from '../redux/actions/Auth/AuthAction';

const Physics = () => {
  const [navbarMenu,setNavbarMenu] = useState(false);
 
  const {data,loading}  = useSelector(state=>state.physicsChapterName);
    const history = useNavigate();
    const dispatch = useDispatch();
    const log = useRef(true);
  
    useEffect(() => {
      if(log.current){
         dispatch(physicschapterNamesOfAll());
         log.current=false;
      }
     
    }, [])
    const singleItem=async(id)=>{
       const item =await data?.find(x=>x._id===id)?.chapterName.split(' ').join('-').toLowerCase()
       history(`/subject/physics/${item}`,{replace:true})
       // onClick={()=>singleItem(chapter._id)}     this is in Link
     }
     if(data?.message==='jwt expired'){
       
      return (
        <div className='flex items-center justify-center min-h-screen'>
        <div className='relative  mx-auto my-auto bg-black text-white p-2 max-w-[500px]'>Your Session has expired <span className=' cursor-pointer text-blue' onClick={()=>{
          history('/',{replace:true});
          dispatch(signout());
        }}  >Click Me</span> to Log In again</div>
        </div>
      )
  }
     if(loading){
      return (
        <div>
          <Loading />
        </div>
       )
  }
  return (
    <div className=''>
      {/* <Navbar height={height} subject={subject} /> */}
      <div className='fixed w-full top-0' ><Navbar  navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} /></div>
      <Sidebar navbarMenu={navbarMenu} setNavbarMenu={setNavbarMenu} />
      <div className={`md:ml-[300px] mt-[86px] mr-2  ease-in-out duration-300 `}>
     {data?.map((chapter,i)=>(
      <Link onClick={()=>singleItem(chapter._id)} key={i} >
        <div className='flex gap-2 z-0 border-b rounded hover:bg-deep-blue hover:text-white transition duration-300 ease-in-out  p-10'  >
             <div className='text-[17px] sm:text-[20px] ml-7  sm:ml-16 cursor-pointer font-roboto font-semi ' >{chapter.chapterName}</div>
        </div>
       </Link>
            
       
       
     ))}
     </div>
    
  
    </div>
  )
}

export default Physics
