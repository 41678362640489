import React,{ useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { AskToSahilGetAction } from '../../redux/actions/Ask-to-sahil/AskToSahilAction';
import Loading from '../../components/Loading';
import HandleOneByOne from './HandleOneByOne';


const AllDataOfAsk = ({ localData,setId,setShowComment,createdata }) => {
 const [loading,setLoading] = useState(false)
  const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(AskToSahilGetAction());
     },[dispatch,createdata]);

 useEffect(()=>{
  setLoading(true)
   setTimeout(()=>{
      setLoading(false)
   },1500)
 },[])
  return (
    <div>
     {loading ?<div className='relative -top-60'> <Loading /></div>:
      localData?.map(x=>(
            <div key={x._id} >
             <HandleOneByOne post = {x} setId={setId} setShowComment={setShowComment} />
            
            </div>
        ))
      }
    </div>
  )}

export default AllDataOfAsk
