import React, { useEffect } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'
import { signout } from '../../../redux/actions/Auth/AuthAction';

const Tab1 = ({setPage,page,selectedPage}) => {
  const {data,loading} = useSelector(state=>state.conceptsOfLimitsRed);
 
  const dispatch = useDispatch();
  const history = useNavigate();

  const Logout=()=>{
    history('/',{replace:true});
    dispatch(signout());
 }
  if(data.message==='jwt expired'){
       
    return (
      <div className='flex items-center justify-center min-h-screen'>
      <div className='relative  mx-auto my-auto bg-black text-white p-2 max-w-[500px]'>Your Session has expired <span className=' cursor-pointer text-blue' onClick={Logout}  >Click Me</span> to Log In again</div>
      </div>
    )
}
  return (
    <div>
        <main className='mr-2 mt-[150px] flex justify-center items-center text-gray-900'>
  
  <div className='ml-1 w-[1050px]'>
  {
   loading?<Loading/>:
   data?.results?.map(x=>(
                <div className='definition rounded-md mr-1' key={x._id} >
                  <div className='flex items-center'>
                    <div className='text-[19px] mr-2 '>{x.conceptNo}.</div>
                     <h1 className='text-2xl flex items-center' style={{color:"#005DFF"}}> {x.heading}</h1>
                     </div>
                   
                    <div className='text-[12px] xs:text-[13px] sm:text-[16px]' style={{margin:"10px 0"}} > {x.definition1}</div>
                    <div className='flex'>
                     
                       <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px] ' >  <InlineMath math={x.concept} /></div>
                       <div>{x.image&&<img src={x.image} alt="nothing" width='200px' />}</div>
                     </div>
                     <div className='text-[18px] mb-2' > {x.definition2}</div>
                      <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>  <InlineMath math={x.examples} /></div>
                      
                </div>
              )) }
        </div>
   </main>
       <div className='my-3'>
         <Pagination selectedPage={selectedPage} setPage={setPage} page={page} conceptData={data} />
         </div>
    </div>
  )
}

export default Tab1