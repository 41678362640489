import React from 'react'
import { InlineMath } from 'react-katex'
import { useSelector } from 'react-redux';
import { CloseIcons } from '../../../icons/Icon';

const ReviseTab = ({setRevise}) => {
    const {data:{data}} = useSelector(state=>state.reviseConceptsOfFunctionRed);
    
  return (
    <section className='absolute top-0 rounded-md md:max-w-[800px] right-0 z-50 bg-white definition ml-10' >
   
              <div className='flex'>
                 <div className='text-[19px] mr-2 ' >{data?.conceptNo}</div>
                 <h2 className='text-2xl flex items-center' style={{color:"#005DFF"}}> {data?.heading}</h2>
              </div>
            
             { data?.definition1 && <div style={{marginTop:"3px"}} className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' > {data?.definition1}</div>}
                
                <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' style={{display:"flex",alignItems:"flex-start",marginTop:"6px"}}>  <InlineMath options={{strict:false}} math={data?.concept} /></div>
                <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >{data?.simpleConcept}</div>
                <div><img src={data?.image} alt="" width='200px' /></div>
               

                <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]'>{data?.simpleConcept1}</div>
                {data?.concept1 &&<div>
                  
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' ><InlineMath options={{strict:false}} math={data?.concept1}/></div>
               
                 { data?.image1&&<div><img src={data?.image1} alt="" className='w-[350px]' /></div>}
                  
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' >{data?.simpleConcept2}</div>
                  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' ><InlineMath options={{strict:false}} math={data?.concept2}/></div>
                
                  <div><img src={data?.image2} alt=""  
                  className='w-[350px]' /></div>
                </div> 
                }
               
                {
                    data?.examples &&  <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' style={{display:"flex",alignItems:"flex-start",marginTop:"10px"}}>  <InlineMath math={data?.examples} options={{strict:false}} /></div>
              
               }

               <div className='text-[9px] vvs:text-[11px] xs:text-[13px] sm:text-[16px]' style={{display:"flex",alignItems:"flex-start",marginTop:"10px"}} > {data?.definition2}</div>
               <div><img src={data?.image3} alt="" width='400px' /></div>
               <div className=' absolute top-2 right-2 cursor-pointer' onClick={()=>setRevise(false)} ><CloseIcons/></div>
          
   </section>
  )
}

export default ReviseTab
