import React, {useState } from 'react'
import { InlineMath } from 'react-katex'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../components/Loading'
import Pagination from '../../../components/Pagination'
import { GoBackIcon } from '../../../icons/Icon'
import { reviseConceptOfAllChaptersAction } from '../../../redux/actions/physicsActions/AllChapters/AllChaptersActions'
import ReviseTab from './ReviseTab'


const Tab2 = ({setPage2,selectedPage,page2}) => {
  const {data,loading} = useSelector(state=>state.allChaptersExamplesRed);
  const [reviseTab,setReviseTab] = useState();
  const [id,setId] = useState(null) ;
  const [revise,setRevise] = useState(false);
  const dispatch = useDispatch();
 
  const revisedConcept=(conceptId,chapter,id)=>{
    
    if(!revise){
       dispatch(reviseConceptOfAllChaptersAction(conceptId,chapter));
       setRevise(true);
    }
     setId(id)
  }
 
  return (
    <div className='relative' >
     <main className=' mt-[180px]  flex justify-center items-center text-gray-900'>
     
     <div className='ml-1 w-[1050px]'>
   {
   
  loading?<Loading/>:
  data?.results?.map(x=>(
    <div className='definition relative' key={x._id} >
    <div className='flex'>
                  <div>{x.Qno}.</div>
          <div>
             <div className='defini ml-2' style={{color:"#005DFF",fontSize:'1.2rem'}}> {x.Question}</div>
           {x.QuestionLatex && <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px] ' style={{color:"#005DFF"}}><InlineMath displayMode={true} options={{strict: false}} math={x.QuestionLatex} errorColor={'#cc0000'} /></div> }  
          
           </div>     
              </div>
              <div><img src={x.image} width='200px' alt="" /></div>
              <div className='defini' style={{fontSize:'1.2rem'}} >{x.solution}</div>
              <div><img src={x.image1} width='200px' alt="" /></div>
             <div className='text-[11px] vvs:text-[12px] xs:text-[14px] sm:text-[18px] '>
                <InlineMath displayMode={true} options={{strict: false}} math={x.solutionLatex} errorColor={'#cc0000'} />
             </div>
                {
                 x.solution2 &&  <div className='defini' style={{fontSize:'1.2rem'}} >{x.solution2}</div>
               }
              
             <div className='absolute right-2 bottom-2 bg-gray2 text-white py-2 px-3 ss:px-4 rounded flex' onClick={()=>revisedConcept(x.conceptId,x.chapter,x._id)} >
                {reviseTab && (x?._id===data?.results?.find(p=>p?._id === id)?._id)?<div className='button--loading py-[12px]' ></div>:
                   <GoBackIcon/>}  
                </div>
             </div>
          ))}
         {!revise &&
         <div className={`mb-3 mt-[18px] ${selectedPage==="examples"?'fixed top-[115px] ss:top-[125px] sm:top-[130px] right-2 items-center left-25 flex justify-center':''} z-40`}>
          <Pagination setPage2={setPage2} page2={page2} selectedPage={selectedPage} exampletData={data}  />
         </div>
         }
     </div>
   
</main>

<div>
  { revise && <div><ReviseTab setRevise={setRevise} selectedPage={selectedPage} setReviseTab={setReviseTab} /></div> }
</div>

    </div>
  )
}

export default Tab2


