import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { filesAllData } from '../../redux/actions/downloads/downLoadsAction';

const DataHandling = () => {
  const {data} = useSelector(state=>state.getAllFilesRed);
  const dispatch=useDispatch();
  useEffect(() => {
    dispatch(filesAllData())
  }, [dispatch])

  return (
    <div className=' items-center justify-center max-w-[900px] mx-auto' >
       <div className='grid sm:grid-cols-3 gap-1 m-2'>
      {
       data?.length!==0&& data?.map(x=>(
          <div key={x._id} >
            {
              x?.originalname?.split(".").slice(-1)[0]==='pdf' &&(
                <div>
                   <div className='p-4 bg-grey opacity-80 flex text-center justify-center text-white mt-3 w-full border-2 border-dotted ' >{x && x?.originalname?.split('.')[0]}</div>
                   <a href={`${process.env.REACT_APP_DOWNLOADURL}/${x.filename}`} >download
               
               </a>
               </div>)
               
             }
            </div>
        ))
       }
       </div>
    </div>
  )
}

export default DataHandling
