import React from 'react'
import ReactPaginate from 'react-paginate'


const PaginateText = ({ page,setPage,data }) => {
  // const isAbovemediumScreen = useMediaQuery('(min-width: 780px)');
    const handlePageClick=(paginate)=>{
      setPage(paginate.selected+1);
       }
       // isAbovemediumScreen?4:1
  return (
    <div>
     {isNaN(data?.totalPages) ?<div className='button--loading2 relative -top-5 right-5'></div>:
      <ReactPaginate
         previousLabel={'pre'}
         breakLabel={"..."}
         nextLabel={'next'}
         pageCount={ Math.ceil(Number(data?.totalPages))}
         pageRangeDisplayed={1}
         onPageChange={handlePageClick}
         marginPagesDisplayed={0}
         //initialPage={selectedPage==="concepts"?page-1:selectedPage==="examples"?page2-1:selectedPage==="jee mains"?page3-1:selectedPage==="jee advance"?page5-1:page4-1}
         forcePage={page-1}
         containerClassName={'pagination justify-content-center'}
         pageClassName={'page-item'}
         pageLinkClassName={'page-link'}
         breakLinkClassName={'page-link'}
         previousClassName={'page-item'}
         previousLinkClassName={'page-link'}
         nextClassName={'page-item'}
         nextLinkClassName={'page-link'}
         activeClassName={'active'}

      />
     }
    </div>
  )
}

export default PaginateText;


export const PaginateForTestSeries = ({page,setPage,data,selectedPage,page2,setPage2,page3,setPage3}) => {
 // const isAbovemediumScreen = useMediaQuery('(min-width: 780px)');
    const handlePageClick=(paginate)=>{
      
      if(selectedPage === 'concepts'){
        setPage(paginate.selected+1);
      }
     else if(selectedPage === 'examples'){
         setPage2(paginate.selected+1)
      }
    else if(selectedPage === 'exercises'){
        setPage3(paginate.selected+1)
     }
       
       
      }
    
  return (
    <div>
    {isNaN(data?.totalPages) ?<div className='button--loading2 relative -top-5 right-5'></div>  :
<ReactPaginate
         previousLabel={'pre'}
         breakLabel={"..."}
         nextLabel={'next'}
         pageCount={Math.ceil(Number(data?.totalPages))}
         pageRangeDisplayed={1}
         onPageChange={handlePageClick}
         marginPagesDisplayed={0}
         //initialPage={selectedPage==="concepts"?page-1:selectedPage==="examples"?page2-1:selectedPage==="jee mains"?page3-1:selectedPage==="jee advance"?page5-1:page4-1}
         forcePage={selectedPage === 'concepts'?page-1:selectedPage === 'examples'?page2-1:page3-1}
         //selectedPage==="concepts"?page-1:page2-1
         containerClassName={'pagination justify-content-center'}
         pageClassName={'page-item'}
         pageLinkClassName={'page-link'}
         breakLinkClassName={'page-link'}
         previousClassName={'page-item'}
         previousLinkClassName={'page-link'}
         nextClassName={'page-item'}
         nextLinkClassName={'page-link'}
         activeClassName={'active'}

      />
    }
      
    </div>
  )
}


