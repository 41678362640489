import  axios  from "axios";

//let url = 'http://localhost:8000/newton'
let url = 'https://whiteboardblackpen-tge6.onrender.com/newton'
//const url = `${process.env.REACT_APP_BACKEND_DOMAIN}/newton`


const API = axios.create({ baseURL: url });
API.interceptors.request.use((req) => {
    if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`;
    }
  
    return req;
  });
export const conceptOfNewton=(page)=>async(dispatch)=>{
    dispatch({type:"NEWTON_CONCEPT_REQUEST"});
    try {
     const {data} = await API.get(`/concept?page=${page}&limit=${3}`);
     dispatch({type:"NEWTON_CONCEPT_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"NEWTON_CONCEPT_FAIL"});
    }
  }
  
  // examples
  
  export const exampleOfNewton=(page2)=>async(dispatch)=>{
    dispatch({type:"NEWTON_EXAMPLE_REQUEST"});
    try {
     const {data} = await API.get(`/example?page=${page2}&limit=${3}`);
     dispatch({type:"NEWTON_EXAMPLE_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"NEWTON_EXAMPLE_FAIL"});
    }
  }



  //more

  export const moreQuestionOfNewton=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_QUE_NEWTON_REQUEST"});
   
    try {
     const {data} = await API.get(`/morequestion?page=${page}&limit=${12}`);
     dispatch({type:"MORE_QUE_NEWTON_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_QUE_NEWTON_FAIL"});
    }
  }
  
  export const moreAnswerOfNewton=(page)=>async(dispatch)=>{
    dispatch({type:"MORE_ANS_NEWTON_REQUEST"});
   
    try {
     const {data} = await API.get(`/moreanswer?page=${page}&limit=${12}`);
     dispatch({type:"MORE_ANS_NEWTON_SUCCESS",payload:data})
    } catch (error) {
    
      dispatch({type:"MORE_ANS_NEWTON_FAIL"});
    }
  }