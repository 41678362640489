export const conceptsOfDerevative=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'DERIVATIVE_CONCEPT_REQUEST':
            return {...state,loading:true}
        case 'DERIVATIVE_CONCEPT_SUCCESS':
            return {...state,data:action.payload,loading:false}
        case 'DERIVATIVE_CONCEPT_FAIL':
             return {...state,loading:false}
        default:
            return state
    }
}

export const examplesOfDerevative=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'DERIVATIVE_EXAMPLE_REQUEST':
            return {...state,loading:true}
        case 'DERIVATIVE_EXAMPLE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'DERIVATIVE_EXAMPLE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const reviseConceptsOfDerivativeRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'DERIVATIVE_REVISE_REQUEST':
            return {...state,loading:true}
        case 'DERIVATIVE_REVISE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'DERIVATIVE_REVISE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

//more

export const moreQueDerivativeRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_QUE_DERIVATIVE_REQUEST':
            return {...state,loading:true}
        case 'MORE_QUE_DERIVATIVE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_QUE_DERIVATIVE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}

export const moreAnsDerivativeRed=(state={data:[],loading:true},action)=>{
    switch(action.type){
        case 'MORE_ANS_DERIVATIVE_REQUEST':
            return {...state,loading:true}
        case 'MORE_ANS_DERIVATIVE_SUCCESS':
           return {...state,data:action.payload,loading:false}
        case 'MORE_ANS_DERIVATIVE_FAIL':
           return {...state,loading:false}
        default:
            return state
    }
}